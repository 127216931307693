import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import { FiPlus } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";

function EditPRRequest({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [prRequestData, setPrRequestData] = useState({
    department_name: "",
    department_id: "",
    pr_type: "",
    description: "",
    mat_code: "",
    qty: "",
    unit: "",
    est_unit_rate: "",
    preferred_vendor: "",
    expenditure_type: "",
    vendor_name: "",
    vendor_id: "",
  });
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [matCodes, setMatCodes] = useState([]);
  const [selectedMatCode, setSelectedMatCode] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const [vendorType, setVendorType] = useState("");
  const fileInputRef = useRef(null);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [rows, setRows] = useState([
    { matCodes: "", unit: "", quantity: "", est_unit_rate: "" },
  ]);
  const handleInputChanges = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
    if (field === "mat_code_id") {
      const selectedMatCode = matCodes.find((option) => option.value === value);

      // Update the description for the selected matCode
      newRows[index].mat_code_description = selectedMatCode
        ? selectedMatCode.mat_code_description
        : "";
    }
  };
  const handleAddRow = () => {
    setRows([
      ...rows,
      { matCodes: "", unit: "", quantity: "", est_unit_rate: "" },
    ]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchPRRequestData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_pr_request/${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );

        const responseData = response.data;
        const prRequestData = responseData.pr_request;

        // Log the raw expected_delivery_date for debugging
        console.log(
          "Raw expected_delivery_date:",
          prRequestData.expected_delivery_date
        );

        // Safely parse the expected_delivery_date
        let parsedDate = null;
        if (prRequestData.expected_delivery_date) {
          parsedDate = new Date(prRequestData.expected_delivery_date);
          if (isNaN(parsedDate)) {
            console.error("Invalid date format for expected_delivery_date");
            parsedDate = null;
          }
        }

        // Set department and vendor
        setSelectedDepartment({
          value: prRequestData.department_id || "",
          label: prRequestData.department_name || "",
        });
        setSelectedVendor({
          value: prRequestData.vendor_id || "",
          label: prRequestData.vendor_name || "",
        });
        setSelectedMatCode({
          label: prRequestData.mat_code || "",
        });

        // Set rows based on po_list_items
        setRows(
          responseData.po_list_items.map((item) => ({
            mat_code_id: item.mat_code_id, // Ensure you are storing mat_code_id
            unit: item.unit,
            description: item.description,
            mat_code_description: item.mat_code_description,
            quantity: item.quantity,
            est_unit_rate: item.est_unit_rate,
          }))
        );

        // Formatting data for PR Request form fields
        const formattedData = {
          department_name: prRequestData.department_name || "",
          department_id: prRequestData.department_id || "",
          vendor_name: prRequestData.vendor_name || "",
          vendor_id: prRequestData.vendor_id || "",
          pr_type: prRequestData.pr_type || "",
          description: prRequestData.description || "",
          mat_code: prRequestData.mat_code || "",
          qty: prRequestData.qty !== null ? prRequestData.qty : "",
          unit: prRequestData.unit || "",
          est_unit_rate:
            prRequestData.est_unit_rate !== null
              ? prRequestData.est_unit_rate
              : "",
          preferred_vendor: prRequestData.preferred_vendor || "",
          expenditure_type: prRequestData.expenditure_type || "",
          expected_delivery_date: parsedDate,
        };

        setPrRequestData(formattedData);
        setSelectedDeliveryDate(parsedDate);
      } catch (error) {
        console.error("Error fetching department data:", error.message);
        setError("An error occurred while fetching department data");
      }
    };

    fetchPRRequestData();
  }, [id]);

  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_all_mat_codes`, {
        headers: {
          Authorization: authKey,
        },
      });

      // Transform data to match Select component's expected format
      const transformedData = response.data.mat_code_details.map((matCode) => ({
        value: matCode.mat_code_id,
        label: matCode.mat_code,
        mat_code_description: matCode.description,
      }));

      setMatCodes(transformedData);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleDeleteFile = (indexToDelete) => {
    console.log('Before delete:', selectedFiles); // Debugging
  
    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter((_, index) => index !== indexToDelete);
    
    setSelectedFiles(updatedFiles);
  
  
    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };
  const handleVendorTypeChange = (event) => {
    const vendorTypeValue = event.target.value;
    setVendorType(vendorTypeValue);
    setSelectedVendor(null);
    setPrRequestData((prevState) => ({
      ...prevState,
      preferred_vendor: vendorTypeValue,
      vendor_name: "",
      vendor_id: "",
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      preferred_vendor: "",
    }));
  };

  const handleExpenditureTypeChange = (event) => {
    const expenditureTypeValue = event.target.value;
    // setVendorType(expenditureTypeValue);
    setPrRequestData((prevState) => ({
      ...prevState,
      expenditure_type: expenditureTypeValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      expenditure_type: "",
    }));
  };

  const prTypeOptions = [
    { value: "AMC", label: "AMC" },
    { value: "Spare", label: "Spare" },
    { value: "Service", label: "Service" },
    { value: "Work Order", label: "Work Order" },
  ];

  const unitOptions = [
    { "value": "centimeter", "label": "Centimeter (cm)" },
    { "value": "visit", "label": "Visit" },
    { "value": "day", "label": "Day" },
    { "value": "gram", "label": "Gram (g)" },
    { "value": "inch", "label": "Inch (in)" },
    { "value": "kilometer", "label": "Kilometer (km)" },
    { "value": "kg", "label": "Kilogram (kg)" },
    { "value": "liters", "label": "Liters (L)" },
    { "value": "m", "label": "Meter (m)" },
    { "value": "milligram", "label": "Milligram (mg)" },
    { "value": "millimeter", "label": "Millimeter (mm)" },
    { "value": "month", "label": "Month" },
    { "value": "pcs", "label": "Pieces (pcs)" },
    { "value": "year", "label": "Year" }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrRequestData({ ...prRequestData, [name]: value });
    // Clear validation error when input changes
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleCancelClick = () => {
    // navigate("/pr-request");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleUnitChange = (selectedOption, index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index].unit = selectedOption ? selectedOption.value : "";
      return updatedRows;
    });
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
    setPrRequestData((prevFormData) => ({
      ...prevFormData,
      vendor_name: selectedOption ? selectedOption.label : "",
      vendor_id: selectedOption ? selectedOption.value : "",
    }));
  };

  const validateForm = () => {
    let errors = {};

    // Validate department_name
    if (!prRequestData.department_name) {
      errors.department_name = "Department is required";
    }

    // Validate PR Type
    if (!prRequestData.pr_type) {
      errors.pr_type = "PR Type is required";
    }

    // Validate Expenditure Type
    if (!prRequestData.expenditure_type) {
      errors.expenditure_type = "Expenditure Type is required";
    }

    // Validate Preferred Vendor
    if (!prRequestData.preferred_vendor) {
      errors.preferred_vendor = "Preferred Vendor is required";
    }

    // Validate vendor_name if preferred_vendor is "OEM"
    if (
      prRequestData.preferred_vendor === "OEM" &&
      !prRequestData.vendor_name
    ) {
      errors.vendor_name = "Vendor Name is required for OEM";
    }

    // Validate Expected Delivery Date
    if (!prRequestData.expected_delivery_date) {
      errors.expected_delivery_date = "Expected Delivery Date is required";
    }

    // Return errors object
    setErrors(errors);
    return Object.keys(errors).length === 0; // If no errors, return true
  };

  const handleSaveClick = async () => {
    if (!validateForm()) {
      return; // Stop if validation fails
    }

    const formData = new FormData();
    formData.append("department_id", prRequestData.department_id);
    formData.append("department_name", prRequestData.department_name);
    formData.append("pr_type", prRequestData.pr_type);
    formData.append("preferred_vendor", prRequestData.preferred_vendor);
    formData.append("description", prRequestData.description);
    formData.append("project_id", prRequestData.project_id);
    formData.append("expenditure_type", prRequestData.expenditure_type);

    if (prRequestData.vendor_name) {
      formData.append("vendor_name", prRequestData.vendor_name);
    }
    if (prRequestData.vendor_id) {
      formData.append("vendor_id", prRequestData.vendor_id);
    }

    const formattedDate = new Date(prRequestData.expected_delivery_date)
      .toISOString()
      .split("T")[0];
    formData.append("expected_delivery_date", formattedDate);

    rows.forEach((row) => {
      formData.append("mat_code_ids[]", row.mat_code_id);
      formData.append("quantities[]", row.quantity);
      formData.append("units[]", row.unit);
      formData.append("est_unit_rates[]", row.est_unit_rate);
    });
    if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
      attachmentFile.forEach((file) => {
        formData.append("attachments", file);
      });
    }

    // Append file types if applicable
    if (Array.isArray(prRequestData.filetype)) {
      prRequestData.filetype.forEach((type) => {
        formData.append("filetype", type);
      });
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.put(
        `${API_URL}/api/update_pr_request/${id}`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response message:", response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("PR Request updated successfully");
        setTimeout(() => {
          navigate("/pr-request");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving PR request:", error.message);
      setSeverity("error");
      // showSnackbar("Missing item details");
      if (rows.length === 0) {
        showSnackbar("Please add at least one item to submit the PR request.");
      } else if (!prRequestData.department_id || !prRequestData.description) {
        showSnackbar(
          "Please add items in the Request Item Table before submitting."
        );
      } else {
        showSnackbar("Error saving PR request. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackProject = () => {
    navigate("/pr-request");
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setPrRequestData((prevUserData) => ({
      ...prevUserData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      department_name: "",
    }));
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };
  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);
  const handleSelectChange = (selectedOption) => {
    setPrRequestData({ ...prRequestData, pr_type: selectedOption.value });
  };

  const fetchVendors = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_vendors_procurement`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      // Assuming response.data is already parsed as JSON
      const options = response.data.Vendors.map((vendor) => ({
        label: vendor.vendor_name,
        value: vendor.id,
      }));

      setVendors(options);
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const handleDeliveryDateChange = (date) => {
    setSelectedDeliveryDate(date);
    setPrRequestData((prevState) => ({
      ...prevState,
      expected_delivery_date: date,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      expected_delivery_date: "",
    }));
  };

  const handleUploadButtonClick = () => {
    // Trigger click event of file input when upload button is clicked
    fileInputRef.current.click();
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit PR Request
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={() => setDepartmentMenuIsOpen(true)}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                  />
                  {errors.department_name && (
                    <div className="error-text">{errors.department_name}</div>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="pr_type">
                    PR Type<span className="text-danger">*</span>
                  </label>
                  <Select
                    name="pr_type"
                    value={prTypeOptions.find(
                      (option) => option.value === prRequestData.pr_type
                    )}
                    onChange={handleSelectChange}
                    options={prTypeOptions}
                    placeholder="Select PR Type"
                  />
                  {errors.pr_type && (
                    <div className="error-text">{errors.pr_type}</div>
                  )}
                </div>

                <div className="form-group-select">
                  <label htmlFor="expenditure_type">
                    Expenditure Type<span className="text-danger">*</span>
                  </label>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="revenue"
                      name="expenditure_type"
                      value="revenue"
                      checked={prRequestData.expenditure_type === "revenue"}
                      onChange={handleExpenditureTypeChange}
                    />
                    <label htmlFor="revenue">Revenue</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="capital"
                      name="expenditure_type"
                      value="capital"
                      checked={prRequestData.expenditure_type === "capital"}
                      onChange={handleExpenditureTypeChange}
                    />
                    <label htmlFor="capital">Capital</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="others" // Added id for association
                      name="expenditure_type"
                      value="others"
                      checked={prRequestData.expenditure_type === "others"}
                      onChange={handleExpenditureTypeChange}
                    />
                    <label htmlFor="others">Others</label>
                  </div>
                  {errors.expenditure_type && (
                    <div className="error-message-radio">
                      {errors.expenditure_type}
                    </div>
                  )}
                </div>

                <div className="form-group-select">
                  <label htmlFor="preferred_vendor">
                    Preferred Vendor<span className="text-danger">*</span>
                  </label>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="oem"
                      name="preferred_vendor"
                      value="OEM"
                      checked={prRequestData.preferred_vendor === "OEM"}
                      onChange={handleVendorTypeChange}
                    />
                    <label htmlFor="oem">OEM</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="openMarket"
                      name="preferred_vendor"
                      value="Open Market"
                      checked={prRequestData.preferred_vendor === "Open Market"}
                      onChange={handleVendorTypeChange}
                    />
                    <label htmlFor="openMarket">OpenMarket</label>
                  </div>
                  {errors.preferred_vendor && (
                    <div className="error-message-radio2">
                      {errors.preferred_vendor}
                    </div>
                  )}
                </div>
                {prRequestData.preferred_vendor === "OEM" && (
                  <div className="form-group-select">
                    <label htmlFor="vendor_name">
                      Preferred Vendor Name
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="vendor_name"
                      options={vendors}
                      value={selectedVendor}
                      onChange={handleVendorChange}
                      placeholder="Select vendor name"
                      isSearchable
                    />
                    {errors.vendor_name && (
                      <div className="error-message">{errors.vendor_name}</div>
                    )}
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="expected_delivery_date">
                    Expected Delivery Date{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedDeliveryDate}
                      onChange={handleDeliveryDateChange}
                      name="expected_delivery_date"
                      id="expected_delivery_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select delivery date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors.expected_delivery_date && (
                    <div className="error-message-date">
                      {errors.expected_delivery_date}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <div className="input-group">
                    <textarea
                      type="text"
                      name="description"
                      value={prRequestData.description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="attachement">
                    Technical Specification Attachment{" "}
                  </label>
                  <div className="input-group">
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={handleUploadButtonClick}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      id="attachments"
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                      multiple
                    />
                    {selectedFiles.length > 0 && (
                      <span
                        className="inquiry-file-btn"
                        onClick={handleIconClick}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedFiles.length}`}
                      </span>
                    )}
                    {isPopupOpen && (
                    <div className="attach-popup-amc4 Edit-pr-popup">
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  </div>
                </div>
              </div>
            </form>

            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Item Table
                </div>
                <div className="actions-purchase-heading-content-right-side"></div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-tables">
                  <>
                    <table className="action-table action-multilevel-data ">
                      <thead className="action-purchase-heads">
                        <tr>
                          <th className="th2">MAT CODE</th>
                          <th className="th2">DESCRIPTION</th>
                          <th className="th3">UNIT</th>
                          <th className="th4">QUANTITY</th>
                          <th className="th5">ESTIMATED UNIT RATE</th>
                          <th className="th6">ACTION</th>
                        </tr>
                      </thead>

                      <tbody className="action-purchase-scroll-bodys">
                        {rows.map((row, index) => (
                          <tr key={index}>
                            <td className="th2">
                              <Select
                                options={matCodes}
                                value={matCodes.find(
                                  (option) => option.value === row.mat_code_id
                                )}
                                onChange={(selectedOption) =>
                                  handleInputChanges(
                                    index,
                                    "mat_code_id", // Correct field to update
                                    selectedOption ? selectedOption.value : ""
                                  )
                                }
                              />
                            </td>
                            <td className="th4">
                              {row.mat_code_description || "NA"}
                            </td>
                            <td className="th2">
                              <Select
                                options={unitOptions}
                                value={unitOptions.find(
                                  (option) => option.value === rows[index].unit
                                )}
                                onChange={(selectedOption) =>
                                  handleUnitChange(selectedOption, index)
                                }
                                placeholder="Select Unit"
                                isClearable
                              />
                            </td>

                            <td className="th4">
                              <input
                                type="number"
                                value={row.quantity}
                                onChange={(e) =>
                                  handleInputChanges(
                                    index,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter quantity"
                              />
                            </td>
                            <td className="th5">
                              <input
                                type="number"
                                value={row.est_unit_rate}
                                onChange={(e) =>
                                  handleInputChanges(
                                    index,
                                    "est_unit_rate",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter estimated unit rate"
                              />
                            </td>
                            <td className="th6">
                              <button
                                className="delete-row"
                                onClick={() => {
                                  const newRows = rows.filter(
                                    (_, i) => i !== index
                                  );
                                  setRows(newRows);
                                }}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="row">
                      <div className="action-caluculation">
                        <div className="new-rows">
                          <button onClick={handleAddRow} className="add-rows">
                            <FiPlus className="add-row-icon" /> Add New Row
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              {/* <button
                className="request-button"
                onClick={() => handleGenerateRequest(id)}
              >
                Send Request
              </button> */}

              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(50px)", marginLeft: "25px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditPRRequest;
