import React, { useState, useRef } from "react";
import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import "../Responsive/TableResponsive.css";
import "../Responsive/FormResponsive.css";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import { FaPlusCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { FiUpload } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import useClickOutside from "../useClickOutside";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
function VendorForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [msmeChecked, setMsmeChecked] = useState(false);
  const [selectedGSTFiles, setSelectedGSTFiles] = useState([]);
  const [selectedPANFiles, setSelectedPANFiles] = useState([]);
  const [selectedMSMEFiles, setSelectedMSMEFiles] = useState([]);
  const [selectedIncorporationFiles, setSelectedIncorporationFiles] = useState(
    []
  );
  const fileInputRef = useRef(null);
  const gstFileInputRef = useRef(null);
  const popupRef = useRef(null)
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [isIncorporationPopupOpen, setIsIncorporationPopupOpen] =
    useState(false);
  const [isGSTPopupOpen, setIsGSTPopupOpen] = useState(false);
  const [isPANPopupOpen, setIsPANPopupOpen] = useState(false);
  const [isMSMEPopupOpen, setIsMSMEPopupOpen] = useState(false);
  const panFileInputRef = useRef(null);
  const incorporationFileInputRef = useRef(null);
  const msmeFileInputRef = useRef(null);
  const [vendorData, setVendorData] = useState({
    vendor_name: "",
    vendor_type: "", // Add vendor_type to the state
    locations: [
      {
        location: "",
        contact_person_name: "",
        location_contact_no: "",
        location_email: "",
        location_address: "",
      },
    ],
    address: "",
    contact_no: "",
    gst_no: "",
    pan: "",
    is_msme: "",
  });
  const { theme } = useContext(ThemeContext);

  const handleGSTCloseAttachment = () => {
    setIsGSTPopupOpen(false);
  };
  const handlePANCloseAttachment = () => {
    setIsPANPopupOpen(false);
  };
  const handleMSMECloseAttachment = () => {
    setIsMSMEPopupOpen(false);
  };
  const handleIncorporationCloseAttachment = () => {
    setIsIncorporationPopupOpen(false);
  };

  const handleIncorporationIconClick = () => {
    // Show selected files when icon is clicked
    setIsIncorporationPopupOpen(true);
    setIsGSTPopupOpen(false);
    setIsPANPopupOpen(false);
    setIsMSMEPopupOpen(false);
  };
  const handleGSTIconClick = () => {
    setIsGSTPopupOpen(true);
    setIsIncorporationPopupOpen(false);
    setIsPANPopupOpen(false);
    setIsMSMEPopupOpen(false);
  };
  const handlePANIconClick = () => {
    // Show selected files when icon is clicked
    setIsPANPopupOpen(true);
    setIsGSTPopupOpen(false);
    setIsIncorporationPopupOpen(false);
    setIsMSMEPopupOpen(false);
  };
  const handleMSMEIconClick = () => {
    // Show selected files when icon is clicked
    setIsMSMEPopupOpen(true);
    setIsGSTPopupOpen(false);
    setIsIncorporationPopupOpen(false);
    setIsPANPopupOpen(false);
  };
  // useClickOutside(
  //   [containerRef, buttonRef, gstFileInputRef, fileInputRef],
  //   () => {
  //     if (isGSTPopupOpen) {
  //       setIsGSTPopupOpen(false);
  //     }
  //   }
  // );


  const handleClosePopup = () => {
    setIsGSTPopupOpen(false);
    setIsPANPopupOpen(false)
    setIsIncorporationPopupOpen(false)
  };

  // useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));
  useClickOutside([containerRef, buttonRef], handleClosePopup, 200); // 200ms delay
  useClickOutside([popupRef, buttonRef], handleClosePopup, 300);






  const handleUploadButtonClick = (inputRef) => {
    // This function handles the file upload button click
    // Open the file input dialog
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleDeleteFile = (indexToDelete) => {
    console.log("Delete function called for index:", indexToDelete);
    console.log("Before delete:", selectedGSTFiles);

    // Filter out the file at the given index
    const updatedFiles = selectedGSTFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state
    setSelectedGSTFiles(updatedFiles);
    console.log("After delete:", updatedFiles);

    // Check if no files remain, then close the popup
    if (updatedFiles.length === 0) {
      handleGSTCloseAttachment(); // Close the popup if no files remain
      console.log("Popup closed");
    }
  };
  const handleDeletePanFile = (indexToDelete) => {
    console.log("Delete function called for index:", indexToDelete);
    console.log("Before delete:", selectedPANFiles);

    // Filter out the file at the given index
    const updatedFiles = selectedPANFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state
    setSelectedPANFiles(updatedFiles);
    console.log("After delete:", updatedFiles);

    // Check if no files remain, then close the popup
    if (updatedFiles.length === 0) {
      handlePANCloseAttachment(); // Close the popup if no files remain
      console.log("Popup closed");
    }
  };

  const handleDeleteMsmeFile = (indexToDelete) => {
    console.log("Delete function called for index:", indexToDelete);
    console.log("Before delete:", selectedPANFiles);

    // Filter out the file at the given index
    const updatedFiles = selectedMSMEFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state
    setSelectedMSMEFiles(updatedFiles);
    console.log("After delete:", updatedFiles);

    // Check if no files remain, then close the popup
    if (updatedFiles.length === 0) {
      handleMSMECloseAttachment(); // Close the popup if no files remain
      console.log("Popup closed");
    }
  };
  const handleDeleteincorporationFile = (indexToDelete) => {
    console.log("Delete function called for index:", indexToDelete);
    console.log("Before delete:", selectedIncorporationFiles);

    // Filter out the file at the given index
    const updatedFiles = selectedIncorporationFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state using the setter function
    setSelectedIncorporationFiles(updatedFiles); // Ensure you're using the correct setter function

    console.log("After delete:", updatedFiles);

    // Check if no files remain, then close the popup
    if (updatedFiles.length === 0) {
      handleIncorporationCloseAttachment(); // Close the popup if no files remain
      console.log("Popup closed");
    }
  };

  const handleCheckboxChange = (e) => {
    setMsmeChecked(e.target.value === "yes");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };
  const handleFileInputChange = (files, setFiles) => {
    const fileArray = Array.from(files);
    setFiles(fileArray);
  };

  const handleInputChange = (event, index = null, field = null) => {
    const { name, value } = event.target;
    if (index !== null) {
      const newLocations = [...vendorData.locations];
      if (field) {
        newLocations[index][field] = value;
      } else {
        newLocations[index] = value;
      }
      setVendorData((prevState) => ({
        ...prevState,
        locations: newLocations,
      }));
    } else {
      setVendorData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
    setErrors((prevState) => ({
      ...prevState,
      locations: "", // Clear the error message for this field
    }));
  };

  const handleAddLocation = () => {
    setVendorData((prevState) => ({
      ...prevState,
      locations: [
        ...prevState.locations,
        {
          location: "",
          contact_person_name: "",
          location_contact_no: "",
          location_email: "",
          location_address: "",
        },
      ],
    }));
  };

  const handleRemoveLocation = (index) => {
    const newLocations = vendorData.locations.filter((_, i) => i !== index);
    setVendorData((prevState) => ({
      ...prevState,
      locations: newLocations,
    }));
  };

  const handleCancelClick = () => {
    navigate("/vendors");
  };

  const handleVendorTypeChange = (selectedOption) => {
    setVendorData((prevState) => ({
      ...prevState,
      vendor_type: selectedOption.value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      vendor_type: "", // Clear the error message for vendor_type
    }));
  };

  const options = [
    { value: "Service", label: "Service" },
    { value: "Material", label: "Material" },
    { value: "Both", label: "Both" },
  ];

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateContactNumber = (contactNo) => {
    const contactPattern = /^\d{10}$/;
    return contactPattern.test(contactNo);
  };

  const handleSaveClick = async () => {
    const newErrors = {};
    let error = false;

    const validateExactTenDigits = (number) => /^\d{10}$/.test(number);

    // Validate required fields for vendor data
    if (!vendorData.vendor_name.trim()) {
      newErrors.vendor_name = "Vendor name is required";
      error = true;
    }
    if (!vendorData.vendor_type.trim()) {
      newErrors.vendor_type = "Vendor type is required";
      error = true;
    }
    // if (!vendorData.contact_no.trim()) {
    //   newErrors.contact_no = "Contact number is required";
    //   error = true;
    // }
    if (!vendorData.contact_no.trim()) {
      newErrors.contact_no = "Contact number is required";
      error = true;
    } else if (vendorData.contact_no.length > 10) {
      newErrors.contact_no = "Enter only 10 digits";
      error = true;
    } else if (!validateExactTenDigits(vendorData.contact_no)) {
      newErrors.contact_no = "Contact number must contain exactly 10 digits";
      error = true;
    }

    if (!vendorData.address.trim()) {
      newErrors.address = "Address is required";
      error = true;
    }

    // Validate each location's required fields and formats
    vendorData.locations.forEach((location, index) => {
      const locationErrors = {};

      // Check for empty fields
      if (!location.location.trim()) {
        locationErrors.location = "Location is required";
        error = true;
      }
      if (!location.contact_person_name.trim()) {
        locationErrors.contact_person_name = "Contact person name is required";
        error = true;
      }
      if (!location.location_contact_no.trim()) {
        locationErrors.location_contact_no = "Contact number is required";
        error = true;
      } else if (!validateContactNumber(location.location_contact_no)) {
        locationErrors.location_contact_no = "Invalid contact number";
        error = true;
      }
      if (!location.location_email.trim()) {
        locationErrors.location_email = "Email is required";
        error = true;
      } else if (!validateEmail(location.location_email)) {
        locationErrors.location_email = "Invalid email format";
        error = true;
      }
      if (!location.location_address.trim()) {
        locationErrors.location_address = "Address is required";
        error = true;
      }

      // Assign location-specific errors to the main error object if there are any
      if (Object.keys(locationErrors).length > 0) {
        newErrors[`location_${index}`] = locationErrors;
      }
    });

    // If no locations provided
    if (vendorData.locations.length === 0) {
      newErrors.locations = "At least one location is required";
      error = true;
    }

    if (error) {
      setErrors(newErrors);
      setSeverity("warning");
      showSnackbar("Please fix the validation errors.");
      return; // Prevent further execution if there are validation errors
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Create a new FormData object
      const formData = new FormData();

      // Append vendor data fields individually
      formData.append("vendor_name", vendorData.vendor_name);
      formData.append("vendor_type", vendorData.vendor_type);
      formData.append("contact_no", vendorData.contact_no);
      formData.append("address", vendorData.address);
      formData.append("pan", vendorData.pan);
      formData.append("gst_no", vendorData.gst_no);
      formData.append("is_msme", msmeChecked ? "1" : "0");
      formData.append("locations", JSON.stringify(vendorData.locations));

      // Append each file with respective key
      // selectedGSTFiles.forEach((file) => formData.append("gst_attachments", file));
      // selectedPANFiles.forEach((file) => formData.append("pan_attachments", file));
      // selectedIncorporationFiles.forEach((file) => formData.append("incorporation_attachments", file));
      // selectedMSMEFiles.forEach((file) => formData.append("msme_attachments", file));
      selectedGSTFiles.forEach((file) =>
        formData.append("gst_attachments", file)
      );
      selectedPANFiles.forEach((file) =>
        formData.append("pan_attachments", file)
      );
      selectedGSTFiles.forEach((fileData, type) =>
        formData.append("gst_file_types", "gst")
      );
      selectedPANFiles.forEach((fileData, type) =>
        formData.append("pan_file_types", "pan")
      );
      selectedIncorporationFiles.forEach((fileData, type) =>
        formData.append("incorporation_file_types", "incorporation")
      );
      selectedMSMEFiles.forEach((fileData, type) =>
        formData.append("msme_file_types", "msme")
      );
      selectedIncorporationFiles.forEach((file) =>
        formData.append("incorporation_attachments", file)
      );
      selectedMSMEFiles.forEach((file) =>
        formData.append("msme_attachments", file)
      );

      // Make POST request
      const response = await axios.post(`${API_URL}/api/add_vendor`, formData, {
        headers: {
          Authorization: authKey,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Vendor added successfully");
        setTimeout(() => {
          navigate("/vendors");
        }, 1000);
      } else {
        setSeverity("error");
        showSnackbar("Failed to add vendor");
      }
    } catch (error) {
      console.error("Error saving vendor:", error.message);
      setSeverity("error");
      showSnackbar("Error saving vendor");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content" ref={containerRef}>
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Vendor
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="vendor_name">
                    Vendor Name<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="vendor_name"
                      value={vendorData.vendor_name}
                      onChange={handleInputChange}
                      placeholder="Enter vendor name"
                    />
                  </div>
                  {errors.vendor_name && (
                    <div className="error-message">{errors.vendor_name}</div>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="vendor_type">
                    Type of Vendor<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="vendor_type"
                    options={options}
                    onChange={handleVendorTypeChange}
                    placeholder="Select vendor type..."
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color for control
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                        border: `1px solid ${
                          theme === "dark" ? "#444" : "#ccc"
                        }`, // Border for the menu
                        borderRadius: "4px", // Optional: add border radius for rounded edges
                        zIndex: 999, // Ensures the menu appears above other elements
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.vendor_type && (
                    <div className="error-message">{errors.vendor_type}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="contact_no">
                    Contact No<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      name="contact_no"
                      value={vendorData.contact_no}
                      onChange={handleInputChange}
                      placeholder="Enter contact no"
                    />
                  </div>
                  {errors.contact_no && (
                    <div className="error-message">{errors.contact_no}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="gst_no">GST No</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="gst_no"
                      value={vendorData.gst_no}
                      onChange={handleInputChange}
                      placeholder="Enter GST no"
                    />
                  </div>
                </div>
                <div className="form-group-attachments">
                  <div className="form-group">
                    <label htmlFor="gst_attachments">GST Certificate</label>
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={() => {
                        handleUploadButtonClick(gstFileInputRef);
                      }}
                      ref={buttonRef}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      type="file"
                      id="gst_attachments"
                      ref={gstFileInputRef}
                      onChange={(e) =>
                        handleFileInputChange(
                          e.target.files,
                          setSelectedGSTFiles
                        )
                      }
                      style={{ display: "none" }}
                      multiple
                    />
                    {selectedGSTFiles.length > 0 && (
                      <span
                        className="inquiry-file-btn"
                        onClick={handleGSTIconClick}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedGSTFiles.length}`}
                      </span>
                    )}
                    {isGSTPopupOpen && (
                      <div className="attach-popup-amc1" ref={popupRef}>
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="fiile-close-btn"
                              onClick={handleGSTCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          {selectedGSTFiles.length > 0 && (
                            <div className="attach-scrroll-body">
                              {selectedGSTFiles.map((file, index) => (
                                <div
                                  className="attach-popup-contant-body"
                                  key={index}
                                >
                                  <div className="attach-file-container">
                                    <div className="attach-file-show">
                                      <div className="text-attachment">
                                        {file.name}
                                      </div>
                                      <div className="text-attachment-file">
                                        File Size:(
                                        {(file.size / 1024).toFixed(2)} KB)
                                      </div>
                                    </div>
                                    <div
                                      className="file-delete-btn"
                                      onClick={() => handleDeleteFile(index)}
                                    >
                                      <span>
                                        {" "}
                                        <MdDeleteOutline className="file-delete-icon" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="pan">PAN</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="pan"
                      value={vendorData.pan}
                      onChange={handleInputChange}
                      placeholder="Enter PAN"
                    />
                  </div>
                </div>
                <div className="form-group-attachments">
                  <div className="form-group">
                    <label htmlFor="pan_attachments">
                      PAN Copy Certificate
                    </label>
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={() => handleUploadButtonClick(panFileInputRef)}
                      ref={buttonRef}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      id="pan_attachments"
                      type="file"
                      ref={panFileInputRef}
                      style={{ display: "none" }}
                      onChange={(e) =>
                        handleFileInputChange(
                          e.target.files,
                          setSelectedPANFiles
                        )
                      }
                      multiple
                    />
                    {selectedPANFiles.length > 0 && (
                      <span
                        className="inquiry-file-btn"
                        onClick={handlePANIconClick}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedPANFiles.length}`}
                      </span>
                    )}
                    {isPANPopupOpen && (
                      <div className="attach-popup-amc2" ref={popupRef}>
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="fiile-close-btn"
                              onClick={handlePANCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          {selectedPANFiles.length > 0 && (
                            <div className="attach-scrroll-body">
                              {selectedPANFiles.map((file, index) => (
                                <div
                                  className="attach-popup-contant-body"
                                  key={index}
                                >
                                  <div className="attach-file-container">
                                    <div className="attach-file-show">
                                      <div className="text-attachment">
                                        {file.name}
                                      </div>
                                      <div className="text-attachment-file">
                                        File Size:(
                                        {(file.size / 1024).toFixed(2)} KB)
                                      </div>
                                    </div>
                                    <div
                                      className="file-delete-btn"
                                      onClick={() => handleDeletePanFile(index)}
                                    >
                                      <span>
                                        {" "}
                                        <MdDeleteOutline className="file-delete-icon" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group-attachments">
                  <div className="form-group">
                    <label htmlFor="incorporation_attachments">
                      Incorporation Certificate
                    </label>
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={() =>
                        handleUploadButtonClick(incorporationFileInputRef)
                      }
                      ref={buttonRef}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      id="incorporation_attachments"
                      type="file"
                      ref={incorporationFileInputRef}
                      style={{ display: "none" }}
                      onChange={(e) =>
                        handleFileInputChange(
                          e.target.files,
                          setSelectedIncorporationFiles
                        )
                      }
                      multiple
                    />
                    {selectedIncorporationFiles.length > 0 && (
                      <span
                        className="inquiry-file-btn"
                        onClick={handleIncorporationIconClick}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedIncorporationFiles.length}`}
                      </span>
                    )}
                    {isIncorporationPopupOpen && (
                      <div className="attach-popup-amc3 vendor-third-popup" ref={popupRef}>
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="fiile-close-btn"
                              onClick={handleIncorporationCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          {selectedIncorporationFiles.length > 0 && (
                            <div className="attach-scrroll-body">
                              {selectedIncorporationFiles.map((file, index) => (
                                <div
                                  className="attach-popup-contant-body"
                                  key={index}
                                >
                                  <div className="attach-file-container">
                                    <div className="attach-file-show">
                                      <div className="text-attachment">
                                        {file.name}
                                      </div>
                                      <div className="text-attachment-file">
                                        File Size:(
                                        {(file.size / 1024).toFixed(2)} KB)
                                      </div>
                                    </div>
                                    <div
                                      className="file-delete-btn"
                                      onClick={() =>
                                        handleDeleteincorporationFile(index)
                                      }
                                    >
                                      <span>
                                        {" "}
                                        <MdDeleteOutline className="file-delete-icon" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="address">
                    Address<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="address"
                      value={vendorData.address}
                      onChange={handleInputChange}
                      placeholder="Enter address"
                    />
                  </div>
                  {errors.address && (
                    <div className="error-message">{errors.address}</div>
                  )}
                </div>
                <div className="form-group msmme">
                  <label htmlFor="is_msme">
                    Is MSME ?<span className="text-danger">*</span>
                  </label>

                  <div className="checkbox-input">
                    <input
                      type="checkbox"
                      id="msme_yes"
                      name="is_msme"
                      value="yes"
                      checked={msmeChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="msmeYes">Yes</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="checkbox"
                      id="msme_no"
                      name="is_msme"
                      value="no"
                      checked={!msmeChecked}
                      onChange={() => setMsmeChecked(false)}
                    />
                    <label htmlFor="msmeNo">No</label>
                  </div>
                </div>
                <div className="form-group-attachments">
                  {msmeChecked && (
                    <div className="form-group">
                      <label htmlFor="msme_attachments">MSME Certificate</label>
                      <button
                        type="button"
                        className="challan-btn"
                        onClick={() =>
                          handleUploadButtonClick(msmeFileInputRef)
                        }
                      >
                        <FiUpload /> Upload File
                      </button>
                      <input
                        id="msme_attachments"
                        type="file"
                        ref={msmeFileInputRef}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleFileInputChange(
                            e.target.files,
                            setSelectedMSMEFiles
                          )
                        }
                        multiple
                      />
                      {selectedMSMEFiles.length > 0 && (
                        <span
                          className="inquiry-file-btn"
                          onClick={handleMSMEIconClick}
                        >
                          <MdAttachFile className="inquiry-file-icon" />{" "}
                          {`${selectedMSMEFiles.length}`}
                        </span>
                      )}
                      {isMSMEPopupOpen && (
                        <div className="attach-popup-amc fourth-popup">
                          <div className="attach-popup-contant">
                            <div className="attachment-header">
                              <div className="attachment-header-content">
                                Attachments
                              </div>
                              <button
                                className="fiile-close-btn"
                                onClick={handleMSMECloseAttachment}
                              >
                                <IoCloseOutline className="file-close-icon" />
                              </button>
                            </div>
                            {selectedMSMEFiles.length > 0 && (
                              <div className="attach-scrroll-body">
                                {selectedMSMEFiles.map((file, index) => (
                                  <div
                                    className="attach-popup-contant-body"
                                    key={index}
                                  >
                                    <div className="attach-file-container">
                                      <div className="attach-file-show">
                                        <div className="text-attachment">
                                          {file.name}
                                        </div>
                                        <div className="text-attachment-file">
                                          File Size:(
                                          {(file.size / 1024).toFixed(2)} KB)
                                        </div>
                                      </div>
                                      <div
                                        className="file-delete-btn"
                                        onClick={() =>
                                          handleDeleteMsmeFile(index)
                                        }
                                      >
                                        <span>
                                          {" "}
                                          <MdDeleteOutline className="file-delete-icon" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Place of Business<span className="text-danger">*</span>
                  </label>
                  <div className="new-frequency-rowss">
                    {vendorData.locations.map((location, index) => (
                      <div key={index} className="location-group-content">
                        <div className="location-group">
                          <div className="input-group frequency-row">
                            <textarea
                              className="point-fr place-businees"
                              name={`Location_${index}`}
                              value={location.location}
                              onChange={(e) =>
                                handleInputChange(e, index, "location")
                              }
                              placeholder={`Enter place of business ${
                                index + 1
                              }`}
                              cols="30"
                              rows="2"
                            ></textarea>
                            {/* {errors[`location_${index}`]?.location && (
                              <div className="error-message">{errors[`location_${index}`].location}</div>
                            )} */}
                          </div>
                          {errors[`location_${index}`]?.location && (
                            <div className="error-message">
                              {errors[`location_${index}`].location}
                            </div>
                          )}
                          <div className="flex-group">
                            <div>
                              <div className="input-group frequency-row">
                                <input
                                  type="text"
                                  className="point-fr"
                                  name={`ContactPersonName_${index}`}
                                  value={location.contact_person_name}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "contact_person_name"
                                    )
                                  }
                                  placeholder="Enter contact person name"
                                />
                                {/* {errors[`location_${index}`]?.contact_person_name && (
                                  <div className="error-message">{errors[`location_${index}`].contact_person_name}</div>
                                )} */}
                              </div>
                              {errors[`location_${index}`]
                                ?.contact_person_name && (
                                <div className="error-message">
                                  {
                                    errors[`location_${index}`]
                                      .contact_person_name
                                  }
                                </div>
                              )}
                              <div className="input-group frequency-row">
                                <input
                                  type="number"
                                  className="point-fr"
                                  name={`LocationContactNo_${index}`}
                                  value={location.location_contact_no}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "location_contact_no"
                                    )
                                  }
                                  placeholder="Enter location contact no"
                                />
                                {/* {errors[`location_${index}`]?.location_contact_no && (
                                  <div className="error-message">{errors[`location_${index}`].location_contact_no}</div>
                                )} */}
                              </div>
                              {errors[`location_${index}`]
                                ?.location_contact_no && (
                                <div className="error-message">
                                  {
                                    errors[`location_${index}`]
                                      .location_contact_no
                                  }
                                </div>
                              )}
                              <div className="input-group frequency-row">
                                <input
                                  type="email"
                                  className="point-fr"
                                  name={`LocationEmail_${index}`}
                                  value={location.location_email}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "location_email"
                                    )
                                  }
                                  placeholder="Enter location email"
                                />
                                {/* {errors[`location_${index}`]?.location_email && (
                                  <div className="error-message">{errors[`location_${index}`].location_email}</div>
                                )} */}
                              </div>
                              {errors[`location_${index}`]?.location_email && (
                                <div className="error-message">
                                  {errors[`location_${index}`].location_email}
                                </div>
                              )}
                            </div>
                            <div>
                              <div className="input-group frequency-row">
                                <textarea
                                  className="point-fr locaton-address"
                                  name={`LocationAddress_${index}`}
                                  value={location.location_address}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      "location_address"
                                    )
                                  }
                                  placeholder="Enter location address"
                                  cols="30"
                                  rows="6.9"
                                ></textarea>
                                {/* {errors[`location_${index}`]?.location_address && (
                                  <div className="error-message">{errors[`location_${index}`].location_address}</div>
                                )} */}
                              </div>
                              {errors[`location_${index}`]
                                ?.location_address && (
                                <div className="error-message">
                                  {errors[`location_${index}`].location_address}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="add-close-button"
                            onClick={() => handleRemoveLocation(index)}
                            disabled={vendorData.locations.length === 1}
                          >
                            <IoClose className="frequency-close-icon" />
                          </button>
                        </div>
                      </div>
                    ))}

                    <div className="add-new-frequency-customer">
                      <button
                        className="add-new-buttons"
                        type="button"
                        onClick={handleAddLocation}
                      >
                        <FaPlusCircle className="frequency-plus-icon" />
                      </button>
                    </div>
                  </div>
                  {errors.locations && (
                    <div className="error-message">{errors.locations}</div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default VendorForm;
