import React, { useState } from "react";
import "./Form.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";

function Form2({ isSidebarExpanded }) {
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const checklistItems = [
        "1) Equipment/work area inspected",
        "2) Surraounding area checked cleaned and covered",
        "3) Equipment blinded/connected/closed/isolated/wedge opened",
        "4) equipment  properly drained and depressurized",
        "5) Electrically isolated and tagged vide permit no. ............",
        "6) Equipment water flushed",
        "7) Equipment properly steamed/purged",
        "8) proper ventilation and lighting provided",
        "9) Area cordoned off & caution boards/Tags provided",
        "10) HCs= ....%LEL  Toxic gases= ....%ppm",
    ];

    const secondaryChecklistItems = [
        "1) Scaffolding Tag Provided",
        "2) Scaffolding Tag signed by contractor, supervisor and maintenance supervisor.",
        "3) Full body harness with shock absorber used.",
        "4) Scaffolding area barricaded",
        "5) IS/EN certified safety harness used.",
        "6) Safety belt life line anchoring to the rigid structure to be ensured",
        "7) In the absence of fixed means of access, provisions of lifelines with fall arresting for both vertical and horizontal movement is to be ensured.",
    ];

    const [checklist, setChecklist] = useState(
        checklistItems.map((item) => ({ label: item, status: null }))
    );
    const [secondaryChecklist, setSecondaryChecklist] = useState(
        secondaryChecklistItems.map((item) => ({ label: item, status: null }))
    );

    const handleStatusChange = (index, status, checklistType) => {
        const updatedChecklist =
            checklistType === "primary" ? [...checklist] : [...secondaryChecklist];
        updatedChecklist[index].status = status;
        checklistType === "primary"
            ? setChecklist(updatedChecklist)
            : setSecondaryChecklist(updatedChecklist);
    };

    const handleNextClick = () => {
        navigate("/cold-work-permit?tab=Form3");
    };
    const handleCancelClick = () => {
        navigate("/cold-work-permit?tab=Form1");
    };
    return (
        <>

            <div className="data-form-content">
                <div className="data-form-header">
                    <div className="data-form-header-content">
                        <div className="data-form-heading-content-left-side">
                            New Cold Work Permit
                        </div>
                        <div className="data-form-heading-content-right-side"></div>
                    </div>
                </div>
                <div className="header-separator"></div>
                <div className="data-form-body">
                    <form className="form-detail">
                        <div className="new-data-form">
                        <div className="cold-error">
            <div>Under Development</div>
            </div>
                            <div className="checklist-container">
                                {checklist.map((item, index) => (
                                    <div key={index} className="checklist-item">
                                        <label>{item.label}</label>
                                        <div className="checklist-options">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name={`status-${index}`}
                                                    value="done"
                                                    checked={item.status === 'done'}
                                                    onChange={() => handleStatusChange(index, 'done')}
                                                />
                                                Done
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name={`status-${index}`}
                                                    value="not-required"
                                                    checked={item.status === 'not-required'}
                                                    onChange={() => handleStatusChange(index, 'not-required')}
                                                />
                                                Not Required
                                            </label>
                                        </div>
                                    </div>
                                ))}

                                <div><h3>Scaffolding Erection / Working at height</h3></div>

                                {secondaryChecklist.map((item, index) => (
                                    <div key={index} className="checklist-item">
                                        <label>{item.label}</label>
                                        <div className="checklist-options">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name={`status-secondary-${index}`}
                                                    value="done"
                                                    checked={item.status === "done"}
                                                    onChange={() =>
                                                        handleStatusChange(index, "done", "secondary")
                                                    }
                                                />
                                                Done
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name={`status-secondary-${index}`}
                                                    value="not-required"
                                                    checked={item.status === "not-required"}
                                                    onChange={() =>
                                                        handleStatusChange(index, "not-required", "secondary")
                                                    }
                                                />
                                                Not Required
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="footer">
                    <div className="button-group">
                        <button
                            className="data-form-save-button"
                            type="save"
                            // onClick={handleSaveClick}
                            disabled={isLoading}
                        >
                            {isLoading ? "Saving..." : "Save"}
                        </button>
                        <button
                            className="data-form-cancel-button"
                        onClick={handleCancelClick}
                        >
                            Cancel
                        </button>
                        <button
                            className="data-form-next-button"
                            onClick={handleNextClick}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>


        </>
    );
}

export default Form2;
