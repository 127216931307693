import React, { useState, useEffect } from "react";
import "./POConfigurationForm.css";
import { useNavigate, useParams } from "react-router-dom"; // useParams to get po_id
import axios from "axios"; // Ensure axios is imported
import API_URL from "../../src/Config"; // Ensure the correct path to your config file
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";

function ApprovedTechnicalView({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams(); // Get po_id from the route parameters
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [poData, setPoData] = useState({ po_no: "" });
  const [rows, setRows] = useState([]);

  // Fetch API Data
  // const fetchData = async () => {
  //     try {
  //         const authKey = localStorage.getItem("authKey");
  //         const response = await axios.get(`${API_URL}/api/get_inbound_purchase_orders/${id}`, {
  //             headers: {
  //                 Authorization: authKey,
  //             },
  //         });

  //         const { po_list, po_list_items } = response.data; // Correctly extract from the response

  //         // Set data into the respective states
  //         setPoData({ po_no: po_list.po_no }); // Accessing PO number from po_list
  //         setSelectedVendor({
  //             label: po_list.vendor_name,
  //             value: po_list.vendor_id,
  //         });

  //         // Map items to rows for the table
  //         const rowsData = po_list_items.map((item) => ({
  //             matCodes: item.mat_code,
  //             quantity: item.quantity,
  //             price: item.price,
  //             est_unit_rate: item.est_unit_rate,
  //             description: item.description,
  //         }));

  //         setRows(rowsData);
  //     } catch (error) {
  //         console.error("Error fetching PO data:", error.message);
  //     }
  // };

  // const fetchData = async () => {
  //     try {
  //         const authKey = localStorage.getItem("authKey");
  //         const response = await axios.get(`${API_URL}/api/get_inbound_purchase_orders/${id}`, {
  //             headers: {
  //                 Authorization: authKey,
  //             },
  //         });

  //         const { inbound_purchase_orders } = response.data; // Correctly extract from the response

  //         // Assuming only one PO is returned in the array, access the first PO details
  //         const po = inbound_purchase_orders[0];

  //         // Set data into the respective states
  //         setPoData({ po_no: po.po_no }); // Accessing PO number from inbound_purchase_orders
  //         setSelectedVendor({
  //             label: po.vendor_name,
  //             value: po.vendor_id,
  //         });

  //         // Map items (if available) to rows for the table
  //         const rowsData = po.po_list_items?.map((item) => ({
  //             matCodes: item.mat_code,
  //             quantity: item.quantity,
  //             price: item.price,
  //             est_unit_rate: item.est_unit_rate,
  //             description: item.description,
  //         })) || [];

  //         setRows(rowsData);
  //     } catch (error) {
  //         console.error("Error fetching PO data:", error.message);
  //     }
  // };

  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_approved_inbound_po/${id}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      const poDataResponse = response.data.inbound_purchase_orders[0]; // Accessing the first PO from the list

      // Set data into the respective states
      setPoData({ po_no: poDataResponse.po_no }); // Accessing PO number from the response
      setSelectedVendor({
        label: poDataResponse.vendor_name,
        value: poDataResponse.vendor_id,
      });

      // Map items to rows for the table
      const rowsData = poDataResponse.items.map((item) => ({
        matCodes: item.mat_code, // Mat Code ID
        quantity: item.qty, // Quantity
        price: item.price,
        est_unit_rate: item.est_unit_rate, // Price
        description: item.description, // Description
        inbounded_qty: item.inbounded_qty,
        approved_qty: item.approved_qty,
        inbound_list_id: item.id,
      }));

      setRows(rowsData);

      // Handle attachments if needed
      const attachments = poDataResponse.attachments.map((attachment) => ({
        file_id: attachment.file_id,
        file_name: attachment.filename,
        file_path: attachment.file_path,
        file_type: attachment.file_type,
        uploaded_at: attachment.uploaded_at,
      }));

      // If you're managing attachments separately
      // setAttachments(attachments);
    } catch (error) {
      console.error("Error fetching PO data:", error.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]); // Only fetch when po_id is available

  const handleBackProject = () => {
    navigate("/technical-pr-approved-list");
  };

  return (
    <div
      className={`data-form-content-container page-content ${
        isSidebarExpanded ? "expanded" : "collapsed"
      }`}
    >
      <div className="data-form-content">
        <div className="data-form-header">
          <div className="data-form-header-content">
            <div className="data-form-heading-content-left-side">
              <Tooltip title="Back" arrow>
                <IoMdArrowBack
                  className="back-company"
                  onClick={handleBackProject}
                />
              </Tooltip>
              Approved Technical List Items
            </div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="data-form-body">
          <div className="action-purchase-header">
            <div className="project-detailss">
              <div className="project-detail-arround">
                <div className="project-details-left-side">
                  <div className="project-detail">
                    <div className="detail-header">PO Number:</div>
                    <div className="detail-part">{poData.po_no || "N/A"}</div>
                  </div>
                  <div className="project-detail">
                    <div className="detail-header">Invoice No:</div>
                    <div className="detail-part">{poData.po_no || "N/A"}</div>
                  </div>
                  <div className="project-detail">
                    <div className="detail-header">Vendor:</div>
                    {/* Display Vendor name here */}
                    <div className="detail-part">
                      {selectedVendor ? selectedVendor.label : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="action-purchase-sheet-header">
            <div className="actions-purchase-sheet-header-content">
              <div className="actions-purchase-sheet-heading-content-left-side">
                Item Table
              </div>
            </div>
          </div>

          <div className="action-purchase-body">
            <div className="action-purchase-scroll-body">
              <div className="action-purchase-table">
                <table className="action-table action-multilevel-data ">
                  <thead className="action-purchase-heads">
                    <tr>
                      <th className="th2">MAT CODE</th>
                      <th className="th3">QUANTITY</th>
                      <th className="th3">ESTIMATED UNIT RATE</th>
                      <th className="th5">DESCRIPTION</th>
                      <th className="th4">PRICE</th>
                      <th className="th4">INWARD QUANTITY</th>
                      <th className="th4">PROVISIONAL QUANTITY</th>
                    </tr>
                  </thead>
                  <tbody className="action-purchase-scroll-bodys">
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td className="th1">{row.matCodes || "N/A"}</td>
                        <td className="th2">{row.quantity || "N/A"}</td>
                        <td className="th3">{row.est_unit_rate || "N/A"}</td>
                        <td className="th5">{row.description || "N/A"}</td>
                        <td className="th4">{row.price || "N/A"}</td>
                        <td className="th4">{row.inbounded_qty || "N/A"}</td>
                        <td className="th4">{row.approved_qty || "0"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApprovedTechnicalView;
