import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import UserView from "../Views/UserView";
import useClickOutside from '../useClickOutside';

function User({ isSidebarExpanded, userID }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const buttonRef = useRef(null)
  const [popupUserID, setPopupUserID] = useState(null);
  const [sortOrders, setSortOrders] = useState({
    Name: true,
    Designation: true,
    Department: true,
  });

  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedschedules = [...users].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setUsers(sortedschedules);
  };

  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));



  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_user`, {
        headers: {
          Authorization: authKey,
        },
      });

      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      setLoading(false);
    }
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(newSelectAll ? users.map((user) => user.ID) : []);
    setChecked(newSelectAll);
  };

  const handleSelectRow = (userID) => {
    const updatedSelectedRows = selectedRows.includes(userID)
      ? selectedRows.filter((id) => id !== userID)
      : [...selectedRows, userID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === users.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchivedUsers = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_user`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ user_ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedUsers = users.filter(
          (user) => !selectedRows.includes(user.ID)
        );
        setUsers(updatedUsers);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete users");
      }
    } catch (error) {
      console.error("An error occurred while deleting users:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    // setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  // const handleSort = (column) => {
  //   setSortOrders((prevSortOrders) => ({
  //     ...prevSortOrders,
  //     [column]: !prevSortOrders[column],
  //   }));
  //   setIsSortPopup(false);
  //   if (column === sortColumn) {
  //     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  //   } else {
  //     setSortColumn(column);
  //     setSortOrder("asc");
  //   }
  // };

  const handleArchiveUser = () => {
    navigate("/archive-user");
  };

  const handleSearch = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_user`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
        },
      });

      if (response.status === 200) {
        setUsers(response.data);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      handleSearch();
    }, 600);
    return () => clearTimeout(delay);
  }, [searchInput]);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSortOrderToggle = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  const filteredUsers = users.filter((user) => {
    return (
      (user.Name?.toLowerCase() || "").includes(searchInput.toLowerCase()) ||
      (user.Designation?.toLowerCase() || "").includes(searchInput.toLowerCase()) ||
      (user.Email?.toLowerCase() || "").includes(searchInput.toLowerCase()) ||
      (user.ContactNo?.toLowerCase() || "").includes(searchInput.toLowerCase()) ||
      (user.department_name?.toLowerCase() || "").includes(searchInput.toLowerCase()) ||
      (user.role?.toLowerCase() || "").includes(searchInput.toLowerCase())
      // (user.assigned_projects?.toLowerCase() || "").includes(searchInput.toLowerCase())

    );
  });


  const sortedUsers = () => {
    if (sortColumn) {
      return filteredUsers.slice().sort((a, b) => {
        const first = a[sortColumn].toUpperCase();
        const second = b[sortColumn].toUpperCase();

        if (first < second) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (first > second) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredUsers;
  };
  const handleRefresh = () => {
    fetchData();
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };
  const togglePopup = (userID) => {
    // setPopupUserID(userID);
    // setIsMainContainerVisible(true); 
    navigate(`/user-view/${userID}`);
  };
  const handleCloseUserView = () => {
    setIsMainContainerVisible(false); // Close the user view
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" >
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">

                      <button
                        className="delete"
                        onClick={handleDeletePopup}
                      >
                        Move to archive
                      </button>

                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive? <br/> Once moved, user will no longer be able to log in.</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchivedUsers}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Users
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search users..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Link to="/userform" className="data-list-new">
                      <Tooltip title="New User" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup} ref={buttonRef}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div
                            className="sort-by"
                            onClick={handleSortOrderToggle}
                          >
                            SORT BY{" "}
                            {sortOrder[sortColumn] === "asc"
                              ? "(Ascending)"
                              : "(Descending)"}
                          </div>
                          {/* <div className="sort-by-button">
                            <button onClick={() => handleSort("Name")}>
                              Name{" "}
                              {sortOrders.Name ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("Designation")}>
                              Designation{" "}
                              {sortOrders.Designation ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("Department")}>
                              Department{" "}
                              {sortOrders.Department ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>
                          </div> */}
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("Name")}>
                              Name{" "}
                              {sortOrder.Name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("department_name")}>
                              Department{" "}
                              {sortOrder.department_name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("Designation")}>
                              Designation{" "}
                              {sortOrder.Designation === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("role")}>
                              User Type{" "}
                              {sortOrder.role === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("assigned_projects")}>
                              Project{" "}
                              {sortOrder.assigned_projects === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />



                          </div>
                          <button
                            className="delete-item"
                            onClick={handleArchiveUser}
                          >
                            Archived User
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div class="header-separator"></div>
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : users.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead className="data-list-head">
                        <tr>
                          <th className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th className="center-alignment sr-no">SR. NO.</th>
                          <th>NAME</th>
                          <th>DEPARTMENT</th>
                          <th>DESIGNATION</th>
                          <th>EMAIL</th>
                          <th className="center-alignment">CONTACT NO</th>
                          <th>USER TYPE</th>
                          <th>ASSIGNED PROJECTS</th>
                          <th className="center-alignment">CREATED AT</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {users.map((user, index) => (
                          <tr key={user.ID} className="hoverable-row">
                            <td className="center-alignment">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(user.ID)}
                                onChange={() => handleSelectRow(user.ID)}
                              />
                            </td>

                            <td className="center-alignment">{index + 1}</td>
                            {/* <td>{user.Name || "NA"}</td> */}
                            <td className="view-data" onClick={() => togglePopup(user.ID)}>{user.Name || "NA"}</td>
                            <td>{user.department_name || "NA"}</td>
                            <td>{user.Designation || "NA"}</td>
                            <td>{user.Email || "NA"}</td>
                            <td className="center-alignment">{user.ContactNo || "NA"}</td>
                            <td>{user.role || "NA"}</td>
                            <td>
                              {user.assigned_projects?.length > 0 ? (
                                <div className="assigned-projects-list">
                                  {user.assigned_projects.map((project) => (
                                    <li key={project}>{project}</li>
                                  ))}
                                </div>
                              ) : (
                                <span>No Projects Assigned</span>
                              )}
                            </td>
                            <td className="center-alignment">{formatDate(user.created_at || "NA")}</td>
                            <td className="edit-pop center-alignment">
                              <button
                                className="edit-data-button"
                                onClick={() =>
                                  handleButtonClick(user.serial_number)
                                }
                              >
                                <div className="edit-button">
                                  <MdExpandMore />
                                </div>
                              </button>
                            </td>
                            {selectedRowIndex === user.serial_number && (
                              <div className="edit-popup">
                                <div className="edit-popup-contant">
                                  <div className="edit-popup-button">
                                    <Link
                                      to={`/edit-user/${user.ID}`}
                                      className="inquiry-new"
                                    >
                                      <button className="edit-button-inquiry">
                                        <MdOutlineModeEditOutline /> Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">No Users Found</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* {isMainContainerVisible && (
          <div className="main-containers">
            <UserView userID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )} */}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default User;
