import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";

function Form1({ isSidebarExpanded }) {
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [issuedTo, setIssuedTo] = useState(null);
    const [areaUnit, setAreaUnit] = useState(null);

    const [departmentData, setDepartmentData] = useState({
        DepartmentName: "",
    });
    const options = [
        { value: 'department', label: 'Department' },
        { value: 'section', label: 'Section' },
        { value: 'contractor', label: 'Contractor' },
    ];
    const issuedToOptions = [
        { value: 'department', label: 'Department' },
        { value: 'section', label: 'Section' },
        { value: 'contractor', label: 'Contractor' },
    ];


    const areaUnitOptions = [
        { value: 'area', label: 'Area' },
        { value: 'unit', label: 'Unit' },
        { value: 'equipment_no', label: 'Equipment No' },
    ];

    const handleIssuedToChange = (selectedOption) => {
        setIssuedTo(selectedOption);
        console.log("Issued to:", selectedOption);
    };

    const handleAreaUnitChange = (selectedOption) => {
        setAreaUnit(selectedOption);
        console.log("Area Unit / Equipment No:", selectedOption);
    };

    const handleNextClick = () => {
        navigate("/cold-work-permit?tab=Form4");
    };
    const handleCancelClick = () => {
        navigate("/cold-work-permit?tab=Form2");
    };

    return (
        <>
          
                <div className="data-form-content">
                    <div className="data-form-header">
                        <div className="data-form-header-content">
                            <div className="data-form-heading-content-left-side">
                                New Cold Work Permit
                            </div>
                            <div className="data-form-heading-content-right-side"></div>
                        </div>
                    </div>
                    <div className="header-separator"></div>
                    <div className="data-form-body">
                        <form className="form-detail">
                            <div className="new-data-form">
                            <div className="cold-error">
            <div>Under Development</div>
            </div>
                           
                                <div className="form-group">
                                <label htmlFor="description">Additional Precaution, if any</label>
                                <div className="input-group">
                                  <textarea
                                    type="message"
                                    rows={4}
                                    cols={5}
                                    className="textarea"
                                   s
                                    placeholder="Enter Additional Precaution"
                                  />
                                </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="footer">
                        <div className="button-group">
                            <button
                                className="data-form-save-button"
                                type="save"
                                // onClick={handleSaveClick}
                                disabled={isLoading}
                            >
                                {isLoading ? "Saving..." : "Save"}
                            </button>
                            <button
                                className="data-form-cancel-button"
                            onClick={handleCancelClick}
                            >
                                Cancel
                            </button>
                            <button
                                className="data-form-next-button"
                            onClick={handleNextClick}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
           

        </>
    );
}

export default Form1;
