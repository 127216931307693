import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import Select from "react-select";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { FiUpload } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";

function EditSafetyInduction({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [departmentData, setDepartmentData] = useState({});

  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedEC, setSelectedEC] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [initialEC, setInitialEC] = useState(null);
  const [actualManPowerIdFromApi, setActualManPowerIdFromApi] = useState(null);
  const [ecOptions, setEcOptions] = useState([]); // Dropdown options
  const [employeeName, setEmployeeName] = useState("");
  const [ecData, setEcData] = useState([]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleUploadButtonClick = (inputRef) => {
    // This function handles the file upload button click
    // Open the file input dialog
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const handleFileInputChange = (files, setFiles) => {
    const fileArray = Array.from(files);
    setFiles(fileArray);
  };
  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };
  const handleDeleteFile = (indexToDelete) => {
    console.log("Delete function called for index:", indexToDelete);
    console.log("Before delete:", selectedFiles);

    // Filter out the file at the given index
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state
    setSelectedFiles(updatedFiles);
    console.log("After delete:", updatedFiles);

    // Check if no files remain, then close the popup
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
      console.log("Popup closed");
    }
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const fetchECNo = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_ec_no`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setEcData(data);

        // Map the API response to the options for the dropdown
        const options = data.map((item) => ({
          value: item.id,
          label: item.ec_no,
          person_name: item.employee_name,
        }));
        setEcOptions(options);
      } else {
        showSnackbar("Failed to fetch EC Numbers.", "error");
      }
    } catch (error) {
      showSnackbar("An error occurred while fetching EC Numbers.", "error");
      console.error("Error fetching EC Numbers:", error);
    }
  };

  useEffect(() => {
    fetchECNo();
  }, []);

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);

    // Reset fields when category changes
    if (selectedOption && selectedOption.value === "Employee") {
      setSelectedEC(null);
      setSelectedEmployee(null);
      setEmployeeName("");
    } else {
      setEmployeeName("");
    }
  };

  // // Handle EC dropdown change
  // const handleECChange = (selectedOption) => {
  //   setSelectedEC(selectedOption);
  // };
  const handleECChange = (selectedOption) => {
    setSelectedEC(selectedOption); // Set the EC No selection
    const selectedEmployee = ecData.find(
      (ec) => ec.id === selectedOption.value
    );
    if (selectedEmployee) {
      setEmployeeName(selectedEmployee.person_name); // Auto-fill employee name from EC No
    } else {
      setEmployeeName(""); // Clear employee name if no match
    }
  };
  // Handle Name of Employee dropdown change

  // Handle free text input for Name of Employee
  const handleEmployeeNameChange = (e) => {
    setEmployeeName(e.target.value);
    setSelectedEC(null);
  };

  const categoryOptions = [
    { value: "Employee", label: "Employee" },
    { value: "Visitor", label: "Visitor" },
    { value: "TemporaryWorker", label: "Temporary Worker" },
  ];
  // useEffect(() => {
  //   const fetchSafetyInductionData = async () => {
  //     try {
  //       const authKey = localStorage.getItem("authKey");
  //       const response = await axios.get(
  //         `${API_URL}/api/get_safety_induction/${id}`,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //           },
  //         }
  //       );

  //       const responseData = response.data.safety_induction;

  //       // Set state with response data
  //       setActualManPowerIdFromApi(responseData.actual_man_power_id);
  //       setSelectedCategory({ value: responseData.category, label: responseData.category });
  //       setEmployeeName(responseData.employee_name);
  //       setSelectedEC({ value: responseData.actual_man_power_id, label: responseData.ec_no });
  //       setSelectedFiles([]); // If there are files to be displayed, handle it here.
  //       setInitialEC({ value: responseData.id, label: responseData.ec_no });
  //     } catch (error) {
  //       console.error("Error fetching safety induction data:", error.message);
  //       setError("An error occurred while fetching data");
  //     }
  //   };

  //   fetchSafetyInductionData();
  // }, [id]);
  useEffect(() => {
    const fetchSafetyInductionData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_safety_induction/${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );

        const responseData = response.data.safety_induction;

        // Set state with response data
        setActualManPowerIdFromApi(responseData.actual_man_power_id);
        setSelectedCategory({
          value: responseData.category,
          label: responseData.category,
        });
        setEmployeeName(responseData.employee_name);
        setSelectedEC({
          value: responseData.actual_man_power_id,
          label: responseData.ec_no,
        });
        setInitialEC({ value: responseData.id, label: responseData.ec_no });

        // Set fetched file data to selectedFiles state
        setSelectedFiles(responseData.files || []);
      } catch (error) {
        console.error("Error fetching safety induction data:", error.message);
        setError("An error occurred while fetching data");
      }
    };

    fetchSafetyInductionData();
  }, [id]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDepartmentData({ ...departmentData, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    // navigate("/department");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // const handleSaveClick = async () => {
  //   const errors = {};
  //   let error = false;
  //   Object.keys(departmentData).forEach((key) => {
  //     if (!departmentData[key]) {
  //       errors[key] = `${key} is required`;
  //       setSeverity("warning");
  //       showSnackbar("Required fields are empty");
  //       error = true;
  //     }
  //   });

  //   if (error === false) {
  //     try {
  //       setIsLoading(true);
  //       const authKey = localStorage.getItem("authKey");
  //       const response = await axios.put(
  //         `${API_URL}/api/update_safety_induction/${id}`,
  //         departmentData,
  //         {
  //           headers: {
  //             Authorization: authKey,
  //           },
  //         }
  //       );
  //       console.log(response.data.message);
  //       if (response.status === 200) {
  //         setSeverity("success");
  //         showSnackbar("Data updated successfully");
  //         setTimeout(() => {
  //           navigate("/department");
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error updating company:", error.message);
  //       setError("An error occurred while updating company data");
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const handleSaveClick = async () => {
    const formData = new FormData();
    const authKey = localStorage.getItem("authKey");

    // Append form data fields
    if (selectedCategory) formData.append("category", selectedCategory.value);
    // if (selectedEC) {
    //   formData.append("actual_man_power_id", selectedEC.value); // Send the EC's id as actual_man_power_id
    // }

    if (selectedEC) {
      console.log("Selected EC:", selectedEC); // Verify selectedEC is correct
      formData.append("actual_man_power_id", selectedEC.value);
    }

    // Append employee name from selectedEmployee if available, otherwise use employeeName
    const finalEmployeeName = selectedEmployee || employeeName;
    if (finalEmployeeName) {
      formData.append("employee_name", finalEmployeeName);
    }

    // Append files
    selectedFiles.forEach((file) => {
      formData.append("safety_induction_report", file);
    });

    try {
      setIsLoading(true);

      const response = await axios.put(
        `${API_URL}/api/update_safety_induction/${id}`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle success response
      if (response.status === 200) {
        showSnackbar("Safety Induction updated successfully");
        setTimeout(() => {
          navigate("/safety-induction");
        }, 1000);
      }
    } catch (error) {
      showSnackbar("Error saving Safety Induction.", "error");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackProject = () => {
    navigate("/safety-induction");
  };

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Safety Induction
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="DepartmentName">
                    Category<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="category"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    options={categoryOptions}
                    placeholder="Select a category..."
                    isClearable
                  />
                </div>
                {selectedCategory && selectedCategory.value === "Employee" && (
                  <div className="form-group-select">
                    <label htmlFor="ec">
                      EC No <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="ec"
                      value={selectedEC}
                      onChange={handleECChange}
                      options={ecOptions}
                      placeholder="Select an EC..."
                      isClearable
                    />
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="employeeName">
                    Name of Employee <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      id="employeeName"
                      value={employeeName}
                      onChange={handleEmployeeNameChange}
                      placeholder="Enter name of employee..."
                    />
                  </div>
                </div>
                <div className="form-group-attachments">
                  <div className="form-group">
                    <label htmlFor="gst_attachments">
                      Safety Induction Report{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={() => {
                        handleUploadButtonClick(fileInputRef);
                      }}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      type="file"
                      id="gst_attachments"
                      ref={fileInputRef}
                      onChange={(e) =>
                        handleFileInputChange(e.target.files, setSelectedFiles)
                      }
                      style={{ display: "none" }}
                      multiple
                    />
                    {selectedFiles.length > 0 && (
                      <span
                        className="inquiry-file-btn"
                        onClick={handleIconClick}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedFiles.length}`}
                      </span>
                    )}
                    {isPopupOpen && (
                      <div className="attach-popup-amc1">
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="fiile-close-btn"
                              onClick={handleCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          {selectedFiles.length > 0 && (
                            <div className="attach-scrroll-body">
                              {selectedFiles.map((file, index) => (
                                <div
                                  className="attach-popup-contant-body"
                                  key={index}
                                >
                                  <div className="attach-file-container">
                                    <div className="attach-file-show">
                                      <div className="text-attachment">
                                        {file.filename || file.name}
                                      </div>
                                      <div className="text-attachment-file">
                                        File Size:{" "}
                                        {(
                                          (file.file_size || file.size) / 1024
                                        ).toFixed(2)}{" "}
                                        KB
                                      </div>
                                    </div>
                                    <div
                                      className="file-delete-btn"
                                      onClick={() => handleDeleteFile(index)}
                                    >
                                      <span>
                                        <MdDeleteOutline className="file-delete-icon" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditSafetyInduction;
