import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import { format } from "date-fns";
import { FiPlus } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";

function EditDailyProgress({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [error, setError] = useState({});
  const buttonRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [progressreport, setProgressreport] = useState({});
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchDailyProgressData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_daily_progress_report/${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );

        // Assuming response contains the required fields
        const responseData = response.data;
        setProgressreport({
          date: new Date(responseData.date), // Parse date
          id: responseData.id || "",
          description: responseData.description || "",
          attachments: responseData.attachments || [],
        });
        setSelectedFiles(responseData.attachments || []);
      } catch (error) {
        console.error("Error fetching progress data:", error.message);
        setError("An error occurred while fetching progress data");
      }
    };

    fetchDailyProgressData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProgressreport({ ...progressreport, [name]: value });
    // Clear validation error when input changes
  };

  const handleCancelClick = () => {
    // navigate("/department");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
  
      const authKey = localStorage.getItem("authKey");

 
      // Validation for Date
      if (!progressreport.date) {
        showSnackbar("Date is required. Please select a date.", "error", { color: "red" });
        setSeverity("error");
        setIsLoading(false);
        return;
      }
  
      // Validation for Attachments
      if (Array.isArray(attachmentFile) && attachmentFile.length === 0) {
        showSnackbar("At least one attachment is required.", "error", { color: "red" });
        setIsLoading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append("report_id", progressreport.id); // Use the fetched report ID
      const formattedDate = new Date(progressreport.date)
        .toISOString()
        .split("T")[0];
      formData.append("date", formattedDate);
      formData.append("description", progressreport.description || "");
  
      if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
        attachmentFile.forEach((file) => {
          formData.append("attachments", file);
        });
      }
  
      const response = await axios.put(
        `${API_URL}/api/update_daily_progress_report`, // Backend API endpoint
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data", // Required for FormData
          },
        }
      );
  
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar(response.data.message || "Data updated successfully");
        setTimeout(() => {
          navigate("/daily-work-progress");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating daily progress report:", error.message);
      setError("An error occurred while updating the daily progress report");
      showSnackbar(error.response?.data?.message || "Update failed", "error", { color: "red" });
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleBackProject = () => {
    navigate("/daily-work-progress");
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setProgressreport((prevState) => ({
      ...prevState,
      date: date,
    }));
  };
  const handleUploadButtonClick = () => {
    // Trigger click event of file input when upload button is clicked
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };
  const handleIconClick = () => {
    // Show selected files when icon is clicked
    setIsPopupOpen(true);
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };
  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  {" "}
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />{" "}
                </Tooltip>
                Edit Daily Progress Report
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
              
                <div className="form-group">
                  <label htmlFor="date">Date  <span className="text-danger">*</span></label>
                  <div className="input-group">
                    <DatePicker
                      selected={progressreport.date}
                      onChange={(date) =>
                        setProgressreport({ ...progressreport, date })
                      }
                      name="date"
                      id="date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>

                {/* Description Section */}
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <div className="input-group">
                    <textarea
                      name="description"
                      value={progressreport.description}
                      onChange={(e) =>
                        setProgressreport({
                          ...progressreport,
                          description: e.target.value,
                        })
                      }
                      placeholder="Enter description"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* File Upload Section */}
                <div className="form-group">
                  <label htmlFor="attachments">Attachment  <span className="text-danger">*</span></label>
                  <div className="input-group">
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={handleUploadButtonClick}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      id="attachments"
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                      multiple
                    />
                    {selectedFiles.length > 0 && (
                      <span
                        className="inquiry-file-btn"
                        onClick={handleIconClick}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedFiles.length}`}
                      </span>
                    )}
                    {isPopupOpen && (
                      <div className="attach-popup-amc4 Edit-pr-popup">
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="file-close-btn"
                              onClick={handleCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          <div className="attach-scroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                key={index}
                                className="attach-popup-content-body"
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.filename || file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:{" "}
                                      {(
                                        (file.file_size || file.size) / 1024
                                      ).toFixed(2)}{" "}
                                      KB
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <MdDeleteOutline className="file-delete-icon" />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditDailyProgress;
