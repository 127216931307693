import React, { useState, useEffect, useRef } from "react";
import "./POConfigurationForm.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import API_URL from "../../src/Config";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
function OutwardView({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [outwardData, setOutwardData] = useState({ po_no: "" });
  const [rows, setRows] = useState([]);

  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const [isPopupOpen, setIsPopupOpen] = useState(null);
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  const handleIconClick = (index, event) => {
    event.preventDefault(); // Prevent default behavior if necessary
    event.stopPropagation(); // Prevent the click from bubbling up

    // Toggle the popup for the specific index
    setIsPopupOpen((prevIndex) => (prevIndex === index ? null : index));
  };

//   const fetchData = async () => {
//     try {
//       const authKey = localStorage.getItem("authKey");
//       const response = await axios.get(
//         `${API_URL}/api/get_product_outward_items/${id}`,
//         {
//           headers: {
//             Authorization: authKey,
//           },
//         }
//       );

//       const { product_outward_items } = response.data;

//       // Set data into the respective states
//       setOutwardData({
//         po_no: response.data.po_no,
//         attachments: response.attachments || [],
//       }); // Assuming po_no is in the response
//       setSelectedVendor({
//         label: response.data.vendor_name,
//         value: response.data.vendor_id,
//       });

//       // Map items to rows for the table
//       const rowsData = product_outward_items.map((item) => ({
//         id: item.id,
//         mat_code: item.mat_code,
//         outwarded_qty: item.outwarded_qty,
//         mat_code_description: item.mat_code_description,
//       }));

//       setRows(rowsData);
//     } catch (error) {
//       console.error("Error fetching PO data:", error.message);
//     }
//   };

const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(
        `${API_URL}/api/get_product_outward_items/${id}`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
  
      // Destructure data and attachments properly from response.data
      const { product_outward_items, attachments } = response.data;
  
      // Check if attachments are properly destructured and log
      console.log('Attachments:', attachments);
  
      // Set data into the respective states
      setOutwardData({
        po_no: response.data.po_no,
        attachments: attachments || [], // Ensure it's set as an array
      });
  
      setSelectedVendor({
        label: response.data.vendor_name,
        value: response.data.vendor_id,
      });
  
      // Map items to rows for the table
      const rowsData = product_outward_items.map((item) => ({
        id: item.id,
        mat_code: item.mat_code,
        outwarded_qty: item.outwarded_qty,
        mat_code_description: item.mat_code_description,
      }));
  
      setRows(rowsData);
    } catch (error) {
      console.error("Error fetching PO data:", error.message);
    }
  };
  

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleBackProject = () => {
    navigate("/outward-list");
  };

  return (
    <div
      className={`data-form-content-container page-content ${
        isSidebarExpanded ? "expanded" : "collapsed"
      }`}
    >
      <div className="data-form-content">
        <div className="data-form-header">
          <div className="data-form-header-content">
            <div className="data-form-heading-content-left-side">
              <Tooltip title="Back" arrow>
                <IoMdArrowBack
                  className="back-company"
                  onClick={handleBackProject}
                />
              </Tooltip>
              Material Issue List Items
            </div>
            <div className="data-form-heading-content-right-side">
              {outwardData.attachments &&
                outwardData.attachments.length > 0 && (
                  <div>
                    {/* Single trigger button for all attachments */}
                    <span
                      className="inquiry-file-btns"
                      onClick={(event) => handleIconClick(0, event)} // Use a fixed index for a single button
                      ref={buttonRef}
                    >
                      <MdAttachFile className="inquiry-file-icon" />
                    </span>
                    {isPopupOpen === 0 && ( // Keep the condition to show the popup based on a single index
                      <div
                        className="polist-popup1"
                        ref={containerRef}
                      >
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="fiile-close-btn"
                              onClick={handleCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          <div className="attach-popup-contant-body">
                            <div className="inquiry-attach-file-containerssss">
                              {/* Iterate through all attachments and display them */}
                              {outwardData.attachments.map(
                                (attachment, attachmentIndex) => (
                                  <div
                                    className="inquiry-attach-file-container"
                                    key={attachmentIndex}
                                  >
                                    <div className="inquiry-attacth-file-model">
                                      <div className="attach-file-show">
                                        <div className="text-attachment">
                                          {attachment.filename.length > 20
                                            ? `${attachment.filename.substring(
                                                0,
                                                15
                                              )}...`
                                            : attachment.filename}
                                        </div>
                                        <div className="text-attachment-file">
                                          File Size:{" "}
                                          {attachment.file_size || "NA"} KB
                                        </div>
                                      </div>

                                      <div className="file-footer">
                                        <a
                                          href={`${API_URL}/api/download_attachment/${attachment.file_id}/${attachment.file_type}`}
                                          download
                                          className="file-download-attachment file-txt"
                                        >
                                          Download
                                        </a>
                                        <a
                                          href={`${API_URL}/api/view_attachment/${attachment.file_id}/${attachment.file_type}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="file-download-attachment file-txt"
                                        >
                                          View
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="data-form-body">
          {/* <div className="action-purchase-header">
                        <div className="project-detailss">
                            <div className="project-detail-arround">
                                <div className="project-details-left-side">
                                    <div className="project-detail">
                                        <div className="detail-header">MIN Number:</div>
                                        <div className="detail-part">{outwardData.po_no || "N/A"}</div>
                                    </div>
                                 
                                  
                                </div>
                            </div>
                        </div>
                    </div> */}

          <div className="action-purchase-sheet-header">
            <div className="actions-purchase-sheet-header-content">
              <div className="actions-purchase-sheet-heading-content-left-side">
                Item Table
              </div>
            </div>
          </div>

          <div className="action-purchase-body">
            <div className="action-purchase-scroll-body">
              <div className="action-purchase-table">
                <table className="action-table action-multilevel-data ">
                  <thead className="action-purchase-heads">
                    <tr>
                      <th className="th2">MAT CODE</th>
                      <th className="th5">MAT CODE DESCRIPTION</th>
                      {/* <th className="th4 quantity">AVAILABLE QTY</th> */}
                      <th className="th3 total">OUTWARDED QTY </th>
                    </tr>
                  </thead>
                  <tbody className="action-purchase-scroll-bodys">
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td className="th1">{row.mat_code || "N/A"}</td>
                        <td className="th5">
                          {row.mat_code_description || "N/A"}
                        </td>
                        {/* <td className="th4 quantity">{row.price || "N/A"}</td> */}
                        <td className="th2 total">
                          {row.outwarded_qty || "N/A"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OutwardView;
