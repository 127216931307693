import React, { useState, useEffect, useRef } from "react";
import "./UserView.css";
import { Link, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import "../Responsive/TableResponsive.css";

import { HiOutlinePlus } from "react-icons/hi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { MdOutlineModeEditOutline } from "react-icons/md";

function CustomerView({isSidebarExpanded }) {
    const [users, setUsers] = useState([]);
    const [selectedUserID, setSelectedUserID] = useState(null);
    const navigate = useNavigate();
    const {customerID} = useParams();
    const containerRef = useRef(null);
    const [customerData, setCustomerData] = useState(null);

    const handleBackView = () => {
        navigate("/customer")
    };
    const handleClose = () => {
        navigate("/customer")
    }
    // const handleEdit = (customerID) => {
    //     navigate(`/edit-customer/${customerID}`);
    // };

    const handleEdit = () => {
        if (selectedUserID) {
            navigate(`/edit-customer/${selectedUserID}`);
        } else {
            navigate(`/edit-customer/${customerID}`);
        }
    };

    const fetchData = async (currentPage, itemsPerPage) => {
        try {
            const authKey = localStorage.getItem("authKey");
            const response = await axios.get(`${API_URL}/api/get_customers`, {
                headers: { Authorization: authKey },
                params: {
                    page: currentPage,
                    per_page: itemsPerPage,
                  },
            });

            setUsers(response.data.Customers);
            // setTotalItems(response.data.TotalItems);
        } catch (error) {
            console.error("Error fetching user data:", error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    
        const fetchCustomerData = async (customerID) => {
            try {
                const authKey = localStorage.getItem("authKey");
                const response = await axios.get(`${API_URL}/api/get_customer/${customerID}`, {
                    headers: {
                        Authorization: authKey,
                    },
                });
    
                // Set customerData directly since the response is an object
                setCustomerData(response.data);
                setSelectedUserID(customerID);
            } catch (error) {
                console.error("Error fetching user data:", error.message);
            }
        };
    
       
        const togglePopup = async () => {
            try {
                const authKey = localStorage.getItem("authKey");
                const response = await axios.get(`${API_URL}/api/get_customer/${customerID}`, {
                    headers: {
                        Authorization: authKey,
                    },
                });
                setCustomerData(response.data);
                setSelectedUserID(customerID);
            } catch (error) {
                console.error("Error fetching user data:", error.message);
    
            }
        };
    
        useEffect(() => {
            togglePopup();
          }, []);


     
    return (
        <>
            <div
                className={`main-container page-content${isSidebarExpanded ? "expanded" : "collapsed"
                    }`}
            >
                <div
                    className={`data-lists-content-container page-contents ${isSidebarExpanded ? "expanded" : "collapsed"
                        }`}
                >
                    <div className="data-list-content" ref={containerRef}>
                      
                            <div className="data-list-header">
                                <div className="data-list-header-content">
                                    <div className="data-list-heading-content-left-side">
                                        All Customers
                                    </div>
                                    <div className="data-list-heading-content-right-side">
                                        <Link to="/userform" className="data-list-new">
                                            <Tooltip title="New User" arrow>
                                                <button className="new-button">
                                                    <HiOutlinePlus className="new-plus-icon" />
                                                </button>
                                            </Tooltip>
                                        </Link>


                                    </div>
                                </div>
                            </div>
                   
                        <div className="data-list-body">
                            <div className="data-list-scroll-body">
                                <div className="data-list-table">



                                    <table className="table multilevel-data">
                                        <thead>
                                            <tr>
                                            <th>SR. NO.</th>
                                                <th>CUSTOMER NAME</th>
                                            </tr>
                                        </thead>
                                        <tbody className="data-list-scroll-bodys">
                                       
                                                 {users.map((customer, index) => (
                                                <tr key={customer.ID} className="hoverable-row">
                                                     <td>{index + 1}</td>


                                                    <td onClick={() => fetchCustomerData(customer.id)} className={selectedUserID === customer.id ? 'selected-user' : ''} style={{ cursor: "pointer", color: "blue" }}>
                                                        {customer.customer_name || "NA"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="data-view-popup">
                    <div className="data-popup-content">
                        <div className="data-view">
                            <div className="data-view-contant">
                                <div className="data-view-header">
                                    <div className="data-view-header-left-side">
                                        {customerData && (
                                            <div className="user-header-title">
                                                <div>
                                                    <Tooltip title="Back" arrow>  <IoMdArrowBack className="back-company" onClick={handleBackView} /> </Tooltip>
                                                    {customerData.customer_name || "NA"}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="user-view-header-right-side">
                                        <div className="header-title-edit">
                                            <button className='edit-header-button' onClick={handleEdit}><MdOutlineModeEditOutline className="edit-view-icon" />Edit</button>
                                            <div className="view-close-button" onClick={handleClose} >
                                                <IoCloseOutline />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="user-view-body">
                                    <div className="rows">
                                        {customerData && (
                                            <div className="user-view-body-container">
                                                <div className="user-view-body-container-content">
                                                    <div className="text-medium-header">Customer Details</div>
                                                    <div className="text-medium-body">
                                                        <div className="row">
                                                            <label htmlFor="">Customer Name</label>
                                                            <div className="answer">{customerData.customer_name || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Contact No</label>
                                                            <div className="answer">{customerData.contact_no || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Address</label>
                                                            <div className="answer">{customerData.address || "NA"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <label htmlFor="">Location</label>
                                                            <div className="answer">{customerData.locations ? customerData.locations.map((loc, index) => (
                                                                <div key={index}>
                                                                    {loc.address || "NA"}
                                                                </div>
                                                            )) : "NA"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default CustomerView;
