import React, { useState, useEffect, useRef } from "react";
import "./UserView.css";
import { Link, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";

function UserView({ isSidebarExpanded }) {
  const [users, setUsers] = useState([]);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const { userID } = useParams();
  const [userData, setUserData] = useState(null);
  const containerRef = useRef(null);
  const handleBackView = () => {
    // onClose();
    navigate("/user");
  };
  const handleClose = () => {
    // onClose();
    navigate("/user");
  };
  const handleEdit = (userId) => {
    navigate(`/edit-user/${userId}`);
  };

  const togglePopup = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_user/${userID}`, {
        headers: {
          Authorization: authKey,
        },
      });
      setUserData(response.data);
      setSelectedUserID(userID);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };
  useEffect(() => {
    togglePopup();
  }, []);

  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_user`, {
        headers: {
          Authorization: authKey,
        },
      });

      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  const fetchUserData = async (userID) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_user/${userID}`, {
        headers: {
          Authorization: authKey,
        },
      });
      setUserData(response.data);
      setSelectedUserID(userID);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    }
  };

  return (
    <>
      <div
        className={`main-container page-content${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div
          className={`data-lists-content-container page-contents ${
            isSidebarExpanded ? "expanded" : "collapsed"
          }`}
        >
          <div className="data-list-content" ref={containerRef}>
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                  All Users
                </div>
                <div className="data-list-heading-content-right-side">
                  <Link to="/userform" className="data-list-new">
                    <Tooltip title="New User" arrow>
                      <button className="new-button">
                        <HiOutlinePlus className="new-plus-icon" />
                      </button>
                    </Tooltip>
                  </Link>
                </div>
              </div>
            </div>

            <div className="data-list-body">
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th>SR. NO.</th>
                        <th>NAME</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {users.map((user, index) => (
                        <tr key={user.ID} className="hoverable-row">
                          <td
                            onClick={() => fetchUserData(user.ID)}
                            className={
                              selectedUserID === user.ID ? "selected-user" : ""
                            }
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            {index + 1}
                          </td>

                          <td
                            onClick={() => fetchUserData(user.ID)}
                            className={
                              selectedUserID === user.ID ? "selected-user" : ""
                            }
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            {user.Name || "NA"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="data-view-popup">
          <div className="data-popup-content">
            <div className="data-view">
              <div className="data-view-contant">
                <div className="data-view-header">
                  <div className="data-view-header-left-side">
                    {userData &&
                      userData.map((item, index) => (
                        <div className="user-header-title" key={index}>
                          <div>
                            <Tooltip title="Back" arrow>
                              {" "}
                              <IoMdArrowBack
                                className="back-company"
                                onClick={handleBackView}
                              />{" "}
                            </Tooltip>
                            {item.Name}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="user-view-header-right-side">
                    <div className="header-title-edit">
                      <button
                        className="edit-header-button"
                        onClick={() => handleEdit(userData[0]?.ID)}
                      >
                        <MdOutlineModeEditOutline className="edit-view-icon" />
                        Edit
                      </button>
                      <div className="view-close-button" onClick={handleClose}>
                        <IoCloseOutline />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="user-view-body">
                  <div className="rows">
                    {userData && (
                      <div className="user-view-body-container">
                        <div className="user-view-body-container-content">
                          <div className="text-medium-header">User Details</div>
                          {userData.map((item, index) => (
                            <div className="text-medium-body" key={index}>
                              <div className="row">
                                <label htmlFor="">Name</label>
                                <div className="answer">{item.Name}</div>
                              </div>
                              <div className="row">
                                <label htmlFor="">Department</label>
                                <div className="answer">
                                  {item.department_name}
                                </div>
                              </div>
                              <div className="row">
                                <label htmlFor="">Designation</label>
                                <div className="answer">{item.Designation}</div>
                              </div>
                              <div className="row">
                                <label htmlFor="">Email</label>
                                <div className="answer">{item.Email}</div>
                              </div>
                              <div className="row">
                                <label htmlFor="">Contact No</label>
                                <div className="answer">{item.ContactNo}</div>
                              </div>
                              <div className="row">
                                <label htmlFor="">User Type</label>
                                <div className="answer">{item.role}</div>
                              </div>

                              <div className="row">
                                <label htmlFor="">Assigned Project</label>
                                <div className="answer">
                                  {item.assigned_projects}
                                </div>
                              </div>

                              <div className="row">
                                <label htmlFor="">Created at</label>
                                <div className="answer">
                                  {formatDate(item.created_at)}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default UserView;
