import React, { useState, useEffect, useRef } from "react";
import "./UserForm.css";
import "./PRRequestForm.css";
import "../Responsive/FormResponsive.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import { FiUpload } from "react-icons/fi";
import { format } from "date-fns";
import { IoCloseOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import useClickOutside from "../useClickOutside";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
function PRRequestForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedVendor, setSelectedVendor] = useState(null);
  const navigate = useNavigate();
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [matCodes, setMatCodes] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const fileInputRef = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const popupRef = useRef(null)
  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState("");
  const [rows, setRows] = useState([
    {
      matCodes: "",
      unit: "",
      quantity: "",
      est_unit_rate: "",
      description: "",
    },
  ]);
  const [prRequestData, setPrRequestData] = useState({
    department_id: "",
    department_name: "",
    pr_type: "",
    project_id: "",
    vendor_name: "",
    vendor_id: "",
    preferred_vendor: "",
    expenditure_type: "",
    expected_delivery_date: "",
    description: "",
    attachments: [], // Array of files
    filetype: [], // Array of file types
  });
  const { theme } = useContext(ThemeContext);
  const [vendorType, setVendorType] = useState("");
  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        matCodes: "",
        unit: "",
        quantity: "",
        est_unit_rate: "",
        description: "",
      },
    ]);
  };

  const handleVendorTypeChange = (event) => {
    const vendorTypeValue = event.target.value;
    setVendorType(vendorTypeValue);

    // Deselect the selected vendor and remove vendor_name and vendor_id
    setSelectedVendor(null);
    setPrRequestData((prevState) => ({
      ...prevState,
      preferred_vendor: vendorTypeValue,
      vendor_name: "", // Reset vendor_name
      vendor_id: "", // Reset vendor_id
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      preferred_vendor: "",
    }));
  };

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption);
    setPrRequestData((prevprRequestData) => ({
      ...prevprRequestData,
      vendor_name: selectedOption ? selectedOption.label : "",
      vendor_id: selectedOption ? selectedOption.value : "",
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      vendor_name: "",
    }));
  };

  const handleExpenditureTypeChange = (event) => {
    const expenditureTypeValue = event.target.value;
    // setVendorType(expenditureTypeValue);
    setPrRequestData((prevState) => ({
      ...prevState,
      expenditure_type: expenditureTypeValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      expenditure_type: "",
    }));
  };
  const fetchMattCodeData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_all_mat_codes`, {
        headers: {
          Authorization: authKey,
        },
      });

      // Transform data to match Select component's expected format
      const transformedData = response.data.mat_code_details.map((matCode) => ({
        value: matCode.mat_code_id,
        label: matCode.mat_code,
        description: matCode.description,
      }));

      setMatCodes(transformedData);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchMattCodeData();
  }, []);

  const unitOptions = [
    { value: "centimeter", label: "Centimeter (cm)" },
    { value: "visit", label: "Visit" },
    { value: "day", label: "Day" },
    { value: "gram", label: "Gram (g)" },
    { value: "inch", label: "Inch (in)" },
    { value: "kilometer", label: "Kilometer (km)" },
    { value: "kg", label: "Kilogram (kg)" },
    { value: "liters", label: "Liters (L)" },
    { value: "m", label: "Meter (m)" },
    { value: "milligram", label: "Milligram (mg)" },
    { value: "millimeter", label: "Millimeter (mm)" },
    { value: "month", label: "Month" },
    { value: "pcs", label: "Pieces (pcs)" },
    { value: "year", label: "Year" },
  ];

  const prTypeOptions = [
    { value: "AMC", label: "AMC" },
    { value: "Spare", label: "Spare" },
    { value: "Service", label: "Service" },
    { value: "Work Order", label: "Work Order" },
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPrRequestData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };
  // const handleInputChanges = (index, field, value) => {
  //   const newRows = [...rows];
  //   newRows[index][field] = value;
  //   setRows(newRows);
  // };
  const handleInputChanges = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;

    // If the field is matCodes, update the description as well
    if (field === "matCodes") {
      const selectedMatCode = matCodes.find((option) => option.value === value);

      // Update the description for the selected matCode
      newRows[index].description = selectedMatCode
        ? selectedMatCode.description
        : "";
    }

    // Set the updated rows
    setRows(newRows);

    // Update showDescription based on any row having a description
    const hasDescriptions = newRows.some((row) => row.description);
    setShowDescription(hasDescriptions);
  };

  const handleUploadButtonClick = () => {
    // Ensure the ref is not null before triggering the click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };
  // useClickOutside([containerRef, buttonRef, fileInputRef], () => {
  //   // setIsSortPopupOpen(false);
  //   setIsPopupOpen(false);
  // });

  const handleClosePopup = () => {
    setIsPopupOpen(false)
  };

  // useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));
  useClickOutside([containerRef, buttonRef], handleClosePopup, 200); // 200ms delay
  useClickOutside([popupRef, buttonRef], handleClosePopup, 300);




  const handleCancelClick = () => {
    navigate("/pr-request");
  };

  const validateForm = () => {
    const errors = {};

    if (!prRequestData.department_name) {
      errors.department_name = "Department is required.";
    }

    if (!prRequestData.pr_type) {
      errors.pr_type = "PR Type is required.";
    }

    if (!prRequestData.expenditure_type) {
      errors.expenditure_type = "Expenditure Type is required.";
    }

    if (!prRequestData.preferred_vendor) {
      errors.preferred_vendor = "Preferred Vendor is required.";
    }

    if (
      prRequestData.preferred_vendor === "OEM" &&
      !prRequestData.vendor_name
    ) {
      errors.vendor_name = "Vendor Name is required for OEM.";
    }

    if (!prRequestData.expected_delivery_date) {
      errors.expected_delivery_date = "Expected Delivery Date is required.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSaveClick = async () => {
    setIsLoading(true); // Optional: show loading state

    // Validate the form before proceeding
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    const formData = new FormData();

    // Append the necessary fields
    formData.append("department_id", prRequestData.department_id || "");
    formData.append("department_name", prRequestData.department_name || "");
    formData.append("pr_type", prRequestData.pr_type || "");
    formData.append("project_id", prRequestData.project_id || "");
    formData.append("preferred_vendor", prRequestData.preferred_vendor || "");
    formData.append("expenditure_type", prRequestData.expenditure_type || "");
    if (prRequestData.vendor_name) {
      formData.append("vendor_name", prRequestData.vendor_name);
    }
    if (prRequestData.vendor_id) {
      formData.append("vendor_id", prRequestData.vendor_id);
    }

    const formattedDate = prRequestData.expected_delivery_date
      ? new Date(prRequestData.expected_delivery_date)
          .toISOString()
          .split("T")[0]
      : "";
    formData.append("expected_delivery_date", formattedDate);
    formData.append("description", prRequestData.description || "");

    // Append material codes, quantities, etc.
    if (Array.isArray(rows) && rows.length > 0) {
      rows.forEach((row) => {
        if (row.matCodes) formData.append("mat_code_ids[]", row.matCodes);
        if (row.quantity) formData.append("quantities[]", row.quantity);
        if (row.unit) formData.append("units[]", row.unit);
        if (row.est_unit_rate)
          formData.append("est_unit_rates[]", row.est_unit_rate);
      });
    }

    // Append attachments
    if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
      attachmentFile.forEach((file) => {
        formData.append("attachments", file);
      });
    }

    // Append file types if applicable
    if (Array.isArray(prRequestData.filetype)) {
      prRequestData.filetype.forEach((type) => {
        formData.append("filetype", type);
      });
    }

    console.log("FormData to be sent:", formData);

    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.post(
        `${API_URL}/api/add_pr_request`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response message:", response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("PR Request added successfully");
        setTimeout(() => {
          navigate("/pr-request");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving PR request:", error.message);
      setSeverity("error");
      // showSnackbar("Missing item details");
      if (rows.length === 0) {
        showSnackbar("Please add at least one item to submit the PR request.");
      } else if (!prRequestData.department_id || !prRequestData.description) {
        showSnackbar(
          "Please add items in the Request Item Table before submitting."
        );
      } else {
        showSnackbar("Error saving PR request. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setPrRequestData((prevUserData) => ({
      ...prevUserData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      department_name: "",
    }));
  };

  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setPrRequestData({
      ...prRequestData,
      pr_type: selectedOption.value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      pr_type: "",
    }));
  };

  const fetchVendors = async () => {
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/get_vendors_procurement`,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      const options = response.data.Vendors.map((vendor) => ({
        label: vendor.vendor_name,
        value: vendor.id,
      }));

      setVendors(options);
    } catch (error) {
      console.error("Error fetching vendor data:", error.message);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };
  const handleFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedFiles([...selectedFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };
  const handleIconClick = () => {
    setIsPopupOpen((prev) => !prev); // Toggle popup on button click
  };
  const handleDeliveryDateChange = (date) => {
    setSelectedDeliveryDate(date);
    setPrRequestData((prevState) => ({
      ...prevState,
      expected_delivery_date: date,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      expected_delivery_date: "",
    }));
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };

  const handleGenerateRequest = async () => {
    setIsLoading(true); // Optional: show loading state

    // Validate the form before proceeding
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    const formData = new FormData();

    // Append the necessary fields
    formData.append("department_id", prRequestData.department_id || "");
    formData.append("department_name", prRequestData.department_name || "");
    formData.append("pr_type", prRequestData.pr_type || "");
    formData.append("project_id", prRequestData.project_id || "");
    formData.append("vendor_name", prRequestData.vendor_name || "");
    formData.append("vendor_id", prRequestData.vendor_id || "");
    formData.append("preferred_vendor", prRequestData.preferred_vendor || "");
    formData.append("expenditure_type", prRequestData.expenditure_type || "");

    const formattedDate = prRequestData.expected_delivery_date
      ? new Date(prRequestData.expected_delivery_date)
          .toISOString()
          .split("T")[0]
      : "";
    formData.append("expected_delivery_date", formattedDate);
    formData.append("description", prRequestData.description || "");

    // Append material codes, quantities, etc.
    if (Array.isArray(rows) && rows.length > 0) {
      rows.forEach((row) => {
        if (row.matCodes) formData.append("mat_code_ids[]", row.matCodes);
        if (row.quantity) formData.append("quantities[]", row.quantity);
        if (row.unit) formData.append("units[]", row.unit);
        if (row.est_unit_rate)
          formData.append("est_unit_rates[]", row.est_unit_rate);
      });
    }

    // Append attachments
    if (Array.isArray(attachmentFile) && attachmentFile.length > 0) {
      attachmentFile.forEach((file) => {
        formData.append("attachments", file);
      });
    }

    // Append file types if applicable
    if (Array.isArray(prRequestData.filetype)) {
      prRequestData.filetype.forEach((type) => {
        formData.append("filetype", type);
      });
    }

    console.log("FormData to be sent:", formData);

    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.post(
        `${API_URL}/api/add_direct_pr_request`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Response message:", response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("PR Request added successfully");
        setTimeout(() => {
          navigate("/pr-request");
        }, 1000);
      }
    } catch (error) {
      console.error("Error saving PR request:", error.message);
      setSeverity("error");
      // showSnackbar("Missing item details");

      if (rows.length === 0) {
        showSnackbar("Please add at least one item to submit the PR request.");
      } else if (!prRequestData.department_id || !prRequestData.description) {
        showSnackbar(
          "Please add items in the Request Item Table before submitting."
        );
      } else {
        showSnackbar("Error saving PR request. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content" ref={containerRef}>
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New PR Request
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Department<span className="text-danger">*</span>
                  </label>
                  <Select
                    id="department_name"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    // components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color for control
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                        border: `1px solid ${
                          theme === "dark" ? "#444" : "#ccc"
                        }`, // Border for the menu
                        borderRadius: "4px", // Optional: add border radius for rounded edges
                        zIndex: 999, // Ensures the menu appears above other elements
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.department_name && (
                    <div className="error-message">
                      {errors.department_name}
                    </div>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="pr_type">
                    PR Type<span className="text-danger">*</span>
                  </label>
                  <Select
                    name="pr_type"
                    value={prTypeOptions.find(
                      (option) => option.value === prRequestData.pr_type
                    )}
                    onChange={handleSelectChange}
                    options={prTypeOptions}
                    placeholder="Select PR Type"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color for control
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                        border: `1px solid ${
                          theme === "dark" ? "#444" : "#ccc"
                        }`, // Border for the menu
                        borderRadius: "4px", // Optional: add border radius for rounded edges
                        zIndex: 999, // Ensures the menu appears above other elements
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.pr_type && (
                    <div className="error-message">{errors.pr_type}</div>
                  )}
                </div>

                <div className="form-group-select">
                  <label htmlFor="expenditure_type">
                    Expenditure Type<span className="text-danger">*</span>
                  </label>

                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="revenue" // Added id for association
                      name="expenditure_type"
                      value="revenue"
                      checked={prRequestData.expenditure_type === "revenue"}
                      onChange={handleExpenditureTypeChange}
                    />
                    <label htmlFor="revenue">Revenue</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="capital" // Added id for association
                      name="expenditure_type"
                      value="capital"
                      checked={prRequestData.expenditure_type === "capital"}
                      onChange={handleExpenditureTypeChange}
                    />
                    <label htmlFor="capital">Capital</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="others" // Added id for association
                      name="expenditure_type"
                      value="others"
                      checked={prRequestData.expenditure_type === "others"}
                      onChange={handleExpenditureTypeChange}
                    />
                    <label htmlFor="others">Others</label>
                  </div>

                  {errors.expenditure_type && (
                    <div className="error-message-radio">
                      {errors.expenditure_type}
                    </div>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="preferred_vendor">
                    Preferred Vendor<span className="text-danger">*</span>
                  </label>

                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="oem" 
                      name="preferred_vendor"
                      value="OEM"
                      checked={prRequestData.preferred_vendor === "OEM"}
                      onChange={handleVendorTypeChange}
                    />
                    <label htmlFor="oem">OEM</label>
                  </div>
                  <div className="checkbox-input">
                    <input
                      type="radio"
                      id="openMarket"
                      name="preferred_vendor"
                      value="Open Market"
                      checked={prRequestData.preferred_vendor === "Open Market"}
                      onChange={handleVendorTypeChange}
                    />
                    <label htmlFor="openMarket">OpenMarket</label>
                  </div>
                  {errors.preferred_vendor && (
                    <div className="error-message-radio2">
                      {errors.preferred_vendor}
                    </div>
                  )}
                </div>
                {vendorType === "OEM" && (
                  <div className="form-group-select">
                    <label htmlFor="vendor_name">
                      Preferred Vendor Name
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      id="vendor_name"
                      options={vendors}
                      value={selectedVendor}
                      onChange={handleVendorChange}
                      placeholder="Select vendor name"
                      isSearchable
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: theme === "dark" ? "black" : "white", 
                          color: theme === "dark" ? "#fff" : "#333", // Text color
                          borderColor: theme === "dark" ? "#444" : "#ccc", 
                          cursor: "pointer",
                          boxShadow: "none",
                          "&:hover": {
                            borderColor: theme === "dark" ? "#666" : "#888", 
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          backgroundColor: theme === "dark" ? "#000" : "#fff",
                          border: `1px solid ${
                            theme === "dark" ? "#444" : "#ccc"
                          }`, // Border for the menu
                          borderRadius: "4px",
                          zIndex: 999,
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused
                            ? "#666" 
                            : theme === "dark"
                            ? "#000"
                            : "#fff", 
                          color: state.isFocused
                            ? "#fff"
                            : theme === "dark"
                            ? "#fff"
                            : "#333", // Text color
                          cursor: "pointer",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: theme === "dark" ? "#fff" : "#333", 
                        }),
                      }}
                    />
                    {errors.vendor_name && (
                      <div className="error-message">{errors.vendor_name}</div>
                    )}
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="expected_delivery_date">
                    Expected Delivery Date{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedDeliveryDate}
                      onChange={handleDeliveryDateChange}
                      name="expected_delivery_date"
                      id="expected_delivery_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select delivery date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors.expected_delivery_date && (
                    <div className="error-message-date">
                      {errors.expected_delivery_date}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <div className="input-group">
                    <textarea
                      type="text"
                      name="description"
                      value={prRequestData.description}
                      onChange={handleInputChange}
                      placeholder="Enter description"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="attachement">
                    Technical Specification Attachment{" "}
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={handleUploadButtonClick}
                    ref={buttonRef}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="attachments"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                      ref={buttonRef}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-amc4" ref={popupRef}>
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Request Item Table
                </div>
                <div className="actions-purchase-heading-content-right-side"></div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-tabless">
                  <>
                    <table className="action-table action-multilevel-data ">
                      <thead className="action-purchase-heads">
                        <tr>
                          <th className="th2">
                            MAT CODE <span className="text-danger">*</span>
                          </th>
                          {showDescription && (
                            <th className="th2">
                              MAT CODE DESCRIPTION{" "}
                              <span className="text-danger">*</span>
                            </th>
                          )}
                          <th className="th3">
                            UNIT <span className="text-danger">*</span>
                          </th>
                          <th className="th4">
                            QUANTITY <span className="text-danger">*</span>
                          </th>
                          <th className="th5">
                            ESTIMATED UNIT RATE{" "}
                            <span className="text-danger">*</span>
                          </th>
                          <th className="th6">ACTION</th>
                        </tr>
                      </thead>

                      <tbody className="action-purchase-scroll-bodys">
                        {rows.map((row, index) => (
                          <tr key={index}>
                            <td className="th2">
                              <Select
                                options={matCodes}
                                value={matCodes.find(
                                  (option) => option.value === row.matCodes
                                )}
                                onChange={(selectedOption) =>
                                  handleInputChanges(
                                    index,
                                    "matCodes",
                                    selectedOption ? selectedOption.value : ""
                                  )
                                }
                                placeholder="Select Mat Code"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor:
                                      theme === "dark" ? "black" : "white", // Dropdown background color
                                    color: theme === "dark" ? "#fff" : "#333", // Text color
                                    borderColor:
                                      theme === "dark" ? "#444" : "#ccc", // Border color
                                    cursor: "pointer",
                                    boxShadow: "none",
                                    "&:hover": {
                                      borderColor:
                                        theme === "dark" ? "#666" : "#888", // Border color on hover
                                    },
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    backgroundColor:
                                      theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                                    border: `1px solid ${
                                      theme === "dark" ? "#444" : "#ccc"
                                    }`, // Border for the menu
                                    borderRadius: "4px", // Optional: add border radius for rounded edges
                                    zIndex: 1000, // Ensures the menu appears above other elements
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused
                                      ? "#666" // Gray when hovered
                                      : theme === "dark"
                                      ? "#000"
                                      : "#fff", // Black background for options in dark mode
                                    color: state.isFocused
                                      ? "#fff"
                                      : theme === "dark"
                                      ? "#fff"
                                      : "#333", // Text color
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                                  }),
                                }}
                              />
                            </td>
                            {showDescription && (
                              <td className="th4">{row.description || "NA"}</td>
                            )}
                            <td className="th2">
                              <Select
                                options={unitOptions}
                                value={unitOptions.find(
                                  (option) => option.value === row.unit // Changed from prRequestData.unit to row.unit
                                )}
                                onChange={(selectedOption) =>
                                  handleInputChanges(
                                    index,
                                    "unit",
                                    selectedOption ? selectedOption.value : ""
                                  )
                                }
                                placeholder="Select Unit"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor:
                                      theme === "dark" ? "black" : "white", // Dropdown background color
                                    color: theme === "dark" ? "#fff" : "#333", // Text color
                                    borderColor:
                                      theme === "dark" ? "#444" : "#ccc", // Border color
                                    cursor: "pointer",
                                    boxShadow: "none",
                                    "&:hover": {
                                      borderColor:
                                        theme === "dark" ? "#666" : "#888", // Border color on hover
                                    },
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    backgroundColor:
                                      theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                                    border: `1px solid ${
                                      theme === "dark" ? "#444" : "#ccc"
                                    }`, // Border for the menu
                                    borderRadius: "4px", // Optional: add border radius for rounded edges
                                    zIndex: 1000, // Ensures the menu appears above other elements
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused
                                      ? "#666" // Gray when hovered
                                      : theme === "dark"
                                      ? "#000"
                                      : "#fff", // Black background for options in dark mode
                                    color: state.isFocused
                                      ? "#fff"
                                      : theme === "dark"
                                      ? "#fff"
                                      : "#333", // Text color
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                                  }),
                                }}
                              />
                            </td>
                            <td className="th4">
                              <input
                                type="number"
                                value={row.quantity}
                                onChange={(e) =>
                                  handleInputChanges(
                                    index,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter quantity"
                              />
                            </td>
                            <td className="th5">
                              <input
                                type="number"
                                value={row.est_unit_rate}
                                onChange={(e) =>
                                  handleInputChanges(
                                    index,
                                    "est_unit_rate",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter estimated unit rate"
                              />
                            </td>
                            <td className="th6">
                              <button
                                className="delete-row"
                                onClick={() => {
                                  const newRows = rows.filter(
                                    (_, i) => i !== index
                                  );
                                  setRows(newRows);
                                }}
                              >
                                Delete
                              </button>
                            </td>
                            {/* <div className="item-action">

                              <MdOutlineCancel
                               onClick={() => {
                                const newRows = rows.filter(
                                  (_, i) => i !== index
                                );
                                setRows(newRows);
                              }}
                                className="item-action-icon cancel-icon"
                              />

                           
                            </div> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="row">
                      <div className="action-caluculation">
                        <div className="new-rows">
                          <button onClick={handleAddRow} className="add-rows">
                            <FiPlus className="add-row-icon" /> Add New Row
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save as Draft"}
              </button>
              <button
                className="request-button"
                onClick={() => handleGenerateRequest()}
              >
                Send Request
              </button>

              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={15000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(50px)", marginLeft: "25px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default PRRequestForm;
