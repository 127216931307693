import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Form1({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [issuedTo, setIssuedTo] = useState(null);
  const [areaUnit, setAreaUnit] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [departmentData, setDepartmentData] = useState({
    DepartmentName: "",
  });
  const options = [
    { value: "department", label: "Department" },
    { value: "section", label: "Section" },
    { value: "contractor", label: "Contractor" },
  ];
  const issuedToOptions = [
    { value: "department", label: "Department" },
    { value: "section", label: "Section" },
    { value: "contractor", label: "Contractor" },
  ];

  const areaUnitOptions = [
    { value: "area", label: "Area" },
    { value: "unit", label: "Unit" },
    { value: "equipment_no", label: "Equipment No" },
  ];

  const handleIssuedToChange = (selectedOption) => {
    setIssuedTo(selectedOption);
    console.log("Issued to:", selectedOption);
  };

  const handleAreaUnitChange = (selectedOption) => {
    setAreaUnit(selectedOption);
    console.log("Area Unit / Equipment No:", selectedOption);
  };

  const handleNextClick = () => {
    navigate("/cold-work-permit?tab=Form2");
  };

  return (
    <>
      {/* <div
                className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
                    }`}
            > */}
      <div className="data-form-content">
        <div className="data-form-header">
          <div className="data-form-header-content">
            <div className="data-form-heading-content-left-side">
              New Cold Work Permit
            </div>
            <div className="data-form-heading-content-right-side"></div>
          </div>
        </div>
        <div className="header-separator"></div>
        <div className="data-form-body">
          <form className="form-detail">
            <div className="new-data-form">
            <div className="cold-error">
            <div>Under Development</div>
            </div>
              <div className="form-group">
                <label htmlFor="DepartmentName" style={{ fontWeight: "bold" }}>
                  Work clearance from
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="DepartmentName">Start Time & Date</label>
                <div className="input-group">
                  <DatePicker
                    id="start-date"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    dateFormat="Pp" // Date and time format
                    placeholderText="Select start date and time"
                    className="datepicker"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="DepartmentName">End Time & Date</label>
                <div className="input-group">
                  <DatePicker
                    id="end-date"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    showTimeSelect
                    dateFormat="Pp"
                    placeholderText="Select end date and time"
                    className="datepicker"
                    minDate={startDate} // Ensures the end date is after the start date
                  />
                </div>
              </div>
              <div className="form-group-select">
                <label htmlFor="" style={{ fontWeight: "bold" }}>
                  Issued to:
                </label>
              </div>
              <div className="form-group-select">
                <label htmlFor="">Department</label>
                <Select
                  id="issued-to-select"
                  // options={selectDepartment}
                  onChange={handleIssuedToChange}
                  placeholder="Select Department"
                  isClearable
                />
              </div>
              <div className="form-group-select">
                <label htmlFor="">Section</label>
                <Select
                  id="issued-to-select"
                  // options={selectDepartment}
                  onChange={handleIssuedToChange}
                  placeholder="Select Section"
                  isClearable
                />
              </div>
              <div className="form-group-select">
                <label htmlFor="">Contractor</label>
                <Select
                  id="issued-to-select"
                  // options={selectDepartment}
                  onChange={handleIssuedToChange}
                  placeholder="Select Contractor"
                  isClearable
                />
              </div>
              <div className="form-group-select">
                <label
                  htmlFor="area-unit-select"
                  style={{ fontWeight: "bold" }}
                >
                  Exact Location of work
                </label>
              </div>
              <div className="form-group-select">
                <label htmlFor="area-unit-select">Area</label>
                <Select
                  id="area-unit-select"
                  options={areaUnitOptions}
                  onChange={handleAreaUnitChange}
                  placeholder="Select Area "
                  isClearable
                />
              </div>
              <div className="form-group-select">
                <label htmlFor="area-unit-select">Unit</label>
                <Select
                  id="area-unit-select"
                  options={areaUnitOptions}
                  onChange={handleAreaUnitChange}
                  placeholder="Select Unit "
                  isClearable
                />
              </div>
              <div className="form-group-select">
                <label htmlFor="area-unit-select">Equipment No.</label>
                <Select
                  id="area-unit-select"
                  options={areaUnitOptions}
                  onChange={handleAreaUnitChange}
                  placeholder="Select Equipment No"
                  isClearable
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description of work</label>
                <div className="input-group">
                  <textarea
                    type="message"
                    rows={4}
                    cols={5}
                    className="textarea"
                    s
                    placeholder="Description"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="footer">
          <div className="button-group">
            <button
              className="data-form-save-button"
              type="save"
              // onClick={handleSaveClick}
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
            <button
              className="data-form-cancel-button"
              // onClick={handleCancelClick}
            >
              Cancel
            </button>
            <button className="data-form-next-button" onClick={handleNextClick}>
              Next
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default Form1;
