import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";

function EditDesignation({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();

  const [designationData, setDesignationData] = useState({
    email: "",
    password: "",
    is_default: "0",
  });

  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchDesignationData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_email_configuration/${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        console.log("API response:", response.data);

        const responseData = response.data.configs; // Access the configs array
        if (Array.isArray(responseData) && responseData.length > 0) {
          const firstConfig = responseData[0]; // Assuming you want the first item
          setDesignationData({
            email: firstConfig.email || "",
            password: firstConfig.password || "", // Password is not present in the API response
            is_default: firstConfig.is_default || "",
          });
        } else {
          setDesignationData({
            email: "",
            password: "",
            is_default: "1",
          });
        }
        setIsDataLoading(false);
      } catch (error) {
        console.error("Error fetching designation data:", error.message);
        setError("An error occurred while fetching designation data");
        setIsDataLoading(false);
      }
    };

    fetchDesignationData();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setDesignationData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value, // Handle checkbox state
    }));
  };
  const handleCancelClick = () => {
    // navigate("/designations");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

const handleSaveClick = async () => {
  // Validation checks
  if (!designationData.email.trim()) {
    showSnackbar("Email is required", "error");
    return;
  }

  // Regex for validating email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(designationData.email)) {
    showSnackbar("Please enter a valid email address", "error");
    return;
  }

  if (!designationData.password.trim()) {
    showSnackbar("Password is required", "error");
    return;
  }

  if (designationData.password.length < 6) {
    showSnackbar("Password must be at least 6 characters long", "error");
    return;
  }

  // Proceed with saving if all validations pass
  try {
    setIsLoading(true);
    const authKey = localStorage.getItem("authKey");
    const response = await axios.put(
      `${API_URL}/api/update_email_config/${id}`,
      designationData,
      {
        headers: {
          Authorization: authKey,
        },
      }
    );

    if (response.status === 200) {
      showSnackbar("Data updated successfully");
      setTimeout(() => {
        navigate(`/email-configuration`);
      }, 1000);
    }
  } catch (error) {
    console.error("Error updating designation:", error.message);
    setError("An error occurred while updating designation data");
    setIsLoading(false);
  }
};


  const handleBackProject = () => {
    navigate(`/email-configuration`);
  };

  if (isDataLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Edit Email Configuration
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form action="" onKeyDown={handleKeyDown}>
              <div className="new-data-edit-form">
                <div className="form-group">
                  <label htmlFor="email">
                    Email<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="email"
                      value={designationData.email}
                      onChange={handleInputChange}
                      placeholder="Enter email"
                    />
                  </div>
                </div>
                <div className="form-group msmme">
                  <label htmlFor="is_default">Default Email</label>

                  <div className="checkbox-input">
                    <input
                      type="radio"
                      name="is_default"
                      value={1} // Yes
                      checked={designationData.is_default === 1} // Compare with integer 1
                      onChange={(event) =>
                        setDesignationData((prevState) => ({
                          ...prevState,
                          is_default: parseInt(event.target.value, 10), // Convert to integer
                        }))
                      }
                    />
                    <label>Yes</label>
                  </div>

                  <div className="checkbox-input">
                    <input
                      type="radio"
                      name="is_default"
                      value={0} // No
                      checked={designationData.is_default === 0} // Compare with integer 0
                      onChange={(event) =>
                        setDesignationData((prevState) => ({
                          ...prevState,
                          is_default: parseInt(event.target.value, 10), // Convert to integer
                        }))
                      }
                    />
                    <label>No</label>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="password">
                    App Password<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="password"
                      value={designationData.password}
                      onChange={handleInputChange}
                      placeholder="Enter password"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="button"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                type="button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditDesignation;
