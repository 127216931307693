import React, { useState, useEffect, useRef } from "react";
import "./User.css";
import { Link } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import "../Responsive/TableResponsive.css";

import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import EquipmentView from "../Views/EquipmentView";
import Pagination from "../Component/Pagination";
import useClickOutside from '../useClickOutside';
import { MdAttachFile } from "react-icons/md";

function NewEquipment({ isSidebarExpanded, equipmentID }) {
  const [equipments, setEquipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [severity, setSeverity] = useState("success");
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const popupRef = useRef(null)
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [popupUserID, setPopupUserID] = useState(null);
  const [sortColumn, setSortColumn] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupUserOpen, setIsPopupUserOpen] = useState(false);

  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedschedules = [...equipments].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setEquipments(sortedschedules);
  };

  const handleCloseUserView = () => {
    setIsMainContainerVisible(false); 
  };
  
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);

  const containerRef = useRef(null)
  const buttonRef = useRef(null)
  const fetchTimeout = useRef(null);




  // const handleExport = async (format, filter, startDate, endDate) => {
  //   try {
  //     const authKey = localStorage.getItem("authKey");
  //     const params = new URLSearchParams({
  //       format,
  //       filter_option: filter.value,
  //       ...(filter.value === "custom_date" && { start_date: formatDate(startDate), end_date: formatDate(endDate) })
  //     }).toString();

  //     const response = await axios.get(`${API_URL}/api/export_equipments?${params}`, {
  //       headers: {
  //         Authorization: authKey,
  //       },
  //       responseType: 'blob', // To handle binary file data
  //     });

  //     if (response.status === 200) {
  //       // Handle file download
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `equipments.${format}`);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //     } else {
  //       console.error("Error exporting schedule:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error exporting schedule:", error.message);
  //   }
  // };


 

   const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage); 
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_equipment`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setEquipments(response.data.Items);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  
  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage); 
      }
    }, 600); 

    return () => clearTimeout(fetchTimeout.current); 
  }, [searchInput, page, itemsPerPage]);
  

  // useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const handleClosePopup = () => {
    setIsSortPopup(false);
    setIsPopupOpen(false);
    setIsPopupUserOpen(false)
    // setIsPermitPopupOpen(false)
  };

  // useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));
  useClickOutside([containerRef, buttonRef], handleClosePopup, 200); // 200ms delay
  useClickOutside([popupRef, buttonRef], handleClosePopup, 300);

  
  


const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
  };

const handleButtonClick = (index) => {
  setSelectedRowIndex(index === selectedRowIndex ? null : index);
};

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };


  const handleGenerateScheduler = async (equipmentID) => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.post(
        `${API_URL}/api/generate_scheduler/${equipmentID}`,
        {},
        {
          headers: {
            Authorization: authKey,
          },
        }
      );

      if (response.data && response.data.scheduler_list) {
        showSnackbar("Scheduler generated successfully", "success");

      } else {
        // Handle unexpected response
        setSeverity("error");
        showSnackbar("Failed to generate scheduler", "error");
      }
    } catch (error) {
      // Display backend error message in Snackbar
      if (error.response && error.response.data && error.response.data.message) {
        const message = error.response.data.message.includes("Frequency Of Maintenance value is not found or empty") ?
          "Frequency Of Maintenance value is not found or empty for equipment" :
          error.response.data.message;
        setSeverity("error");
        showSnackbar(message, "error");
      } else {
        setSeverity("error");
        showSnackbar("An unexpected error occurred", "error");
      }
      console.error("An error occurred while generating scheduler:", error);
    }
  };


  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_equipment`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      setEquipments(response.data.Items);
      setTotalItems(response.data.TotalItems);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    fetchData(page, itemsPerPage);
    setSearchInput("");
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? equipments.map((equipment) => equipment.ID) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (equipmentID) => {
    const updatedSelectedRows = selectedRows.includes(equipmentID)
      ? selectedRows.filter((id) => id !== equipmentID)
      : [...selectedRows, equipmentID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === equipments.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_equipment`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ equipment_ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedquipments = equipments.filter(
          (equipment) => !selectedRows.includes(equipment.ID)
        );
        setEquipments(updatedquipments);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete users");
      }
    } catch (error) {
      console.error("An error occurred while deleting users:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
    return formatter.format(date);
  };

  const handleArchiveUser = () => {
    navigate("/archive-equipment");
  };



  const handleSortOrderToggle = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const togglePopup = (equipmentID) => {
    setPopupUserID(equipmentID);
    setIsMainContainerVisible(true); 
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleIconClick = (index) => {
    // setIsPopupOpen(inquiryID);
    setIsPopupOpen(index === isPopupOpen ? null : index);
    setSelectedRowIndex(false);
    setIsPopupUserOpen(false)
  };
  const handleUserCloseAttachment = () => {
    setIsPopupUserOpen(false);
  };
  const handleUserIconClick = (index) => {
    // setIsPopupOpen(inquiryID);
    setIsPopupUserOpen(index === isPopupOpen ? null : index);
    setSelectedRowIndex(false);
    setIsPopupOpen(false)

  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                    <button
                        className="delete"
                        onClick={handleDeletePopup}
                      >
                        Move to archive
                      </button>
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleConfirmDelete}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Equipments
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search equipments..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>
                   
                    <Link to="/new-equipment-form" className="data-list-new">
                      <Tooltip title="New Equipment" arrow>
                        <button className="new-button">
                          <HiOutlinePlus className="new-plus-icon" /> New
                        </button>
                      </Tooltip>
                    </Link>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button className="sort-button" onClick={handleSortpopup} ref={buttonRef}>
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={popupRef}>
                        <div className="sort-popup-content">
                          <div
                            className="sort-by"
                            onClick={handleSortOrderToggle}
                          >
                            SORT BY{" "}
                            {sortOrder[sortColumn] === "asc"
                              ? "(Ascending)"
                              : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("Category")}>
                              Category{" "}
                              {sortOrder.Category === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("TagNo")}>
                              Tag No{" "}
                              {sortOrder.TagNo === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("Make")}>
                            Make{" "}
                              {sortOrder.Make === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("Model")}>
                            Model{" "}
                              {sortOrder.Model === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("InstallationYear")}>
                            Installation Year{" "}
                              {sortOrder.InstallationYear === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("LastMaintenanceDate")}>
                              Last Maintenance Date{" "}
                              {sortOrder.LastMaintenanceDate === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("Location")}>
                            Location{" "}
                              {sortOrder.Location === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("Description")}>
                            Description{" "}
                              {sortOrder.Description === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                          </div>
                          <button
                            className="delete-item"
                            onClick={handleArchiveUser}
                          >
                            Archived Equipment
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : equipments.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th  className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th  className="center-alignment">SR. NO.</th>
                          <th>CATEGORY</th>
                          <th>TAG NO</th>
                          <th>MAKE </th>
                          <th  className="center-alignment">MODEL </th>
                          <th  className="center-alignment">YEAR OF INSTALLATION </th>
                          {/* <th>WARRANTY EXPIRY</th> */}
                          {/* <th>FREQUENCY OF MAINTENANCE </th> */}
                          <th  className="center-alignment">LAST MAINTENANCE DATE </th>
                          <th>LOCATION</th>
                          <th>DESCRIPTION </th>
                          <th  className="center-alignment">PR COPY ATTACHMENT </th>
                          <th  className="center-alignment">USER MANUAL ATTACHMENT </th>
                          <th  className="center-alignment">ACTION </th>

                          <th  className="center-alignment"></th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {equipments.map((equipment, index) => (
                          <tr key={equipment.ID} className="hoverable-row">
                            <td  className="center-alignment">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(equipment.ID)}
                                onChange={() => handleSelectRow(equipment.ID)}
                              />
                            </td>

                            <td  className="center-alignment">{equipment.serial_no}</td>
                            {/* <td>{equipment.Category || "NA"}</td> */}
                            <td className="view-data" onClick={() => togglePopup(equipment.ID)}>{equipment.Category ||"NA"}</td>
                            <td  className="center-alignment">{equipment.TagNo || "NA"}</td>
                            <td>{equipment.Make || "NA"}</td>
                            <td  className="center-alignment">{equipment.Model || "NA"}</td>
                            <td  className="center-alignment">{equipment.InstallationYear || "NA"}</td>
                            {/* <td>{equipment.WarrantyExp || "NA"}</td> */}
                            {/* <td>{equipment.FrequencyOfMaintenance || "NA"}</td>                        */}
                            {/* <td>
                              {new Date(
                                equipment.LastMaintenanceDate
                              ).toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }) || "NA"}
                            </td> */}
                            <td  className="center-alignment">{formatDate(equipment.LastMaintenanceDate)}</td>
                            <td>{equipment.Location || "NA"}</td>
                            <td>{equipment.Description || "NA"}</td>
                            <td  className="center-alignment">
      <span
        className="inquiry-file-btns"
        onClick={() => handleIconClick(index)}
        ref={buttonRef}
      >
        <MdAttachFile className="inquiry-file-icon" />
      </span>
      {isPopupOpen === index && (
        <div className="inquiry-attach-popup Pr-popup" ref={popupRef}>
          <div className="attach-popup-contant">
            <div className="attachment-header">
              <div className="attachment-header-content">PR Copy Attachments</div>
              <button
                className="fiile-close-btn"
                onClick={handleCloseAttachment}
              >
                <IoCloseOutline className="file-close-icon" />
              </button>
            </div>
            <div className="attach-popup-contant-body" key={index}>
              <div className="inquiry-attach-file-containerssss">
                <div className="attach-file-showsss">
                  <div className="text-attachmentss">
                    {equipment.files &&
                    equipment.files.filter(file => file.file_type === 'pr_copy').length > 0 ? (
                      <div className="attach-popup-content-body">
                        {equipment.files
                          .filter(file => file.file_type === 'pr_copy')
                          .map((attachment, attachmentIndex) => (
                            <div className="inquiry-attach-file-container" key={attachmentIndex}>
                              <div className="inquiry-attacth-file-model">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {attachment.filename.length > 20
                                      ? `${attachment.filename.substring(0, 15)}...`
                                      : attachment.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size: {attachment.file_size || "NA"} KB
                                  </div>
                                </div>
                                <div className="file-footer">
                                  <a
                                    href={`${API_URL}/api/download_attachment/${attachment.file_id}/${attachment.file_type}`}
                                    download
                                    className="file-download-attachment file-txt"
                                  >
                                    Download
                                  </a>
                                  <a
                                    href={`${API_URL}/api/view_attachment/${attachment.file_id}/${attachment.file_type}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="file-download-attachment file-txt"
                                  >
                                    View
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="no-attachments">No PR Copy attachments found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </td>

    {/* User Manual Attachments */}
    <td  className="center-alignment">
      <span
        className="inquiry-file-btns"
        onClick={() => handleUserIconClick(index)}
        ref={buttonRef}
      >
        <MdAttachFile className="inquiry-file-icon" />
      </span>
      {isPopupUserOpen === index && (
        <div className="inquiry-attach-popup User-popup" ref={popupRef}>
          <div className="attach-popup-contant">
            <div className="attachment-header">
              <div className="attachment-header-content">User Manual Attachments</div>
              <button
                className="fiile-close-btn"
                onClick={handleUserCloseAttachment}
              >
                <IoCloseOutline className="file-close-icon" />
              </button>
            </div>
            <div className="attach-popup-contant-body" key={index}>
              <div className="inquiry-attach-file-containerssss">
                <div className="attach-file-showsss">
                  <div className="text-attachmentss">
                    {equipment.files &&
                    equipment.files.filter(file => file.file_type === 'user_manual').length > 0 ? (
                      <div className="attach-popup-content-body">
                        {equipment.files
                          .filter(file => file.file_type === 'user_manual')
                          .map((attachment, attachmentIndex) => (
                            <div className="inquiry-attach-file-container" key={attachmentIndex}>
                              <div className="inquiry-attacth-file-model">
                                <div className="attach-file-show">
                                  <div className="text-attachment">
                                    {attachment.filename.length > 20
                                      ? `${attachment.filename.substring(0, 15)}...`
                                      : attachment.filename}
                                  </div>
                                  <div className="text-attachment-file">
                                    File Size: {attachment.file_size || "NA"} KB
                                  </div>
                                </div>
                                <div className="file-footer">
                                  <a
                                    href={`${API_URL}/api/download_attachment/${attachment.file_id}/${attachment.file_type}`}
                                    download
                                    className="file-download-attachment file-txt"
                                  >
                                    Download
                                  </a>
                                  <a
                                    href={`${API_URL}/api/view_attachment/${attachment.file_id}/${attachment.file_type}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="file-download-attachment file-txt"
                                  >
                                    View
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="no-attachments">No User Manual attachments found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </td>
                            <td>
                              <button
                                className="generate-scheduler-button"
                                onClick={() =>
                                  handleGenerateScheduler(equipment.ID)
                                }
                              >
                                Generate Schedule
                              </button>
                            </td>

                            <td className="edit-pop">
                              <button
                                className="edit-data-button"
                                onClick={() => handleButtonClick(index)}
                              >
                                <div className="edit-button">
                                  <MdExpandMore />
                                </div>
                              </button>
                            </td>
                            {selectedRowIndex === index && (
                              <div className="edit-popup">
                                <div className="edit-popup-contant">
                                  <div className="edit-popup-button">
                                    <Link
                                      to={`/edit-new-equipment/${equipment.ID}`}
                                      className="inquiry-new"
                                    >
                                      <button className="edit-button-inquiry">
                                        <MdOutlineModeEditOutline /> Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </tr>
                        ))}
                      </tbody>

                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No Equipments Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
             
            </div>
          </div>
        )}
         {isMainContainerVisible && (
          <div className="main-containers">
            <EquipmentView equipmentID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default NewEquipment;