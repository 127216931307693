import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeRangePicker from "react-time-range-picker";

function Form4({ isSidebarExpanded }) {
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [issuedTo, setIssuedTo] = useState(null);
    const [areaUnit, setAreaUnit] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  // Restrict the time range from 5 AM to 10 PM
  const filterTime = (time) => {
    const hour = time.getHours();
    return hour >= 5 && hour <= 22;
  };


    const options = [
        { value: 'department', label: 'Department' },
        { value: 'section', label: 'Section' },
        { value: 'contractor', label: 'Contractor' },
    ];
    const issuedToOptions = [
        { value: 'department', label: 'Department' },
        { value: 'section', label: 'Section' },
        { value: 'contractor', label: 'Contractor' },
    ];


    const areaUnitOptions = [
        { value: 'area', label: 'Area' },
        { value: 'unit', label: 'Unit' },
        { value: 'equipment_no', label: 'Equipment No' },
    ];

    const handleIssuedToChange = (selectedOption) => {
        setIssuedTo(selectedOption);
        console.log("Issued to:", selectedOption);
    };

    const handleAreaUnitChange = (selectedOption) => {
        setAreaUnit(selectedOption);
        console.log("Area Unit / Equipment No:", selectedOption);
    };

    const handleNextClick = () => {
        navigate("/user1");
    };
    const handleCancelClick = () => {
        navigate("/cold-work-permit?tab=Form3");
    };

    return (
        <>
            {/* <div
                className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
                    }`}
            > */}
            <div className="data-form-content">
                <div className="data-form-header">
                    <div className="data-form-header-content">
                        <div className="data-form-heading-content-left-side">
                            New Clearance renewal
                        </div>
                        <div className="data-form-heading-content-right-side"></div>
                    </div>
                </div>
                <div className="header-separator"></div>
                <div className="data-form-body">
                    <form className="form-detail">
                        <div className="new-data-form">
                        <div className="cold-error">
            <div>Under Development</div>
            </div>
                            <div className="form-group">
                                <label htmlFor="DepartmentName">
                                    Date
                                </label>
                                <div className="input-group">
                                    <DatePicker
                                        id="start-date"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        placeholderText="Select start date and time"
                                        className="datepicker"
                                    />

                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="DepartmentName">
                                    Time
                                </label>
                                <div className="input-group">
                                <DatePicker
          selected={startTime}
          onChange={(date) => setStartTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15} // Set time intervals to 15 minutes
          timeCaption="Time"
          dateFormat="h:mm aa"
          minTime={new Date().setHours(5, 0, 0, 0)} // 5 AM
          maxTime={new Date().setHours(22, 0, 0, 0)} // 10 PM
          filterTime={filterTime}
          placeholderText="Select Time"
        />
        &nbsp;-&nbsp;
        <DatePicker
          selected={endTime}
          onChange={(date) => setEndTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15} // Set time intervals to 15 minutes
          timeCaption="Time"
          dateFormat="h:mm aa"
          minTime={new Date().setHours(5, 0, 0, 0)} // 5 AM
          maxTime={new Date().setHours(22, 0, 0, 0)} // 10 PM
          filterTime={filterTime}
          placeholderText="Select Time"
        />   


                                </div>
                            </div>
                      
                            <div className="form-group">
                                <label htmlFor="">Extended up to ____ Hrs.
                                </label>
                                <div className="input-group">
                    <input
                      type="text"
                      name="DepartmentName"
                    //   value={departmentData.DepartmentName}
                    //   onChange={handleInputChange}
                      placeholder="Enter total hrs"
                    />
                            </div>
                            </div>
                     
                          
                            <div className="form-group">
                                <label htmlFor="description">Additional precautions if any, otherwise mention "NIL"</label>
                                <div className="input-group">
                                    <textarea
                                        type="message"
                                        rows={4}
                                        cols={5}
                                        className="textarea"
                                        s
                                        placeholder="Add Additional precautions"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="footer">
                    <div className="button-group">
                        <button
                            className="data-form-save-button"
                            type="save"
                            // onClick={handleSaveClick}
                            disabled={isLoading}
                        >
                            {isLoading ? "Saving..." : "Save"}
                        </button>
                        <button
                            className="data-form-cancel-button"
                        onClick={handleCancelClick}
                        >
                            Cancel
                        </button>
                        {/* <button
                            className="data-form-save-button"
                            onClick={handleNextClick}
                        >
                            Next
                        </button> */}
                    </div>
                </div>
            </div>
            {/* </div> */}

        </>
    );
}

export default Form4;
