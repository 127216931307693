import React, { useState, useEffect, useRef } from "react";
import "./POConfigurationForm.css";
import "./TechnicalRequestListView.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import API_URL from "../../src/Config";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import '../TableColor.css';

function InventoryHistory({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [inventoryData, setInventoryData] = useState([]);
  const containerRef = useRef(null);
  const fetchData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/inventory_history/${id}`, {
        headers: { Authorization: authKey },
      });

      if (response.data && response.data.inventory_history) {
        const mappedData = response.data.inventory_history.map((item) => ({
          acceptedQty: item.accepted_qty,
          dateTime: item.date_time,
          rejectedQty: item.rejected_qty,
          totalQty: item.total_qty,
          userName: item.user_name,
          outwarded_qty:item.outwarded_qty,
        }));
        setInventoryData(mappedData);
      } else {
        setInventoryData([]);
      }
    } catch (error) {
      console.error("Error fetching inventory data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleBackProject = () => {
    navigate("/product-inventory");
  };

  return (


    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        <div className="data-list-content" ref={containerRef}>

          <div className="data-list-header">
            <div className="data-list-header-content">
              <div className="data-list-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Product Inventory History

              </div>

              <div className="data-list-heading-content-right-side">

              </div>
            </div>
          </div>

          <div className="data-list-body">
            <div className="data-list-scroll-bodys">
              <div className="data-list-table">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : inventoryData.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th>SR. NO.</th>
                        <th className="total">TOTAL QTY</th>
                        <th className="quantity">ACCEPTED QTY</th>
                        <th>OUTWARDED QTY</th>
                        <th className="rejected">REJECTED QTY</th>
                        <th>ACTION DONE BY</th>
                        <th>UPDATED ON</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {inventoryData.map((item, index) => (
                        <tr key={index} className="hoverable-row">
                          <td>{index + 1}</td>
                          <td className="total">{item.totalQty || "0"}</td>
                          <td className="quantity">{item.acceptedQty || "0"}</td>
                          <td>{item.outwarded_qty || "0"}</td> 
                          <td className="rejected">{item.rejectedQty || "0"}</td>
                          <td>{item.userName || "NA"}</td>
                          <td>
                            {item.dateTime
                              ? new Date(item.dateTime).toUTCString().split('GMT')[0].trim() + " IST"
                              : "NA"}
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">
                      No Product Inventory History Found
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
        </div>
    </>
  );
}

export default InventoryHistory;
