import { useEffect, useRef } from "react";

const useClickOutside = (refsArray = [], handler, delay = 0) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Event handler for detecting outside clicks
    const handleClickOutside = (event) => {
      // Clear the previous timeout to avoid unnecessary calls
      clearTimeout(timeoutRef.current);

      // If delay is provided, wait before calling the handler
      if (delay > 0) {
        timeoutRef.current = setTimeout(() => {
          // Check if click is outside of all refs
          const isOutside = refsArray.every((ref) => ref.current && !ref.current.contains(event.target));
          
          // Trigger the handler if clicked outside
          if (isOutside) {
            handler();
          }
        }, delay);
      } else {
        // If no delay is set, check immediately
        const isOutside = refsArray.every((ref) => ref.current && !ref.current.contains(event.target));

        // Trigger the handler if clicked outside
        if (isOutside) {
          handler();
        }
      }
    };

    // Add event listeners for mouse and touch events
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    // Cleanup the event listeners and timeout when the component is unmounted or refs change
    return () => {
      clearTimeout(timeoutRef.current); // Clear timeout on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [refsArray, handler, delay]); // Rerun the effect when refs, handler, or delay change

};

export default useClickOutside;
