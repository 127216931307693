import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import "./ElecticalDepartment.css";
import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
import "../Responsive/TableResponsive.css";
import Pagination from "../Component/Pagination";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import DatePicker from "react-datepicker";
import { Button, TextField } from "@mui/material";
import { format } from "date-fns";
import { Typography, Modal, Paper } from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { debounce } from "lodash";
import useClickOutside from "../useClickOutside";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { ThemeContext } from "../../src/ThemeContext";

function ElectricalDepartment({ isSidebarExpanded }) {
  const location = useLocation(); // Correct way to access location
  const params = new URLSearchParams(location.search);
  // const department_id = params.get("department_id"); 
  const { department_id } = useParams();
console.log("Department ID:", department_id); 
  const [schedules, setSchedules] = useState([]);
  const [departmentName, setDepartmentName] = useState("All");

  const [loading, setLoading] = useState(true);
  const [totalSchedules, setTotalSchedules] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [dueToday, setDueToday] = useState(0); // State for due today
  const [dueTomorrow, setDueTomorrow] = useState(0); // State for due tomorrow
  const navigate = useNavigate();
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  // const [selectedScheduleStatus, setSelectedScheduleStatus] = useState("all");
  const [selectedScheduleStatus, setSelectedScheduleStatus] = useState(
    localStorage.getItem("selectedScheduleStatus") || "all"
  );
  const [selectedFilter, setSelectedFilter] = useState(
    localStorage.getItem("selectedFilter") || "all"
  );

  const [statusCounts, setStatusCounts] = useState({
    Cancelled: 0,
    Completed: 0,
    Hold: 0,
    active: 0,
    overdue: 0,
    "In Progress": 0,
    "not in tenure": 0,
  });
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { theme } = useContext(ThemeContext);

  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedschedules = [...schedules].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setSchedules(sortedschedules);
  };

  const handleOpenModal = (schedule) => {
    setSelectedSchedule(schedule);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedSchedule(null);
    setModalOpen(false);
  };

  const formatDate = (date) => {
    if (!date) return "N/A";

    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return "N/A";

    return parsedDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  const filterOptions = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Tomorrow", value: "tomorrow" },
    { label: "Next 7 Days", value: "next7days" },
    { label: "Current Week", value: "current_week" },
    { label: "Past Week", value: "past_week" },
    { label: "Current Month", value: "current_month" },
    { label: "Custom Date", value: "custom_date" },
  ];

  const statusOptions = [
    { label: "All Status", value: "all" },
    { label: "Active", value: "active" },
    { label: "Completed", value: "Completed" },
    { label: "Hold", value: "hold" },
    { label: "Overdue", value: "overdue" },
    { label: "In Progress", value: "In Progress" },
    { label: "Not in Tenure", value: "not in tenure" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  // const debouncedFetchSchedules = useCallback(
  //   debounce((filter, status, query, page, perPage, startDate, endDate) => {
  //     fetchSchedulesByFilterAndStatus(
  //       filter,
  //       status,
  //       query,
  //       page,
  //       perPage,
  //       startDate,
  //       endDate
  //     );
  //   }, 100),
  //   []
  // );
  const debouncedFetchSchedules = useCallback(
    debounce((filter, status, query, page, perPage, startDate, endDate) => {
      console.log("Fetching schedules with department_id:", department_id); // Debugging log
      fetchSchedulesByFilterAndStatus(
        filter,
        status,
        query,
        page,
        perPage,
        startDate,
        endDate,
        department_id // Pass department_id here
      );
    }, 100),
    [department_id] // Make sure the department_id is a dependency
  );
  
  const handleFilterChange = (event) => {
    const value = event.target.value;
    setSelectedFilter(value);
    localStorage.setItem("selectedFilter", value);

    if (value !== "custom_date" || (selectedStartDate && selectedEndDate)) {
    }
  };

  const handleScheduleStatusChange = (event) => {
    const value = event.target.value;
    setSelectedScheduleStatus(value);
    localStorage.setItem("selectedScheduleStatus", value);
  };

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;
    setSelectedStartDate(start);
    setSelectedEndDate(end);

    const formattedStartDate = start ? format(start, "yyyy-MM-dd") : null;
    const formattedEndDate = end ? format(end, "yyyy-MM-dd") : null;

    localStorage.setItem("selectedStartDate", formattedStartDate);
    localStorage.setItem("selectedEndDate", formattedEndDate);

    if (formattedStartDate && formattedEndDate) {
    }
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };
  // const handleSortPopupClose = () => {
  //   console.log('Clicked outside the sort popup');
  //   setIsSortPopup(false);
  // };

  // Handle search input change
  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    debouncedFetchSchedules(
      selectedFilter,
      selectedScheduleStatus,
      value,
      page,
      itemsPerPage,
      selectedStartDate,
      selectedEndDate
    );
  };

  
  const fetchSchedules = async () => {
    try {
      // Assuming `fetchSchedulesByFilterAndStatus` is in this component or passed as a prop
      await fetchSchedulesByFilterAndStatus('all', 'all', '', 1, 10, null, null, department_id);
    } catch (error) {
      console.error("Error fetching schedules:", error);
    }
  };

  useEffect(() => {
    if (department_id) {
      fetchSchedules(); // Fetch schedules when department_id is available
    }
  }, [department_id]);



  const fetchSchedulesByFilterAndStatus = async (
    filter,
    status,
    query,
    page,
    perPage,
    startDate,
    endDate,
    department_id // Make sure to include department_id in parameters
  ) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/filter_scheduler_with_search`;
  
      const params = {
        status,
        page,
        per_page: perPage,
        query: query,
        department_id, // Include department_id here
      };
  
      if (filter !== "custom_date") {
        params.filter_option = filter;
      } else if (startDate && endDate) {
        params.start_date = format(new Date(startDate), "yyyy-MM-dd");
        params.end_date = format(new Date(endDate), "yyyy-MM-dd");
      }
  
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params,
      });
  
      if (response.status === 200) {
        setSchedules(response.data.schedulers);
        setTotalItems(response.data.total);
        const departmentName =
        response.data.department_name || "All"; // Use department_name from API response
      setDepartmentName(departmentName);
      } else {
        console.error("Error fetching scheduler data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
    } finally {
      setLoading(false);
    }
  };
  
  

  useEffect(() => {
    const storedFilter = localStorage.getItem("selectedFilter") || "all";
    const storedStatus =
      localStorage.getItem("selectedScheduleStatus") || "all";
    const storedStartDate = localStorage.getItem("selectedStartDate") || null;
    const storedEndDate = localStorage.getItem("selectedEndDate") || null;
    const storedPage = localStorage.getItem("currentPage") || 1;
    const storedItemsPerPage = localStorage.getItem("itemsPerPage") || 50;

    setSelectedFilter(storedFilter);
    setSelectedScheduleStatus(storedStatus);
    setSelectedStartDate(storedStartDate ? new Date(storedStartDate) : null);
    setSelectedEndDate(storedEndDate ? new Date(storedEndDate) : null);
    setPage(parseInt(storedPage, 10));
    setItemsPerPage(parseInt(storedItemsPerPage, 10));

    // Trigger API call when all relevant states have settled
    debouncedFetchSchedules(
      storedFilter,
      storedStatus,
      searchInput,
      storedPage,
      storedItemsPerPage,
      storedStartDate,
      storedEndDate
    );
  }, []);

  useEffect(() => {
    if (selectedFilter && selectedScheduleStatus) {
      debouncedFetchSchedules(
        selectedFilter,
        selectedScheduleStatus,
        searchInput,
        page,
        itemsPerPage,
        selectedStartDate,
        selectedEndDate
      );
    }
  }, [
    selectedFilter,
    selectedScheduleStatus,
    searchInput,
    page,
    itemsPerPage,
    selectedStartDate,
    selectedEndDate,
  ]);

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };
  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
    console.log("pop closed");
  };
  const handleCancel = () => {
    setIsDeletePopup(false);
  };
  
  const fetchStatusData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      
  
      const response = await axios.get(`${API_URL}/api/scheduler_summary_by_department/${department_id}`, {
        headers: {
          Authorization: authKey,
        },
      
      });
  
      const data = response.data;
      if (data) {
        setDueToday(data.due_today || 0);
        setDueTomorrow(data.due_tomorrow || 0);
        setStatusCounts(data.status_counts || {});
        setTotalSchedules(data.total_schedules || null);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (department_id) {
      fetchStatusData();
    }
  }, [department_id]);
  

  useClickOutside([containerRef, buttonRef], () => {
    if (isSortPopup) {
      setIsSortPopup(false);
      console.log("Clicked outside, popup closed");
    } else {
      console.log("notworking");
    }
  });

  const fetchData = async (page, perPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_scheduler`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: Number(page),
          per_page: Number(perPage),
        },
      });

      setSchedules(response.data.schedulers);
      setTotalItems(response.data.total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching scheduler data:", error.message);
      setLoading(false);
    }
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? schedules.map((schedule) => schedule.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (scheduleID) => {
    const updatedSelectedRows = selectedRows.includes(scheduleID)
      ? selectedRows.filter((id) => id !== scheduleID)
      : [...selectedRows, scheduleID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === schedules.length);
    setChecked(updatedSelectedRows.length > 0);
  };

  const handleArchiveschedule = () => {
    // navigate('/')
    // alert('/...test')
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  // const handleSortpopup = () => {
  //   setIsSortPopup(!isSortPopup);
  //   console.log("popclosed");

  // };

  const handleRefresh = () => {
    // Reset all relevant states
    setSelectedScheduleStatus("all");
    setSelectedFilter("all");
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSearchInput("");
    setPage(1);
    setItemsPerPage(50);
    // Clear corresponding localStorage items
    localStorage.removeItem("selectedFilter");
    localStorage.removeItem("selectedStatus");
    localStorage.removeItem("selectedStartDate");
    localStorage.removeItem("selectedEndDate");
    localStorage.removeItem("searchInput");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("itemsPerPage");

    fetchData(1, 50);
  };

  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };

  const handleViewClick = async (scheduler_id) => {
    navigate(`/scheduler-view/${scheduler_id}`);
  };
 
  return (
    <>
    <div
      className={`data-list-content-container page-content ${
        isSidebarExpanded ? "expanded" : "collapsed"
      }`}
    >
      {!isMainContainerVisible && (
        <div className="data-list-content" ref={containerRef}>
          {isChecked ? (
            <div className="action-header">
              <div className="action-header-content">
                <div className="action-header-content-leftside">
                  <div className="action-button">
                    <button className="delete" onClick={handleDeletePopup}>
                      Move to archive
                    </button>

                    {isDeletePopup && (
                      <div className="confirm-delete-popup">
                        <div className="confirm-delete-popup-content">
                          <div className="confirm-model">
                            <div className="confirm-model-content">
                              <div className="confirm-model-content-body">
                                <p>Are you sure want to move archive?</p>
                              </div>
                              <div className="confirm-model-content-footer">
                                <button
                                  className="confirm-delete-button"
                                  // onClick={handleArchivedschedule}
                                >
                                  Yes
                                </button>
                                <button
                                  className="confirm-cancel-button"
                                  onClick={handleCancel}
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-header-content-rightside">
                  <button
                    className="action-header-close-button"
                    onClick={handleCloseButtonClick}
                  >
                    <IoCloseOutline className="action-header-close-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="data-list-header">
              <div className="data-list-header-content">
                <div className="data-list-heading-content-left-side">
                All {departmentName} Schedules
                </div>

                <div className="data-list-heading-content-right-side">
                  <div className="project-dropdown-schedules">
                    <FormControl fullWidth>
                      <InputLabel id="filter-select-label">Status</InputLabel>
                      <Select
                        labelId="filter-select-label"
                        id="filter-select"
                        value={selectedScheduleStatus}
                        onChange={handleScheduleStatusChange}
                        label="Status"
                      >
                        {statusOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="project-dropdown-schedules">
                    <FormControl fullWidth>
                      <InputLabel id="filter-select-label">Filter</InputLabel>
                      <Select
                        labelId="filter-select-label"
                        id="filter-select"
                        value={selectedFilter}
                        onChange={handleFilterChange}
                        label="Filter"
                      >
                        {filterOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {selectedFilter === "custom_date" && (
                    <div className="input-group-datepickers">
                      <DatePicker
                        placeholderText="Select Date Range"
                        className="dates"
                        startDate={selectedStartDate}
                        endDate={selectedEndDate}
                        selectsRange={true}
                        isClearable
                        dateFormat="yyyy-MM-dd"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        onChange={handleDateRangeChange}
                        renderInput={(startProps, endProps) => (
                          <>
                            <TextField {...startProps} variant="standard" />
                            <TextField {...endProps} variant="standard" />
                          </>
                        )}
                        startText="Start Date"
                        endText="End Date"
                      />
                    </div>
                  )}
                  <div className="search-bar-container">
                    <input
                      type="text"
                      placeholder="Search ..."
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      className="search-bar"
                    />
                    <IoSearchOutline className="search-bar-icon" />
                  </div>

                  <Tooltip title="Refresh List" arrow>
                    <button
                      className="refresh-button"
                      onClick={handleRefresh}
                    >
                      <TbRefresh className="refresh-icon" />
                    </button>
                  </Tooltip>
                  <button
                    className="sort-button"
                    onClick={handleSortpopup}
                    ref={buttonRef}
                  >
                    <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                  </button>
                  {isSortPopup && (
                    <div
                      className="sort-popup-container"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor:
                            theme === "dark" ? "black" : "white", // Dropdown background color
                          color: theme === "dark" ? "#fff" : "#333", // Text color
                          borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                          cursor: "pointer",
                          boxShadow: "none",
                          "&:hover": {
                            borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                          border: `1px solid ${
                            theme === "dark" ? "#444" : "#ccc"
                          }`, // Border for menu when opened
                          boxShadow:
                            theme === "dark"
                              ? "0 4px 8px rgba(255, 255, 255, 0.1)"
                              : "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow effect
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused
                            ? "#666" // Gray when hovered
                            : theme === "dark"
                            ? "#000"
                            : "#fff", // Black background for options in dark mode
                          color: state.isFocused
                            ? "#fff"
                            : theme === "dark"
                            ? "#fff"
                            : "#333", // Text color
                          cursor: "pointer",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                        }),
                      }}
                      ref={containerRef}
                    >
                      <div className="sort-popup-content">
                        <div className="sort-by">
                          SORT BY{" "}
                          {sortOrder[sortColumn] === "asc"
                            ? "(Ascending)"
                            : "(Descending)"}
                        </div>
                        <div className="sort-by-button">
                          <button
                            onClick={() => handleSort("equipment_tag_no")}
                          >
                            Tag No{" "}
                            {sortOrder.equipment_tag_no === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <button onClick={() => handleSort("department")}>
                            Department{" "}
                            {sortOrder.department === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <button onClick={() => handleSort("frequency")}>
                            Frequency{" "}
                            {sortOrder.frequency === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <button onClick={() => handleSort("status")}>
                            Status{" "}
                            {sortOrder.status === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <button
                            onClick={() => handleSort("service_due_date")}
                          >
                            Service Due Date{" "}
                            {sortOrder.service_due_date === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                          <br />
                          <button
                            onClick={() => handleSort("last_serviced_date")}
                          >
                            Last Service Date{" "}
                            {sortOrder.last_serviced_date === "asc" ? (
                              <MdExpandMore />
                            ) : (
                              <MdExpandLess />
                            )}
                          </button>{" "}
                        </div>
                        <br />
                        <button
                          className="delete-item"
                          onClick={handleArchiveschedule}
                        >
                          Archived Sub Department
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="entity-dashboard">
            <div className="entity-dashboard-contant">
              <div className="entity-dashboard-contant-item">
                <div className="entity-icon"></div>
                <div className="entity-text-left">
                  <div className="entity-text">
                    <div className="entity-text-title">Total Schedules</div>
                    <div
                      className="entity-text-title-total"
                      onClick={fetchData}
                    >
                      {totalSchedules || "0"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="entity-text-right">
                <div className="entity-text">
                  <div className="entity-text-title">Due Today</div>
                  <div
                    className="entity-text-title-total "
                    // onClick={() => fetchSchedulesByDateType("today")}
                  >
                    {dueToday || "0"}
                  </div>
                </div>{" "}
                <div className="entity-text">
                  <div className="entity-text-title">Due Tomorrow</div>
                  <div className="entity-text-title-total">
                    {dueTomorrow || "0"}
                  </div>
                </div>
                <div className="entity-text">
                  <div className="entity-text-title">Overdue</div>
                  <div className="entity-text-title-total">
                    {statusCounts.overdue || "0"}
                  </div>
                </div>
                <div className="entity-text">
                  <div className="entity-text-title">Completed</div>
                  <div className="entity-text-title-total">
                    {statusCounts.Completed || "0"}
                  </div>
                </div>{" "}
                <div className="entity-text">
                  <div className="entity-text-title">Hold</div>
                  <div className="entity-text-title-total">
                    {statusCounts.Hold || "0"}
                  </div>
                </div>
                <div className="entity-text">
                  <div className="entity-text-title">In Progress</div>
                  <div className="entity-text-title-total">
                    {statusCounts.Hold || "0"}
                  </div>
                </div>
                <div className="entity-text">
                  <div className="entity-text-title">Active</div>
                  <div className="entity-text-title-total">
                    {statusCounts.active || "0"}
                  </div>
                </div>
                <div className="entity-text">
                  <div className="entity-text-title">Cancelled</div>
                  <div className="entity-text-title-total">
                    {statusCounts.Cancelled || "0"}
                  </div>
                </div>
                <div className="entity-text">
                  <div className="entity-text-title">Not in Tenure</div>
                  <div className="entity-text-title-total">
                    {statusCounts["not in tenure"] || "0"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="data-list-body" onClick={closePopups}>
            <div className="data-list-scroll-bodys">
              <div className="data-list-tables">
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div>Loading...</div>
                  </div>
                ) : schedules.length > 0 ? (
                  <table className="table multilevel-data">
                    <thead>
                      <tr>
                        <th className="center-alignment">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                        </th>
                        <th className="center-alignment">SR. NO.</th>
                        <th>TAG NO</th>
                        <th>DEPARTMENT</th>
                        <th className="center-alignment">FREQUENCY</th>
                        <th>FREQUENCY TYPE</th>
                        <th className="center-alignment">STATUS</th>
                        <th className="center-alignment">SERVICE DUE DATE</th>
                        <th>LAST SERVICED DATE</th>
                        <th className="center-alignment">ACTION</th>
                      </tr>
                    </thead>
                    <tbody className="data-list-scroll-bodys">
                      {schedules.map((schedule, index) => (
                        <tr key={index} className="hoverable-row">
                          <td className="center-alignment">
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(schedule.id)}
                              onChange={() => handleSelectRow(schedule.id)}
                            />
                          </td>

                          <td className="center-alignment">
                            {schedule.serial_no}
                          </td>
                          <Tooltip title="Click here to show details">
                            <td
                              className="active-td"
                              onClick={() => handleOpenModal(schedule)}
                            >
                              {schedule.equipment_tag_no || "NA"}
                            </td>
                          </Tooltip>
                          <td>{schedule.department || "NA"}</td>
                          <td className="center-alignment">
                            {schedule.frequency || "NA"} Days
                          </td>
                          <td>
                            {schedule.frequency_type
                              ? schedule.frequency_type === "factory_act"
                                ? "Factory Act"
                                : schedule.frequency_type === "peso"
                                ? "Peso"
                                : schedule.frequency_type === "oisd"
                                ? "OISD"
                                : schedule.frequency_type === "oem"
                                ? "OEM"
                                : schedule.frequency_type
                              : "NA"}
                          </td>

                          {/* <td className="status-schedules">
                            <div
                              style={{
                                backgroundColor:
                                  schedule.status === "Hold"
                                    ? "#D4AC0D"
                                    : schedule.status === "Cancelled"
                                      ? "#F81700"
                                      : schedule.status === "not in tenure"
                                        ? "#313639"
                                        : schedule.status === "overdue"
                                          ? "#C0612B"
                                          : schedule.status === "active"
                                            ? "#2980B9"
                                            : schedule.status === "In Progress"
                                              ? "#df7f26"
                                              : schedule.status === "Completed"
                                                ? "#2E8B57"
                                                : "transparent",
                              }}
                            >
                              {schedule.status || "NA"}
                            </div>
                          </td> */}

                          <td className="status-schedules center-alignment">
                            <div
                              style={{
                                backgroundColor:
                                  schedule.status === "Hold"
                                    ? "#D4AC0D"
                                    : schedule.status === "Cancelled"
                                    ? "#F81700"
                                    : schedule.status === "not in tenure"
                                    ? "#313639"
                                    : schedule.status === "overdue"
                                    ? "#C0612B"
                                    : schedule.status === "active"
                                    ? "#2980B9"
                                    : schedule.status === "In Progress"
                                    ? "#df7f26"
                                    : schedule.status === "Completed"
                                    ? "#2E8B57"
                                    : "transparent",
                                width: "6vw",
                              }}
                            >
                              {schedule.status
                                ? schedule.status.charAt(0).toUpperCase() +
                                  schedule.status.slice(1).toLowerCase()
                                : "NA"}
                            </div>
                          </td>

                          <td className="center-alignment">
                            {formatDate(schedule.service_due_date)}
                          </td>
                          <td>{formatDate(schedule.last_serviced_date)}</td>
                          <td className="center-alignment">
                            <button
                              onClick={() => handleViewClick(schedule.id)}
                              className="view-button"
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-users-container">
                    <div className="no-users-message">No {departmentName} Schedules Found</div>
                  </div>
                )}
              </div>
              <Pagination
                showPerPage={itemsPerPage}
                total={totalItems}
                onPaginationChange={handlePaginationChange}
              />
            </div>
          </div>
        </div>
      )}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Paper className="modal-container">
          <div className="modal-header">
            <Typography variant="h6">Equipment Details</Typography>
            <IconButton className="close-btn" onClick={handleCloseModal}>
              {/* <CloseIcon /> */}
            </IconButton>
          </div>
          <div className="modal-body">
            {selectedSchedule && (
              <div className="details-content">
                <p>
                  <strong>Category:</strong>{" "}
                  {selectedSchedule.category || "N/A"}
                </p>
                <p>
                  <strong>Make:</strong> {selectedSchedule.Make || "N/A"}
                </p>
                <p>
                  <strong>Model:</strong> {selectedSchedule.Model || "N/A"}
                </p>
                <p>
                  <strong>Year of Installation:</strong>{" "}
                  {selectedSchedule.InstallationYear || "N/A"}
                </p>
                <p>
                  <strong>Warranty Expiry:</strong>{" "}
                  {formatDate(selectedSchedule.WarrantyExp || "N/A")}
                </p>
                {/* Add more details as needed */}
              </div>
            )}
          </div>
          {/* Optional footer */}
          {/* <div className="modal-footer">
    <Button variant="contained" onClick={handleCloseModal}>Close</Button>
  </div> */}
        </Paper>
      </Modal>
    </div>
  </>
  );
}

export default ElectricalDepartment;
