import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./PreventiveSchedulesView.css";
import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import Tooltip from "@mui/material/Tooltip";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
import API_URL from "../../src/Config";
import Select from "react-select"; // Import react-select
import DatePicker from "react-datepicker";
import { AiOutlineDownload } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
function AmcSchedulesView({ isSidebarExpanded }) {
  const { schedule_id } = useParams();
  const [status, setStatus] = useState("");
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [dateError, setDateError] = useState("");
  const [amcSchedulesData, setAmcSchedulesData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [remark, setRemark] = useState("");
  const [serviceDueDate, setServiceDueDate] = useState([]);
  const [scheduleFileData, setSchedulesFileData] = useState(null);
  // State for other information
  const fileInputRef = useRef(null);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const handlePreviewClick = (url) => {
    setPreviewUrl(url);
  };
  const today = new Date();
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "overdue", label: "Overdue" },
    { value: "Completed", label: "Completed" },
    { value: "Hold", label: "Hold" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "In Progress", label: "In Progress" },
  ];

  const handleCancel = () => {
    navigate("/amc-schedules");
  };

  const handleSubmit = async () => {
    if (status === "Completed") {
      if (attachmentFiles.length === 0) {
        setSnackbarMessage("Please select at least one attachment.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        return;
      }

      if (!serviceDueDate) {
        setDateError("Please select a service date.");
        return;
      }
    }
    await handleUploadSubmit();

    const formattedDate = serviceDueDate
      ? format(new Date(serviceDueDate), "yyyy-MM-dd")
      : undefined;

    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.put(
        `${API_URL}/api/update_amc_schedule_status/${schedule_id}`,
        {
          status: status,
          service_date: formattedDate,
          remark: remark,
        },
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { message } = response.data;
        setSnackbarMessage(message || "Scheduler status updated successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        fetchAmcSchedules();
        // Refresh the scheduler data
      } else {
        const { message } = response.data;
        setSnackbarMessage(message || "Failed to update scheduler status");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while updating scheduler status";
      console.error(
        "An error occurred while updating scheduler status:",
        error
      );
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleDefaultStatus = async () => {
    const authKey = localStorage.getItem("authKey");
    try {
      const response = await axios.put(
        `${API_URL}/api/reset_amc_status_to_default/${schedule_id}`,
        {},
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { message } = response.data;
        setSnackbarMessage(message || "Status reset to default successfully");
        setSnackbarSeverity("success");
        fetchAmcSchedules(); // Refresh the data
      } else {
        const { message } = response.data;
        setSnackbarMessage(message || "Failed to reset status to default");
        setSnackbarSeverity("error");
      }
      setSnackbarOpen(true);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while resetting status";
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string" || string.length === 0) {
      return string; // Return as is if not a valid string or empty
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const fetchAmcSchedules = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await fetch(
        `${API_URL}/api/get_amc_schedule/${schedule_id}`,
        {
          method: "GET",
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const schedule = Array.isArray(data) ? data[0] : data;
        setAmcSchedulesData(schedule); // Ensure it's in array format
        const fetchedStatus = schedule.status || schedule.new_status;
        setStatus(fetchedStatus || "Unknown");
        const fetchedServiceDate = schedule.serviced_date
          ? new Date(schedule.serviced_date)
          : new Date(); // Defaults to today

        setServiceDueDate(fetchedServiceDate);
        setSchedulesFileData(Array.isArray(data) ? data : [data]);
        setRemark(schedule.remark);
      } else {
        console.error("Error fetching schedule:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching schedule:", error.message);
    }
  };

  useEffect(() => {
    fetchAmcSchedules();
  }, [schedule_id]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleBackScheduler = () => {
    navigate("/amc-schedules");
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const newFileDetails = Array.from(files).map((file) => ({
        name: file.name,
        size: file.size,
      }));

      setSelectedFiles((prevFiles) => [...prevFiles, ...newFileDetails]);
      setAttachmentFiles((prevFiles) => [...prevFiles, ...files]);
    }
  };

  const handleUploadSubmit = async () => {
    if (attachmentFiles.length === 0) return;

    const authKey = localStorage.getItem("authKey");
    const formData = new FormData();
    formData.append("schedule_id", schedule_id);

    // Append all files to FormData
    attachmentFiles.forEach((file) => {
      formData.append("attachments", file);
    });

    try {
      const response = await axios.post(
        `${API_URL}/api/upload_amc_schedule_datasheet/${schedule_id}`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSnackbarMessage("File uploaded successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setAttachmentFiles([]);
        setSelectedFiles([]);
        fetchAmcSchedules();
      } else {
        setSnackbarMessage("Failed to upload file");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while uploading file");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption.value);
    // Reset completedDate and dateError if status is not "Completed"
    if (selectedOption.value !== "Completed") {
      setServiceDueDate(null);
      setDateError("");
    }
  };

  return (
    <>
      <div
        className={`invoice-request-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="invoice-request-content">
          <div className="invoice-request-header">
            <div className="invoice-request-header-content">
              <div className="invoice-request-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackScheduler}
                  />
                </Tooltip>
                AMC schedule details
              </div>
            </div>
          </div>
          <div className="action-purchase-header">
            <div className="action-purchase-header-content">
              {amcSchedulesData ? (
                <div className="project-detailss">
                  <div className="project-detail-arround">
                    <div className="project-details-left-side">
                      <div className="project-detail">
                        <div className="detail-header">Department Name:</div>
                        <div className="detail-part">
                          {amcSchedulesData.department_name
                            ? amcSchedulesData.department_name
                            : "N/A"}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">AMC Name:</div>
                        <div className="detail-part">
                          {amcSchedulesData.amc_name}
                        </div>
                      </div>

                      <div className="project-detail">
                        <div className="detail-header">Service Provider:</div>
                        <div className="detail-part">
                          {amcSchedulesData.service_provider_name
                            ? amcSchedulesData.service_provider_name
                            : "N/A"}
                        </div>
                      </div>

                      <div className="project-detail">
                        <div className="detail-header">Service Date:</div>
                        <div className="detail-part">
                          {new Date(
                            amcSchedulesData.service_date
                          ).toLocaleDateString()}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Status:</div>
                        <div className="detail-part">
                          {amcSchedulesData.status}
                        </div>
                      </div>
                      <div className="project-detail">
                        <div className="detail-header">Services:</div>
                        <div className="detail-part">
                          {amcSchedulesData.services}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
          <div className="invoice-body-container">
            <div className="invoice-body-container-content-schedule">
              <div className="invoice-upload-files-schedules">
                {amcSchedulesData ? (
                  <div className="current-status">
                    <label className="upload-label" htmlFor="docket-file">
                      Current Status:{" "}
                      <span
                        className={
                          amcSchedulesData.status === "Completed"
                            ? "status-completed"
                            : amcSchedulesData.status === "active"
                            ? "status-active"
                            : amcSchedulesData.status === "overdue"
                            ? "status-overdue"
                            : amcSchedulesData.status === "not in tenure"
                            ? "status-not-in-tenure"
                            : amcSchedulesData.status === "Cancelled"
                            ? "status-cancelled"
                            : amcSchedulesData.status === "In Progress"
                            ? "status-progress"
                            : amcSchedulesData.status === "Hold"
                            ? "status-hold"
                            : amcSchedulesData.status === "not in tenure"
                            ? "status-schedule"
                            : ""
                        }
                      >
                        {capitalizeFirstLetter(amcSchedulesData.status)}
                      </span>
                    </label>
                  </div>
                ) : (
                  <p>Loading...</p>
                )}

                <div className="status-content">
                  <div className="form-group-select">
                    <label htmlFor="docket-file">Change Status:</label>
                    <Select
                      options={statusOptions}
                      value={statusOptions.find(
                        (option) => option.value === status
                      )}
                      onChange={handleStatusChange}
                      placeholder="Select status"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                          color: theme === "dark" ? "#fff" : "#333", // Text color
                          borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                          cursor: "pointer",
                          boxShadow: "none",
                          "&:hover": {
                            borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                          border: `1px solid ${
                            theme === "dark" ? "#444" : "#ccc"
                          }`, // Border for menu when opened
                          boxShadow:
                            theme === "dark"
                              ? "0 4px 8px rgba(255, 255, 255, 0.1)"
                              : "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow effect
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused
                            ? "#666" // Gray when hovered
                            : theme === "dark"
                            ? "#000"
                            : "#fff", // Black background for options in dark mode
                          color: state.isFocused
                            ? "#fff"
                            : theme === "dark"
                            ? "#fff"
                            : "#333", // Text color
                          cursor: "pointer",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                        }),
                      }}
                    />
                  </div>
                  {status === "Hold" && (
                    <>
                      <div className="form-group">
                        <label>Remarks:</label>
                        <div className="input-group">
                          <textarea
                            rows="4" // You can adjust the number of rows
                            placeholder="Enter your remarks here..." // Placeholder text
                            className="form-control" // Add any necessary classes for styling
                            value={remark} // Controlled component
                            onChange={(e) => setRemark(e.target.value)} // Update state on change
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <>
                    {status === "Completed" && (
                      <>
                        <div className="form-group">
                          <label>Service Date:</label>
                          <div className="input-group-datepicker">
                            <DatePicker
                              selected={serviceDueDate}
                              onChange={(date) => setServiceDueDate(date)}
                              placeholderText="Select Service date"
                              dateFormat="dd/MM/yyyy"
                              isClearable
                              showYearDropdown
                              showMonthDropdown
                              dropdownMode="select"
                              maxDate={today}
                              className={
                                theme === "dark" ? "dark-theme-datepicker" : ""
                              }
                            />
                          </div>
                          {dateError && (
                            <div className="error-message">{dateError}</div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="upload-label" htmlFor="docket-file">
                            Upload Maintenance Report:
                          </label>
                          {scheduleFileData.length > 0 &&
                            Array.isArray(scheduleFileData) &&
                            scheduleFileData.map((equipment, index) => (
                              <div key={index}>
                                <div className="chalann-input">
                                  <button
                                    type="button"
                                    className="challan-btns"
                                    onClick={handleUploadButtonClick} // Open file dialog
                                  >
                                    <FiUpload /> Upload File
                                  </button>
                                  <input
                                    type="file"
                                    id="fileInput"
                                    ref={fileInputRef}
                                    onChange={handleFileInputChange}
                                    style={{ display: "none" }}
                                  />
                                </div>
                                {selectedFiles.length > 0 && (
                                  <div className="attchment-file-lists">
                                    <ul
                                      style={{
                                        listStyleType: "none",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                    >
                                      {selectedFiles.map((file, index) => (
                                        <li key={index}>{file.name}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                                <div>
                                  {/* Check if attachments exist */}
                                  {equipment.attachments &&
                                  equipment.attachments.length > 0 ? (
                                    equipment.attachments.map((file) => (
                                      <div
                                        key={file.file_id}
                                        className="file-name-lists"
                                      >
                                        <span>File Name: </span>
                                        <span
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handlePreviewClick(file.preview_url)
                                          }
                                        >
                                          {file.filename.length > 15
                                            ? `${file.filename.slice(0, 15)}...`
                                            : file.filename}{" "}
                                          ({file.file_size} KB)
                                        </span>

                                        <a
                                          href={`${API_URL}/api/download_attachment/${file.file_id}/${file.file_type}`}
                                          download
                                        >
                                          <AiOutlineDownload className="download-icon" />
                                        </a>
                                      </div>
                                    ))
                                  ) : (
                                    <p>No attachments available.</p> // Show a message if no attachments
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="schedules-button footer">
            <button className="data-form-save-button" onClick={handleSubmit}>
              Save
            </button>
            <button className="data-form-cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button className="reset-button" onClick={handleDefaultStatus}>
              Reset Status to Default
            </button>
          </div>
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default AmcSchedulesView;
