import { useState,useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { HiOutlinePlus } from "react-icons/hi";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import { MdAttachFile } from "react-icons/md";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

function POConfigurationlist({ isSidebarExpanded }) {
  const [poConfigurations, setPOConfigurations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const popupRef = useRef(null)
  const [isPopupOpen, setIsPopupOpen] = useState(null);
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);


  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  
  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedschedules = [...poConfigurations].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setPOConfigurations(sortedschedules);
  };
 
  const handleIconClick = (index) => {
    // setIsPopupOpen(inquiryID);
    setIsPopupOpen(index === isPopupOpen ? null : index);
    setSelectedRowIndex(false);
  };


  const handleClosePopup = () => {
    setIsSortPopup(false);
    setIsPopupOpen(false);
  };

  useClickOutside([containerRef, buttonRef], handleClosePopup, 200); // 200ms delay
  useClickOutside([popupRef, buttonRef], handleClosePopup, 300);
  
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };
  const handleViewAttachment = (attachment) => {
    window.open(
      `${API_URL}/api/view_pr_attachment/${attachment.file_id}/${attachment.file_type}`,
      "_blank"
    );
  };


  const handleButtonClick = (index) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/get_all_po_list`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setPOConfigurations(response.data.POLists || 0);

        setTotalItems(response.data.TotalItems); // Set total items
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching PO data:", error.message);
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }

    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/search_po_list`, {
        headers: {
          Authorization: authKey,
          "Content-Type": "application/json",
        },
        params: {
          query: searchInput,
          page: currentPage,
          per_page: itemsPerPage,
        },
      });

      if (response.status === 200) {
        setPOConfigurations(response.data.POLists); // Ensure it's always an array
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll
        ? poConfigurations.map((poConfiguration) => poConfiguration.id)
        : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (poConfigurationID) => {
    const updatedSelectedRows = selectedRows.includes(poConfigurationID)
      ? selectedRows.filter((id) => id !== poConfigurationID)
      : [...selectedRows, poConfigurationID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === poConfigurations.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchivedPoList = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_department`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDesignations = poConfigurations.filter(
          (designation) => !selectedRows.includes(designation.ID)
        );
        setPOConfigurations(updatedDesignations);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting designations:", error);
    }
  };

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleArchivePoList = () => {
    navigate("/archived-po-list");
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    if (searchInput) {
      console.log('Search input is set:', searchInput);
    } else {
      fetchData(page, itemsPerPage);
    }
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleViewClick = async (po_id) => {
    navigate(`/po-view/${po_id}`);
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
          }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                    <button
                        className="delete"
                        onClick={handleDeletePopup}
                      >
                        Move to archive
                      </button>
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchivedPoList}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All PO List
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>

                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button
                      className="sort-button"
                      onClick={handleSortpopup}
                      ref={buttonRef}
                    >
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("po_no")}>
                            PO Number{" "}
                              {sortOrder.po_no === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                          
                            <button onClick={() => handleSort("vendor_name")}>
                              Vendor Name{" "}
                              {sortOrder.vendor_name === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("IsInwarded")}>
                              Status{" "}
                              {sortOrder.IsInwarded === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                           
                          <button className='delete-item' onClick={handleArchivePoList}>Archived Sub Department</button>
                            
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <div className="loader-container">
                      <div className="loader"></div>
                      <div>Loading...</div>
                    </div>
                  ) : poConfigurations.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>PO NUMBER</th>
                          <th>VENDOR NAME</th>
                          <th>STATUS</th>
                          <th>CREATED AT</th>
                          <th>ATTACHMENT</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {poConfigurations.map((poData, index) => (
                          <tr
                            key={poData.id} // Use po_list.id as the key
                            className="hoverable-row"
                          >
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(poData.id)}
                                onChange={() => handleSelectRow(poData.id)}
                              />
                            </td>
                            <td>{index + 1}</td>
                            <td style={{ color: "blue" }}>
                              {poData.po_no || "NA"}
                            </td>
                            <td>{poData.vendor_name || "NA"}</td>
                            {/* <td>{poData.IsInwarded === 1 ? "Closed" : "Open"}</td> */}
                            <td
                              style={{
                                color:
                                  poData.IsInwarded === 1 ? "red" : "green",
                              }}
                            >
                              {poData.IsInwarded === 1 ? "Closed" : "Open"}
                            </td>

                            <td>
                              {poData.created_at
                                ? new Date(poData.created_at)
                                  .toUTCString()
                                  .split("GMT")[0]
                                  .trim() + " IST"
                                : "NA"}
                            </td>
                            <td>
                              <span
                                className="inquiry-file-btns"
                                onClick={(event) => handleIconClick(index, event)}
                                ref={buttonRef}
                              >
                                <MdAttachFile className="inquiry-file-icon" />
                              </span>
                              {isPopupOpen === index && (
                                <div className="inquiry-attach-popup polist-popup" ref={popupRef}>
                                  <div className="attach-popup-contant">
                                    <div className="attachment-header">
                                      <div className="attachment-header-content">
                                        Attachments
                                      </div>
                                      <button
                                        className="fiile-close-btn"
                                        onClick={handleCloseAttachment}
                                      >
                                        <IoCloseOutline className="file-close-icon" />
                                      </button>
                                    </div>
                                    <div
                                      className="attach-popup-contant-body"
                                      key={index}
                                    >
                                      <div className="inquiry-attach-file-containerssss">
                                        <div className="attach-file-showsss">
                                          <div className="text-attachmentss">
                                            {poData.file_details &&
                                              poData.file_details.length > 0 ? (
                                              <div className="attach-popup-content-body">
                                                {poData.file_details.map(
                                                  (
                                                    attachment,
                                                    attachmentIndex
                                                  ) => (
                                                    <div
                                                      className="inquiry-attach-file-container"
                                                      key={attachmentIndex}
                                                    >
                                                      <div className="inquiry-attacth-file-model">
                                                        <div className="attach-file-show">
                                                          <div className="text-attachment">
                                                            {attachment.filename
                                                              .length > 20
                                                              ? `${attachment.filename.substring(
                                                                0,
                                                                15
                                                              )}...`
                                                              : attachment.filename}
                                                          </div>
                                                          <div className="text-attachment-file">
                                                            File Size:{" "}
                                                            {attachment.file_size ||
                                                              "NA"}{" "}
                                                            KB
                                                          </div>
                                                        </div>

                                                       
                                                        <div className="file-footer">
                                                          <a
                                                            href={`${API_URL}/api/download_pr_attachment/${attachment.file_id}/${attachment.file_type}`}
                                                            download
                                                            className="file-download-attachment file-txt"
                                                          >
                                                            Download
                                                          </a>
                                                          <a
                                                            href={`${API_URL}/api/view_pr_attachment/${attachment.file_id}/${attachment.file_type}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="file-download-attachment file-txt"
                                                          >
                                                            View
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            ) : (
                                              <div className="no-attachments">
                                                No attachments found
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="file-delete-btn"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>

                            <td>
                              <button
                                onClick={() => handleViewClick(poData.id)}
                                className="view-button"
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No PO Configuration Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
        )}
        {/* {isMainContainerVisible && (
          <div className="main-containers">
            <UserView userID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )} */}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default POConfigurationlist;
