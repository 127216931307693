
import React, { useState, useRef } from "react";
import "./UserForm.css";
import "./SatutoryForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { FiUpload } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { MdAttachFile } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";

function SatutoryForm({ isSidebarExpanded }) {
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const file2InputRef = useRef(null);
    const file3InputRef = useRef(null);
    const file4InputRef = useRef(null);
    const file5InputRef = useRef(null);
    const file6InputRef = useRef(null);
    const file7InputRef = useRef(null);
    const file8InputRef = useRef(null);
    const file9InputRef = useRef(null);
    const file10InputRef = useRef(null);
    const file11InputRef = useRef(null);
    const file12InputRef = useRef(null);
    const file13InputRef = useRef(null);
    const file14InputRef = useRef(null);
    const file15InputRef = useRef(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpen2, setIsPopupOpen2] = useState(false);
    const [selectedFiles2, setSelectedFiles2] = useState([]);
    const [isPopupOpen3, setIsPopupOpen3] = useState(false);
    const [selectedFiles3, setSelectedFiles3] = useState([]);
    const [isPopupOpen4, setIsPopupOpen4] = useState(false);
    const [selectedFiles4, setSelectedFiles4] = useState([]);
    const [isPopupOpen5, setIsPopupOpen5] = useState(false);
    const [selectedFiles5, setSelectedFiles5] = useState([]);
    const [isPopupOpen6, setIsPopupOpen6] = useState(false);
    const [selectedFiles6, setSelectedFiles6] = useState([]);
    const [isPopupOpen7, setIsPopupOpen7] = useState(false);
    const [selectedFiles7, setSelectedFiles7] = useState([]);
    const [isPopupOpen8, setIsPopupOpen8] = useState(false);
    const [selectedFiles8, setSelectedFiles8] = useState([]);
    const [isPopupOpen9, setIsPopupOpen9] = useState(false);
    const [selectedFiles9, setSelectedFiles9] = useState([]);
    const [isPopupOpen10, setIsPopupOpen10] = useState(false);
    const [selectedFiles10, setSelectedFiles10] = useState([]);
    const [isPopupOpen11, setIsPopupOpen11] = useState(false);
    const [selectedFiles11, setSelectedFiles11] = useState([]);
    const [isPopupOpen12, setIsPopupOpen12] = useState(false);
    const [selectedFiles12, setSelectedFiles12] = useState([]);
    const [isPopupOpen13, setIsPopupOpen13] = useState(false);
    const [selectedFiles13, setSelectedFiles13] = useState([]);
    const [isPopupOpen14, setIsPopupOpen14] = useState(false);
    const [selectedFiles14, setSelectedFiles14] = useState([]);
    const [isPopupOpen15, setIsPopupOpen15] = useState(false);
    const [selectedFiles15, setSelectedFiles15] = useState([]);
    const [satutoryData, setSatutoryData] = useState({
        month: null,
        year: null,
    });

    //  attachment 1

    const handleIconClick = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment = () => {
        setIsPopupOpen(false);
    };
    const handleDeleteFile = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // attachment 2

    const handleIconClick2 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen2(true);
        setIsPopupOpen(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment2 = () => {
        setIsPopupOpen2(false);
    };
    const handleDeleteFile2 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles2.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles2(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment2(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick2 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // attachment 3


    const handleIconClick3 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen3(true);
        setIsPopupOpen2(false);
        setIsPopupOpen(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment3 = () => {
        setIsPopupOpen3(false);
    };
    const handleDeleteFile3 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles3.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles3(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment3(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick3 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // attchment 4

    const handleIconClick4 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen4(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment4 = () => {
        setIsPopupOpen4(false);
    };
    const handleDeleteFile4 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles4.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles4(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment4(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick4 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };


    // attchment 5

    const handleIconClick5 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen5(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment5 = () => {
        setIsPopupOpen5(false);
    };
    const handleDeleteFile5 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles5.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles5(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment5(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick5 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // attchment 6

    const handleIconClick6 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen6(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment6 = () => {
        setIsPopupOpen6(false);
    };
    const handleDeleteFile6 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles6.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles6(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment6(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick6 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // attchment 7

    const handleIconClick7 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen7(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment7 = () => {
        setIsPopupOpen7(false);
    };
    const handleDeleteFile7 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles7.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles7(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment7(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick7 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // attchment 8

    const handleIconClick8 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen8(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment8 = () => {
        setIsPopupOpen8(false);
    };
    const handleDeleteFile8 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles8.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles8(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment8(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick8 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // attchment 9

    const handleIconClick9 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen9(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment9 = () => {
        setIsPopupOpen9(false);
    };
    const handleDeleteFile9 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles9.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles9(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment9(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick9 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // attchment 10

    const handleIconClick10 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen10(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment10 = () => {
        setIsPopupOpen10(false);
    };
    const handleDeleteFile10 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles10.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles10(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment10(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick10 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };


    // attchment 11

    const handleIconClick11 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen11(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment11 = () => {
        setIsPopupOpen11(false);
    };
    const handleDeleteFile11 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles11.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles11(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment11(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick11 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };


    // attchment 12

    const handleIconClick12 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen12(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment12 = () => {
        setIsPopupOpen12(false);
    };
    const handleDeleteFile12 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles12.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles12(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment12(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick12 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };


    // attchment 13

    const handleIconClick13 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen13(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen(false);
        setIsPopupOpen14(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment13 = () => {
        setIsPopupOpen13(false);
    };
    const handleDeleteFile13 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles13.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles13(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment13(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick13 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // attchment 14

    const handleIconClick14 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen14(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen(false);
        setIsPopupOpen15(false);
    };
    const handleCloseAttachment14 = () => {
        setIsPopupOpen14(false);
    };
    const handleDeleteFile14 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles14.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles14(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment14(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick14 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // attchment 15

    const handleIconClick15 = () => {
        // Show selected files when icon is clicked
        setIsPopupOpen15(true);
        setIsPopupOpen2(false);
        setIsPopupOpen3(false);
        setIsPopupOpen4(false);
        setIsPopupOpen5(false);
        setIsPopupOpen6(false);
        setIsPopupOpen7(false);
        setIsPopupOpen8(false);
        setIsPopupOpen9(false);
        setIsPopupOpen10(false);
        setIsPopupOpen11(false);
        setIsPopupOpen12(false);
        setIsPopupOpen13(false);
        setIsPopupOpen14(false);
        setIsPopupOpen(false);
    };
    const handleCloseAttachment15 = () => {
        setIsPopupOpen15(false);
    };
    const handleDeleteFile15 = (indexToDelete) => {
        console.log('Delete function called for index:', indexToDelete);
        console.log('Before delete:', selectedFiles);

        // Filter out the file at the given index
        const updatedFiles = selectedFiles15.filter((_, index) => index !== indexToDelete);

        // Update the state
        setSelectedFiles15(updatedFiles);
        console.log('After delete:', updatedFiles);

        // Check if no files remain, then close the popup
        if (updatedFiles.length === 0) {
            handleCloseAttachment15(); // Close the popup if no files remain
            console.log('Popup closed');
        }
    };
    const handleUploadButtonClick15 = (inputRef) => {
        // This function handles the file upload button click
        // Open the file input dialog
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleFileInputChange = (files, setFiles) => {
        const fileArray = Array.from(files);
        setFiles(fileArray);
    };


    const handleCancelClick = () => {
        navigate("/hr-modules");
    };

    const handleSaveClick = async () => {

        try {
            setIsLoading(true);
            const authKey = localStorage.getItem("authKey");
            const formData = new FormData();

            formData.append("month", satutoryData.month);
            formData.append("year", satutoryData.year);


            // selectedMSMEFiles.forEach((file) => formData.append("msme_attachments", file));
            selectedFiles.forEach((file) => formData.append("wage_register", file));
            selectedFiles2.forEach((file) => formData.append("loan_recoveries", file));
            selectedFiles3.forEach((file) => formData.append("attendance", file));
            selectedFiles4.forEach((file) => formData.append("overtime", file));
            selectedFiles5.forEach((file) => formData.append("bank_statement", file));
            selectedFiles6.forEach((file) => formData.append("bank_seal", file));
            selectedFiles7.forEach((file) => formData.append("wage_slip_copy", file));
            selectedFiles8.forEach((file) => formData.append("tax_receipt", file));
            selectedFiles9.forEach((file) => formData.append("bonus_register", file));
            selectedFiles10.forEach((file) => formData.append("leave_register", file));
            selectedFiles11.forEach((file) => formData.append("pf_payment_confirmation", file));
            selectedFiles12.forEach((file) => formData.append("pf_combined_challan", file));
            selectedFiles13.forEach((file) => formData.append("pf_ecr", file));
            selectedFiles14.forEach((file) => formData.append("esi_payment", file));
            selectedFiles15.forEach((file) => formData.append("esi_contribution", file));

            const response = await axios.post(
                `${API_URL}/api/add_human_resources`,
                formData,
                {
                    headers: {
                        Authorization: authKey,
                    },
                }
            );

            console.log(response.data.message);
            if (response.status === 200) {
                setSeverity("success");
                showSnackbar("Hr files added successfully");
                setTimeout(() => {
                    navigate("/hr-modules");
                }, 1000);
            }
        } catch (error) {
            console.error("Error saving department:", error.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const showSnackbar = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSaveClick();
        }
    };

    return (
        <>
            <div
                className={`data-form-content-container page-content ${isSidebarExpanded ? "expanded" : "collapsed"
                    }`}
            >
                <div className="data-form-content">
                    <div className="data-form-header">
                        <div className="data-form-header-content">
                            <div className="data-form-heading-content-left-side">
                                New HR Form
                            </div>
                            <div className="data-form-heading-content-right-side"></div>
                        </div>
                    </div>
                    <div className="header-separator"></div>
                    <div className="data-form-body">
                        <form className="form-detail" onKeyDown={handleKeyDown}>
                            <div className="new-data-form">
                                <div className="form-group">
                                    <label htmlFor="month">Month</label>
                                    <div className="input-group-datepicker">
                                        <DatePicker
                                            selected={selectedMonth}
                                            onChange={(date) => {
                                                setSelectedMonth(date);
                                                setSatutoryData((prevData) => ({
                                                    ...prevData,
                                                    month: date ? date.getMonth() + 1 : null,  // Month as number
                                                }));
                                            }}
                                            name="date_of_seizure"
                                            id="date_of_seizure"
                                            dateFormat="MM"
                                            placeholderText="Select month"
                                            className="datesss"
                                            isClearable={false}
                                            showMonthYearPicker
                                            onKeyDown={(e) => {
                                                if (e.key === "Backspace") {
                                                    setSelectedMonth(null);
                                                    setSatutoryData((prevData) => ({
                                                        ...prevData,
                                                        month: null,
                                                    }));
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="year">Year</label>
                                    <div className="input-group-datepicker">
                                        <DatePicker
                                            selected={selectedYear}
                                            onChange={(date) => {
                                                setSelectedYear(date);
                                                setSatutoryData((prevData) => ({
                                                    ...prevData,
                                                    year: date ? date.getFullYear() : null,  // Year as number
                                                }));
                                            }}
                                            name="date_of_seizure"
                                            id="date_of_seizure"
                                            dateFormat="yyyy"
                                            placeholderText="Select year"
                                            className="datesss"
                                            isClearable={false}
                                            showYearPicker
                                            onKeyDown={(e) => {
                                                if (e.key === "Backspace") {
                                                    setSelectedYear(null);
                                                    setSatutoryData((prevData) => ({
                                                        ...prevData,
                                                        year: null,
                                                    }));
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="form-group-attachmentss">
                                    {/* <div className="form-group"> */}
                                    <label htmlFor="wage_register">
                                        Form B - Wage register duly certified by Engineer In-Charge (EIC) with Emp No
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick(fileInputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="wage_register"
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles)
                                        }
                                        multiple
                                    />
                                    {selectedFiles.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {/* </div> */}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="loan_recoveries">
                                        Form C - Register of Loan Recoveries for the corresponding month duly certified by EIC
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick2(file2InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="loan_recoveries"
                                        type="file"
                                        ref={file2InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles2)
                                        }
                                        multiple
                                    />
                                    {selectedFiles2.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick2}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles2.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen2 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment2}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles2.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles2.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile2(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attendance">
                                        Form D - Attendance Register
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick3(file3InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attendance"
                                        type="file"
                                        ref={file3InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles3)
                                        }
                                        multiple
                                    />
                                    {selectedFiles3.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick3}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles3.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen3 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment3}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles3.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles3.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile3(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="overtime">
                                        Form IX - Overtime Register
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick4(file4InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="overtime"
                                        type="file"
                                        ref={file4InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles4)
                                        }
                                        multiple
                                    />
                                    {selectedFiles4.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick4}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles4.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen4 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment4}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles4.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles4.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile4(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attachment5">
                                        Bank Statement in respect of disbursement of wages for the corresponding month with Contractor
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick5(file5InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attachment5"
                                        type="file"
                                        ref={file5InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles5)
                                        }
                                        multiple
                                    />
                                    {selectedFiles5.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick5}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles5.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen5 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment5}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles5.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles5.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile5(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attachment6">
                                        Bank seal and sign
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick6(file6InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attachment6"
                                        type="file"
                                        ref={file6InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles6)
                                        }
                                        multiple
                                    />
                                    {selectedFiles6.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick6}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles6.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen6 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment6}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles6.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles6.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile6(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attachment7">
                                        Form XIX - Copy of Wage Slip for the corresponding month with seal and sign of Contractor
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick7(file7InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attachment7"
                                        type="file"
                                        ref={file7InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles7)
                                        }
                                        multiple
                                    />
                                    {selectedFiles7.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick7}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles7.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen7 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment6}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles7.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles7.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile7(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attachment8">
                                        Professional Tax receipt for the corresponding month
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick8(file8InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attachment8"
                                        type="file"
                                        ref={file8InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles8)
                                        }
                                        multiple
                                    />
                                    {selectedFiles8.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick8}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles8.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen8 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment8}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles8.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles8.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile8(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attachment9">
                                        Form C - Bonus Register, to be paid on quarterly basis
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick9(file9InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attachment9"
                                        type="file"
                                        ref={file9InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles9)
                                        }
                                        multiple
                                    />
                                    {selectedFiles9.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick9}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles9.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen9 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment9}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles9.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles9.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile9(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attachment10">
                                        Form 14 - Leave with wages Register, to be paid on quarterly basis
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick10(file10InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attachment10"
                                        type="file"
                                        ref={file10InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles10)
                                        }
                                        multiple
                                    />
                                    {selectedFiles10.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick10}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles10.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen10 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment9}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles10.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles10.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile10(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attachment11">
                                        PF Payment Confirmation Receipt for the corresponding month
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick11(file11InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attachment11"
                                        type="file"
                                        ref={file11InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles11)
                                        }
                                        multiple
                                    />
                                    {selectedFiles11.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick11}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles11.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen11 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment11}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles9.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles11.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile11(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attachment12">
                                        PF Combined Challan for the corresponding month
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick12(file12InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attachment12"
                                        type="file"
                                        ref={file12InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles12)
                                        }
                                        multiple
                                    />
                                    {selectedFiles12.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick12}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles12.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen12 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment12}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles12.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles12.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile12(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attachment13">
                                        PF ECR (electronic challan cum returns) for the corresponding month
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick13(file13InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attachment13"
                                        type="file"
                                        ref={file13InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles13)
                                        }
                                        multiple
                                    />
                                    {selectedFiles13.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick13}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles13.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen13 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment13}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles13.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles13.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile13(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attachment14">
                                        ESI Payment Confirmation Challan for the corresponding month
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick14(file14InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attachment14"
                                        type="file"
                                        ref={file14InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles14)
                                        }
                                        multiple
                                    />
                                    {selectedFiles14.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick14}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles14.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen14 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment14}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles14.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles14.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile14(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group-attachmentss">
                                    <label htmlFor="attachment15">
                                        ESI Contribution Statement for the corresponding month
                                    </label>
                                    <button
                                        type="button"
                                        className="challan-btn"
                                        onClick={() => handleUploadButtonClick15(file15InputRef)}
                                    >
                                        <FiUpload /> Upload File
                                    </button>
                                    <input
                                        id="attachment15"
                                        type="file"
                                        ref={file15InputRef}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                            handleFileInputChange(e.target.files, setSelectedFiles15)
                                        }
                                        multiple
                                    />
                                    {selectedFiles15.length > 0 && (
                                        <span
                                            className="inquiry-file-btn"
                                            onClick={handleIconClick15}
                                        >
                                            <MdAttachFile className="inquiry-file-icon" />{" "}
                                            {`${selectedFiles15.length}`}
                                        </span>
                                    )}
                                    {isPopupOpen15 && (
                                        <div className="attach-popup-attachments">
                                            <div className="attach-popup-contant">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-content">
                                                        Attachments
                                                    </div>
                                                    <button
                                                        className="fiile-close-btn"
                                                        onClick={handleCloseAttachment15}
                                                    >
                                                        <IoCloseOutline className="file-close-icon" />
                                                    </button>
                                                </div>
                                                {selectedFiles15.length > 0 && (
                                                    <div className="attach-scrroll-body">
                                                        {selectedFiles15.map((file, index) => (
                                                            <div
                                                                className="attach-popup-contant-body"
                                                                key={index}
                                                            >
                                                                <div className="attach-file-container">
                                                                    <div className="attach-file-show">
                                                                        <div className="text-attachment">
                                                                            {file.name}
                                                                        </div>
                                                                        <div className="text-attachment-file">
                                                                            File Size:(
                                                                            {(file.size / 1024).toFixed(2)} KB)
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="file-delete-btn"
                                                                        onClick={() => handleDeleteFile15(index)}
                                                                    >
                                                                        <span>
                                                                            {" "}
                                                                            <MdDeleteOutline className="file-delete-icon" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="footer">
                        <div className="button-group">
                            <button
                                className="data-form-save-button"
                                type="save"
                                onClick={handleSaveClick}
                                disabled={isLoading}
                            >
                                {isLoading ? "Saving..." : "Save"}
                            </button>
                            <button
                                className="data-form-cancel-button"
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000} // Converted to milliseconds
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ marginTop: "35px", marginLeft: "20px" }}
            >
                <MuiAlert onClose={handleSnackbarClose} severity={severity}>
                    {snackbarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default SatutoryForm;
