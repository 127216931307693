import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { IoMdArrowBack } from "react-icons/io";
import API_URL from "../../src/Config";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";
import { format } from "date-fns";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
const { Control, Menu } = components;
function EditStatutoryCompliance({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [statutoryData, setStatutoryData] = useState({
    department_name: "",
    equipment_type: "",
    tag_no: "",
    date: "",
    description: "",
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [selectedEquipmentTypes, setSelectEdequipmentType] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [tagNo, setTagNo] = useState([]);
  const [selectedTagNo, setSelectedTagNo] = useState(null);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [EquipmentTypeMenuIsOpen, setEquipmentTypeMenuIsOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [departmentMenuIsOpen, setDepartmentMenuIsOpen] = useState(false);
  const { theme } = useContext(ThemeContext);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  useEffect(() => {
    const fetchStatutoryData = async () => {
      try {
        const authKey = localStorage.getItem("authKey");
        const response = await axios.get(
          `${API_URL}/api/get_statutory_compliance/${id}`,
          {
            headers: {
              Authorization: authKey,
            },
          }
        );
        const responseData = response.data;

        // Update statutory data state
        setStatutoryData({
          department_name: responseData.department_name,
          equipment_type: responseData.equipment_type,
          tag_no: responseData.tag_no,
          date: new Date(responseData.date),
          description: responseData.description,
        });

        // Update selected date state
        setSelectedDate(new Date(responseData.date));

        // Update selected department state
        setSelectedDepartment({
          value: responseData.department_id,
          label: responseData.department_name,
        });
        setSelectEdequipmentType({
          value: responseData.equipment_id,
          label: responseData.equipment_type,
        });
        // Update selected tag number state
        setSelectedTagNo({
          value: responseData.tag_no,
          label: responseData.tag_no,
        });
      } catch (error) {
        console.error("Error fetching compliance data:", error.message);
        setError("An error occurred while fetching compliance data");
      }
    };

    fetchStatutoryData();
  }, [id]);

  const handleEquipmentTypeChange = (selectedOption) => {
    setSelectEdequipmentType(selectedOption);
    setStatutoryData((prevFormData) => ({
      ...prevFormData,
      equipment_type: selectedOption.label,
      EquipmentTypeId: selectedOption.value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      equipment_type: "", // Clear the error message for the 'date' field
    }));
  };
  const handleEquipmentTypeMenuOpen = () => {
    setEquipmentTypeMenuIsOpen(true);
  };
  const fetchTagNo = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_equipment`, {
        headers: {
          Authorization: authKey,
        },
      });

      // Ensure the data is an array under the "Items" key
      if (response.data && Array.isArray(response.data.Items)) {
        const tagNoOptions = response.data.Items.map((item) => ({
          value: item.TagNo,
          label: item.TagNo,
        }));
        setTagNo(tagNoOptions);
      } else {
        console.error("Unexpected response format:", response.data);
        setTagNo([]);
      }
    } catch (error) {
      console.error("Error fetching TagNo:", error.message);
    }
  };

  useEffect(() => {
    fetchTagNo();
  }, []);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setStatutoryData({ ...statutoryData, [name]: value });
  //   // Clear validation error when input changes
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update statutoryData state with the new value
    setStatutoryData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear the error message for this field
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleCancelClick = () => {
    // navigate("/statutory-compliance");
    window.history.back();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSaveClick = async () => {
    const newErrors = {};
    if (!statutoryData.department_name)
      newErrors.department_name = "Department is required";
    if (!statutoryData.equipment_type)
      newErrors.equipment_type = "Equipment Type is required";
    if (!statutoryData.tag_no) newErrors.tag_no = "Tag No is required";
    if (!selectedDate) newErrors.date = "Date is required";
    if (!statutoryData.description)
      newErrors.description = "Description is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Prevent form submission
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
      const payload = {
        ...statutoryData,
        date: selectedDate ? format(selectedDate, "yyyy-MM-dd") : "",
      };
      const response = await axios.put(
        `${API_URL}/api/update_statutory_compliance/${id}`,
        payload,
        {
          headers: {
            Authorization: authKey,
          },
        }
      );
      console.log(response.data.message);
      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Data updated successfully");
        setTimeout(() => {
          navigate("/statutory-compliance");
        }, 1000);
      }
    } catch (error) {
      console.error("Error updating compliance:", error.message);
      setError("An error occurred while updating compliance data");
      setIsLoading(false);
    }
  };

  const handleBackProject = () => {
    navigate("/statutory-compliance");
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setStatutoryData((prevState) => ({
      ...prevState,
      date: date,
    }));
    setErrors((prevState) => ({
      ...prevState,
      date: "", // Clear the error message for the 'date' field
    }));
  };

  const handleTagNoChange = (selectedOption) => {
    setSelectedTagNo(selectedOption);
    setStatutoryData((prevState) => ({
      ...prevState,
      tag_no: selectedOption.value, // Assuming selectedOption.value is the TagNo value
    }));
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setStatutoryData((prevFormData) => ({
      ...prevFormData,
      department_id: selectedOption ? selectedOption.value : "",
      department_name: selectedOption ? selectedOption.label : "",
    }));
    setErrors((prevState) => ({
      ...prevState,
      department_name: "", // Clear the error message for the 'date' field
    }));
  };

  const fetchDepartment = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_departments`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const options = data.map((department) => ({
          label: department.DepartmentName,
          value: department.ID,
        }));
        setDepartments(options);
      } else {
        console.error("Failed to fetch departments");
      }
    } catch (error) {
      console.error("An error occurred while fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchDepartment();
  }, []);
  const handleDepartmentMenuOpen = () => {
    setDepartmentMenuIsOpen(true);
  };
  const fetchEquipmentType = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_equipment_types`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });

      if (response.ok) {
        const data = await response.json();
        // Map the API response to the format expected by react-select
        const options = data.map((equipmentType) => ({
          label: equipmentType.EquipmentType,
          value: equipmentType.id,
        }));
        setEquipmentTypes(options);
      } else {
        console.error("Failed to fetch Equipment Type");
      }
    } catch (error) {
      console.error("An error occurred while fetching Equipment Type:", error);
    }
  };

  useEffect(() => {
    fetchEquipmentType();
  }, []);
  return (
    <>
      <div
        className={`data-edit-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-edit-form-content">
          <div className="data-edit-form-header">
            <div className="data-edit-form-header-content">
              <div className="data-edit-form-heading-content-left-side">
                <Tooltip title="Back" arrow>
                  <IoMdArrowBack
                    className="back-company"
                    onClick={handleBackProject}
                  />
                </Tooltip>
                Edit Statutory Compliance
              </div>
              <div className="data-edit-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-edit-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="new-data-form">
                <div className="form-group-select">
                  <label htmlFor="department_name">
                    Department<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="department"
                    options={departments}
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    // components={{ Control: CustomControl, Menu: CustomMenu }}
                    placeholder="Select Department"
                    isSearchable
                    menuIsOpen={departmentMenuIsOpen}
                    onMenuOpen={handleDepartmentMenuOpen}
                    onMenuClose={() => setDepartmentMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                        border: `1px solid ${
                          theme === "dark" ? "#444" : "#ccc"
                        }`, // Border for the menu
                        borderRadius: "4px", // Optional: add border radius for rounded edges
                        zIndex: 1000, // Ensures the menu appears above other elements
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.department_name && (
                    <p className="error-message">{errors.department_name}</p>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="equipment_type">
                    Equipment type<span className="text-danger">*</span>
                  </label>
                  {/* <div className="input-group">
                    <input
                      type="text"
                      name="equipment_type"
                      value={statutoryData.equipment_type}
                      onChange={handleInputChange}
                      placeholder="Enter equipment type"
                    />
                  </div> */}
                  <Select
                    id="equipment_type"
                    options={equipmentTypes}
                    value={selectedEquipmentTypes}
                    onChange={handleEquipmentTypeChange}
                    placeholder="Select Equipment Type"
                    isSearchable
                    menuIsOpen={EquipmentTypeMenuIsOpen}
                    onMenuOpen={handleEquipmentTypeMenuOpen}
                    onMenuClose={() => setEquipmentTypeMenuIsOpen(false)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                        border: `1px solid ${
                          theme === "dark" ? "#444" : "#ccc"
                        }`, // Border for the menu
                        borderRadius: "4px", // Optional: add border radius for rounded edges
                        zIndex: 1000, // Ensures the menu appears above other elements
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.equipment_type && (
                    <p className="error-message">{errors.equipment_type}</p>
                  )}
                </div>
                <div className="form-group-select">
                  <label htmlFor="tag_no">
                    Tag No.<span className="text-danger">*</span>
                  </label>

                  <Select
                    id="tag_no"
                    options={tagNo}
                    value={selectedTagNo}
                    onChange={handleTagNoChange}
                    placeholder="Select tag no"
                    isSearchable
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "black" : "white", // Dropdown background color
                        color: theme === "dark" ? "#fff" : "#333", // Text color
                        borderColor: theme === "dark" ? "#444" : "#ccc", // Border color
                        cursor: "pointer",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: theme === "dark" ? "#666" : "#888", // Border color on hover
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                        border: `1px solid ${
                          theme === "dark" ? "#444" : "#ccc"
                        }`, // Border for the menu
                        borderRadius: "4px", // Optional: add border radius for rounded edges
                        zIndex: 1000, // Ensures the menu appears above other elements
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#666" // Gray when hovered
                          : theme === "dark"
                          ? "#000"
                          : "#fff", // Black background for options in dark mode
                        color: state.isFocused
                          ? "#fff"
                          : theme === "dark"
                          ? "#fff"
                          : "#333", // Text color
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                      }),
                    }}
                  />
                  {errors.tag_no && (
                    <p className="error-message">{errors.tag_no}</p>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="date">
                    Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      name="date"
                      id="date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select date"
                      className="form-control"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors.date && (
                    <p className="error-message">{errors.date}</p>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="description">
                    Statutory Description<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      name="description"
                      rows={4}
                      value={statutoryData.description}
                      onChange={handleInputChange}
                      placeholder=" Statutory Description"
                    />
                  </div>
                  {errors.description && (
                    <p className="error-message">{errors.description}</p>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-edit-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-edit-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default EditStatutoryCompliance;
