import React, { useState, useEffect, useRef } from "react";
import "./UserForm.css";
import "./NewEquipmentForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "../Responsive/TableResponsive.css";

import API_URL from "../../src/Config";
import Select, { components } from "react-select";
import { CiCirclePlus } from "react-icons/ci";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import AddSubTypeVariant from "../Popups/AddSubTypeVariant";
import { PiArrowElbowDownRightFill } from "react-icons/pi";
import { TbPointFilled } from "react-icons/tb";
import { GiGearHammer } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { format } from "date-fns";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
import { MdAttachFile } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { FiUpload } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
const { Control, Menu } = components;

function NewEquipmentForm({ isSidebarExpanded }) {
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedMaintanceDate, setSelectedMaintanceDate] = useState(null);
  const [selectedWarrantyDate, setselectedWarrantyDate] = useState(null);
  const [currentOpenPopup, setCurrentOpenPopup] = useState(null);
  const [selectedInstallationDate, setSelectedInstallationDate] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [attachmentFile, setAttachmentFile] = useState(null);
  // const [selectedFiles, setSelectedFiles] = useState([]);
  const [frequencyData, setFrequencyData] = useState([]);
  const fileInputRef = useRef(null);
  const userFileInputRef = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedUserFiles, setSelectedUserFiles] = useState([]);
  const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [attachmentUserFile, setAttachmentUserFile] = useState(null);
  const [selectedEquipmentSubType, setSelectedEquipmentSubType] =
    useState(null);
  const [tagNoError, setTagNoError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [showFrequencyButton, setShowFrequencyButton] = useState(false);
  const [maintenanceDateError, setMaintenanceDateError] = useState("");
  const [equipmentSubTypeVariants, setEquipmentTypeVariants] = useState([]);
  const [selectedEquipmentSubTypeVariants, setSelectEdequipmentTypeVariants] =
    useState(null);
  const [
    EquipmentSubTypeVariantsMenuIsOpen,
    setEquipmentSubTypeVariantsMenuIsOpen,
  ] = useState(false);
  
  const [frequencyPopup, setFrequencyPopup] = useState(false);
  const [showSubTypeVariantPopup, setShowSubTypeVariantPopup] = useState(false);
  const [offerDocumentError, setOfferDocumentError] = useState("");
  const [equipmentData, setEquipmentData] = useState({
    DepartmentId: "",
    DepartmentName: "",
    EquipmentTypeId: "",
    SubDepartmentName: "",
    SubDepartmentId: "",
    EquipmentType: "",
    EquipmentSubTypeId: "",
    EquipmentSubType: "",
    VariantNameId: "",
    SubTypeVariant: "",
    TagNo: "",
    Attachment: "",
    Make: "",
    Model: "",
    frequency_id: "",
    InstallationYear: null,
    WarrantyExp: "",
    LastMaintenanceDate: "",
    Location: "",
    Description: "",
  });
  const { theme } = useContext(ThemeContext);
  const handleFrequencyDetail = (event) => {
    event.preventDefault(); // Prevent form submission
    setFrequencyPopup(!frequencyPopup); // Toggle popup visibility
  };
  const categorizeFrequencies = (frequencies) => {
    const categorized = {
      oisd: [],
      factory_act: [],
      oem: [],
      peso: [],
    };

    frequencies.forEach((item) => {
      if (item.frequency_type === "oisd") {
        categorized.oisd.push(item);
      } else if (item.frequency_type === "factory_act") {
        categorized.factory_act.push(item);
      } else if (item.frequency_type === "oem") {
        categorized.oem.push(item);
      } else if (item.frequency_type === "peso") {
        categorized.peso.push(item);
      }
    });

    return categorized;
  };

  // Categorize frequencies
  const categorizedFrequencies = selectedEquipmentSubTypeVariants?.Frequencies
    ? categorizeFrequencies(selectedEquipmentSubTypeVariants.Frequencies)
    : {};

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEquipmentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


    const handleUploadButtonClick = () => {
    // Ensure the ref is not null before triggering the click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };

  // const handleFileInputChange = (e) => {
  //   const files = e.target.files;
  //   const newFileDetails = Array.from(files).map((file) => ({
  //     name: file.name,
  //     size: file.size,
  //   }));

  //   setSelectedFiles([...selectedFiles, ...newFileDetails]);

  //   // Set the actual file objects in the state for FormData
  //   setAttachmentFile((prevFiles) => [
  //     ...(prevFiles || []),
  //     ...Array.from(files),
  //   ]);
  // };
  const handleFileInputChange = (files, setFiles) => {
    const fileArray = Array.from(files);
    setFiles(fileArray);
  };
  const handleIconClick = () => {
    setIsPopupOpen((prev) => !prev); // Toggle popup on button click
  };
  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };


  const handleUserUploadButtonClick = () => {
    // Ensure the ref is not null before triggering the click
    if (userFileInputRef.current) {
      userFileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };

  const handleUserFileInputChange = (e) => {
    const files = e.target.files;
    const newFileDetails = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));

    setSelectedUserFiles([...selectedUserFiles, ...newFileDetails]);

    // Set the actual file objects in the state for FormData
    setAttachmentUserFile((prevFiles) => [
      ...(prevFiles || []),
      ...Array.from(files),
    ]);
  };
  const handleUserIconClick = () => {
    setIsUserPopupOpen((prev) => !prev); // Toggle popup on button click
  };
  const handleUserCloseAttachment = () => {
    setIsUserPopupOpen(false);
  };

  const handleUserDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedUserFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedUserFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedUserFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleUserCloseAttachment(); // Close the popup if no files remain
    }
  };

  const handleMaintanaceDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setSelectedMaintanceDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        LastMaintenanceDate: formattedDate,
      }));
    }
  };

  const handleInstallationDateChange = (date) => {
    if (date) {
      const year = date.getFullYear();
      setSelectedInstallationDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        InstallationYear: year,
      }));
    }
  };

  const handleWarrantyDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().slice(0, 10);
      setselectedWarrantyDate(date);
      setEquipmentData((prevFormData) => ({
        ...prevFormData,
        WarrantyExp: formattedDate,
      }));
    }
  };

  const handleCancelClick = () => {
    navigate("/new-equipment");
  };

  // const handleSaveClick = async () => {
  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const formData = new FormData();
  //     formData.append("DepartmentId", equipmentData.DepartmentId);
  //     formData.append("DepartmentName", equipmentData.DepartmentName);
  //     formData.append("EquipmentTypeId", equipmentData.EquipmentTypeId);
  //     formData.append("SubDepartmentId", equipmentData.SubDepartmentId);
  //     formData.append("SubDepartmentName", equipmentData.SubDepartmentName);
  //     formData.append("EquipmentType", equipmentData.EquipmentType);
  //     formData.append("EquipmentSubTypeId", equipmentData.EquipmentSubTypeId);
  //     formData.append("EquipmentSubType", equipmentData.EquipmentSubType);
  //     formData.append("VariantNameId", equipmentData.VariantNameId);
  //     formData.append("SubTypeVariant", equipmentData.SubTypeVariant);
  //     formData.append("TagNo", equipmentData.TagNo);
  //     formData.append("Category", equipmentData.Category);
  //     formData.append("Make", equipmentData.Make);
  //     formData.append("Model", equipmentData.Model);
  //     formData.append("InstallationYear", equipmentData.InstallationYear);
  //     formData.append("WarrantyExp", equipmentData.WarrantyExp);
  //     formData.append("FrequencyOfMaintenance", equipmentData.FrequencyOfMaintenance);
  //     formData.append("LastMaintenanceDate", equipmentData.LastMaintenanceDate);
  //     formData.append("Location", equipmentData.Location);
  //     formData.append("Description", equipmentData.Description);

  //     // Append each file to the form data
  //     // attachmentFile.forEach((file) => {
  //     //   formData.append("Datasheet", file);
  //     // });

  //     // Assuming frequency is an array of objects
  //     formData.append("Frequency", JSON.stringify(equipmentData.Frequency));

  //     const response = await axios.post(
  //       `${API_URL}/api/add_equipment`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     console.log(response.data.message);

  //     if (response.status === 200) {
  //       showSnackbar("Equipment added successfully");
  //       setTimeout(() => {
  //         navigate("/new-equipment");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 400) {
  //       showSnackbar(
  //         "TagNo already exists. Please use a different TagNo.",
  //         "error"
  //       );
  //     } else {
  //       console.error("Error saving equipment:", error.message);
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  // const handleSaveClick = async () => {
  //   // Reset error messages
  //   setTagNoError("");
  //   setCategoryError("");
  //   setMaintenanceDateError("");

  //   // Validate required fields
  //   let isValid = true;

  //   if (!equipmentData.TagNo) {
  //     setTagNoError("Tag No is required");
  //     isValid = false;
  //   }

  //   if (!selectedEquipmentSubTypeVariants) {
  //     setCategoryError("Category is required");
  //     isValid = false;
  //   }

  //   if (!selectedMaintanceDate) {
  //     setMaintenanceDateError("Last Maintenance Date is required");
  //     isValid = false;
  //   }

  //   if (!isValid) {
  //     return;
  //   }

  //   try {
  //     setIsLoading(true);
  //     const authKey = localStorage.getItem("authKey");

  //     const formData = new FormData();
  //     formData.append("DepartmentId", equipmentData.DepartmentId);
  //     formData.append("DepartmentName", equipmentData.DepartmentName);
  //     formData.append("EquipmentTypeId", equipmentData.EquipmentTypeId);
  //     formData.append("SubDepartmentId", equipmentData.SubDepartmentId);
  //     formData.append("SubDepartmentName", equipmentData.SubDepartmentName);
  //     formData.append("EquipmentType", equipmentData.EquipmentType);
  //     formData.append("EquipmentSubTypeId", equipmentData.EquipmentSubTypeId);
  //     formData.append("EquipmentSubType", equipmentData.EquipmentSubType);
  //     formData.append("VariantNameId", equipmentData.VariantNameId);
  //     formData.append("SubTypeVariant", equipmentData.SubTypeVariant);
  //     formData.append("TagNo", equipmentData.TagNo);
  //     formData.append("Category", equipmentData.Category);
  //     formData.append("Make", equipmentData.Make);
  //     formData.append("Model", equipmentData.Model);
  //     formData.append("InstallationYear", equipmentData.InstallationYear);
  //     formData.append(
  //       "WarrantyExp",
  //       selectedWarrantyDate
  //         ? format(new Date(selectedWarrantyDate), "yyyy-MM-dd")
  //         : null
  //     );
  //     formData.append(
  //       "LastMaintenanceDate",
  //       selectedMaintanceDate
  //         ? format(new Date(selectedMaintanceDate), "yyyy-MM-dd")
  //         : null
  //     );
  //     formData.append("Location", equipmentData.Location);
  //     formData.append("Description", equipmentData.Description);
  //     formData.append("frequency_id", equipmentData.frequency_id);


  //     const response = await axios.post(
  //       `${API_URL}/api/add_equipment`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: authKey,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     console.log(response.data.message);

  //     if (response.status === 200) {
  //       showSnackbar("Equipment added successfully");
  //       setTimeout(() => {
  //         navigate("/new-equipment");
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 400) {
  //       showSnackbar(
  //         "TagNo already exists. Please use a different TagNo.",
  //         "error"
  //       );
  //     } else {
  //       console.error("Error saving equipment:", error.message);
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSaveClick = async () => {
    // Reset error messages
    setTagNoError("");
    setCategoryError("");
    setMaintenanceDateError("");
  
    // Validate required fields
    let isValid = true;
  
    if (!equipmentData.TagNo) {
      setTagNoError("Tag No is required");
      isValid = false;
    }
  
    if (!selectedEquipmentSubTypeVariants) {
      setCategoryError("Category is required");
      isValid = false;
    }
  
    if (!selectedMaintanceDate) {
      setMaintenanceDateError("Last Maintenance Date is required");
      isValid = false;
    }
  
    if (!isValid) {
      return;
    }
  
    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");
  
      const formData = new FormData();
      formData.append("DepartmentId", equipmentData.DepartmentId);
      formData.append("DepartmentName", equipmentData.DepartmentName);
      formData.append("EquipmentTypeId", equipmentData.EquipmentTypeId);
      formData.append("SubDepartmentId", equipmentData.SubDepartmentId);
      formData.append("SubDepartmentName", equipmentData.SubDepartmentName);
      formData.append("EquipmentType", equipmentData.EquipmentType);
      formData.append("EquipmentSubTypeId", equipmentData.EquipmentSubTypeId);
      formData.append("EquipmentSubType", equipmentData.EquipmentSubType);
      formData.append("VariantNameId", equipmentData.VariantNameId);
      formData.append("SubTypeVariant", equipmentData.SubTypeVariant);
      formData.append("TagNo", equipmentData.TagNo);
      formData.append("Category", equipmentData.Category);
      formData.append("Make", equipmentData.Make);
      formData.append("Model", equipmentData.Model);
      formData.append("InstallationYear", equipmentData.InstallationYear);
      formData.append(
        "WarrantyExp",
        selectedWarrantyDate ? format(new Date(selectedWarrantyDate), "yyyy-MM-dd") : null
      );
      formData.append(
        "LastMaintenanceDate",
        selectedMaintanceDate ? format(new Date(selectedMaintanceDate), "yyyy-MM-dd") : null
      );
      formData.append("Location", equipmentData.Location);
      formData.append("Description", equipmentData.Description);
      formData.append("frequency_id", equipmentData.frequency_id);
  
      // Append files correctly as binary (File objects)
      selectedFiles.forEach((file) => {
        // Ensure you're appending the actual file objects
        formData.append("pr_copy", file);
      });
  
      selectedUserFiles.forEach((file) => {
        formData.append("user_manual", file);
      });
  
      // Send formData in the request
      const response = await axios.post(
        `${API_URL}/api/add_equipment`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      console.log(response.data.message);
  
      if (response.status === 200) {
        showSnackbar("Equipment added successfully");
        setTimeout(() => {
          navigate("/new-equipment");
        }, 1000);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        showSnackbar("TagNo already exists. Please use a different TagNo.", "error");
      } else {
        console.error("Error saving equipment:", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleEquipmentSubTypeVariantMenuOpen = () => {
    setEquipmentSubTypeVariantsMenuIsOpen(true);
    setCurrentOpenPopup("equipmentSubTypeVariants");
  };

  const CustomVariantControl = ({ children, ...props }) => (
    <Control {...props}>{children}</Control>
  );
  const CustomVariantMenu = ({ children, ...props }) => (
    <Menu {...props}>
      {children}
      <button
        type="button"
        className="add-company-button"
        onClick={handleAddNewTypeVariant}
      >
        <CiCirclePlus className="new-plus-circle" />
        Add Frequency Configuration
      </button>
    </Menu>
  );

  const handleAddNewTypeVariant = () => {
    // setShowSubTypeVariantPopup(true);
    navigate("/frequency-config");
    setEquipmentSubTypeVariantsMenuIsOpen(false);
  };

  const closeEquipmentTypeVariantPopup = () => {
    setShowSubTypeVariantPopup(false);
  };

  const handleTypeSubTypeVariantsChange = (selectedOption) => {
    setSelectEdequipmentTypeVariants(selectedOption);
    const {
      DepartmentName,
      SubDepartmentName,
      VariantName,
      EquipmentSubType,
      EquipmentType,
      frequency,
      id,
    } = selectedOption;

    // Determine the category based on the availability of different properties
    let category = "";
    if (VariantName) {
      category = VariantName;
    } else if (EquipmentSubType) {
      category = EquipmentSubType;
    } else if (EquipmentType) {
      category = EquipmentType;
    } else if (SubDepartmentName) {
      category = SubDepartmentName;
    } else if (DepartmentName) {
      category = DepartmentName;
    }

    setEquipmentData((prevFormData) => ({
      ...prevFormData,
      Category: category,
      SubTypeVariant: selectedOption.SubTypeVariant,
      VariantNameId: selectedOption.variant_id,
      DepartmentId: selectedOption.department_id,
      DepartmentName: selectedOption.DepartmentName,
      EquipmentTypeId: selectedOption.equipment_type_id,
      SubDepartmentId: selectedOption.sub_department_id,
      SubDepartmentName: selectedOption.SubDepartmentName,
      EquipmentType: selectedOption.EquipmentType,
      EquipmentSubTypeId: selectedOption.equipment_sub_type_id,
      EquipmentSubType: selectedOption.EquipmentSubType,
      Frequency: frequency, // Update Frequency field
      frequency_id: id,
    }));

    setFrequencyData(frequency);
    setShowFrequencyButton(true);
  };

  const fetchEquipmentSubTypeVariants = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/get_frequency_configurations`;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
      });
      if (response.ok) {
        const data = await response.json();
        // Directly set the data in state as it is
        setFrequencyData(data);

        const options = data.frequency_configs.map(
          (equipmentSubTypeVariant) => {
            const {
              DepartmentName,
              SubDepartmentName,
              EquipmentType,
              EquipmentSubType,
              VariantName,
              id,
              department_id,
              sub_department_id,
              equipment_type_id,
              equipment_sub_type_id,
              variant_id,
              frequency,
              Frequencies, // Added this to make sure frequencies are included
            } = equipmentSubTypeVariant;
            const labelParts = [
              DepartmentName,
              SubDepartmentName,
              EquipmentType,
              EquipmentSubType,
              VariantName,
            ];
            const label = labelParts.filter((part) => part).join(" >> ");
            return {
              label,
              value: id,
              variant_id,
              id,
              frequency,
              DepartmentName,
              SubDepartmentName,
              EquipmentType,
              EquipmentSubType,
              department_id,
              VariantName,
              equipment_type_id,
              sub_department_id,
              equipment_sub_type_id,
              Frequencies, // Added this to make sure frequencies are included
            };
          }
        );
        setEquipmentTypeVariants(options);
      } else {
        console.error("Failed to fetch Equipment Sub Type Variants");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching Equipment Sub Type Variants:",
        error
      );
    }
  };

  useEffect(() => {
    fetchEquipmentSubTypeVariants();
  }, []);

  const handleNewEquipmentSubTypeVariant = (newSubTypeVariant) => {
    setEquipmentTypeVariants((prevEquipmentTypeVariants) => [
      ...prevEquipmentTypeVariants,
      newSubTypeVariant,
    ]);
    setSelectEdequipmentTypeVariants(newSubTypeVariant);
    setEquipmentData((prevEquipmentTypeVariants) => ({
      ...prevEquipmentTypeVariants,
      SubTypeVariant: newSubTypeVariant.label,
      VariantNameId: newSubTypeVariant.value,
    }));
    showSnackbar("Equipment sub type variant added successsfully");
  };

  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content">
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Equipment
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail" onKeyDown={handleKeyDown}>
              <div className="newperson-user-form">
                <div className="form-header-seperator">
                  <div className="form-header-seperator-content equip">
                    <div className="equipment-image equip1">
                      <div className="box">
                        <GiGearHammer />
                      </div>
                    </div>
                    <div className="equipment-image equip2">
                      <div className="form-group-top-select">
                        <label htmlFor="ContactNo">
                          Category <span className="text-danger">*</span>
                        </label>

                        <Select
                          id="EquipmentSubTypeVariant"
                          options={equipmentSubTypeVariants}
                          value={selectedEquipmentSubTypeVariants}
                          onChange={handleTypeSubTypeVariantsChange}
                          components={{
                            Control: CustomVariantControl,
                            Menu: CustomVariantMenu,
                          }}
                          placeholder="Select category "
                          isSearchable
                          menuIsOpen={EquipmentSubTypeVariantsMenuIsOpen}
                          onMenuOpen={handleEquipmentSubTypeVariantMenuOpen}
                          onMenuClose={() =>
                            setEquipmentSubTypeVariantsMenuIsOpen(false)
                          }
                          styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor:
                                      theme === "dark" ? "black" : "white", // Dropdown background color
                                    color: theme === "dark" ? "#fff" : "#333", // Text color
                                    borderColor:
                                      theme === "dark" ? "#444" : "#ccc", // Border color
                                    cursor: "pointer",
                                    boxShadow: "none",
                                    "&:hover": {
                                      borderColor:
                                        theme === "dark" ? "#666" : "#888", // Border color on hover
                                    },
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    backgroundColor:
                                      theme === "dark" ? "#000" : "#fff", // Black background for dropdown menu in dark mode
                                    border: `1px solid ${
                                      theme === "dark" ? "#444" : "#ccc"
                                    }`, // Border for the menu
                                    borderRadius: "4px", // Optional: add border radius for rounded edges
                                    zIndex: 1000, // Ensures the menu appears above other elements
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused
                                      ? "#666" // Gray when hovered
                                      : theme === "dark"
                                      ? "#000"
                                      : "#fff", // Black background for options in dark mode
                                    color: state.isFocused
                                      ? "#fff"
                                      : theme === "dark"
                                      ? "#fff"
                                      : "#333", // Text color
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                                  }),
                                }}
                        />
                        {showFrequencyButton && (
                          <button
                            type="button"
                            className="frequency-button"
                            onClick={handleFrequencyDetail}
                          >
                            Maintenance Frequency Details
                          </button>
                        )}
                        {categoryError && (
                          <p className="error-message">{categoryError}</p>
                        )}
                      </div>

                      <div className="form-group-top">
                        <label htmlFor="TagNo">
                          Tag No.<span className="text-danger">*</span>
                        </label>
                        <div className="input-group-top">
                          <input
                            type="text"
                            name="TagNo"
                            onChange={handleInputChange}
                            placeholder="Enter tag no"
                          />
                        </div>
                      </div>
                      {tagNoError && (
                        <p className="error-message">{tagNoError}</p>
                      )}
                    </div>
                    {/* {showFrequencyButton && (
                    <button type="button" className="frequency-button" onClick={handleFrequencyDetail}>
                      Maintenance Frequency Details
                    </button>
                    )} */}
                    {/* {selectedEquipmentSubTypeVariants && (
                      <div className="selected-category equip3">
                        <div className="equip3-box">
                          <>
                            <div className="equip-category">
                              {selectedEquipmentSubTypeVariants.DepartmentName && (
                                <div className="parent">
                                  {
                                    selectedEquipmentSubTypeVariants.DepartmentName
                                  }
                                  <div className="equip-icon">
                                    <TbPointFilled />
                                  </div>
                                </div>
                              )}
                              {selectedEquipmentSubTypeVariants.SubDepartmentName && (
                                <div
                                  className={`child ${!selectedEquipmentSubTypeVariants.EquipmentType &&
                                    !selectedEquipmentSubTypeVariants.EquipmentSubType &&
                                    !selectedEquipmentSubTypeVariants.VariantName
                                    ? "bold-text"
                                    : ""
                                    }`}
                                >
                                  {
                                    selectedEquipmentSubTypeVariants.SubDepartmentName
                                  }
                                  <div className="equip-icon">
                                    <PiArrowElbowDownRightFill />
                                  </div>
                                </div>
                              )}
                              {selectedEquipmentSubTypeVariants.EquipmentType && (
                                <div
                                  className={`child ${!selectedEquipmentSubTypeVariants.EquipmentSubType &&
                                    !selectedEquipmentSubTypeVariants.VariantName
                                    ? "bold-text"
                                    : ""
                                    }`}
                                >
                                  {
                                    selectedEquipmentSubTypeVariants.EquipmentType
                                  }
                                  <div className="equip-icon">
                                    <PiArrowElbowDownRightFill />
                                  </div>
                                </div>
                              )}
                              {selectedEquipmentSubTypeVariants.EquipmentSubType && (
                                <div
                                  className={`child ${!selectedEquipmentSubTypeVariants.VariantName
                                    ? "bold-text"
                                    : ""
                                    }`}
                                >
                                  {
                                    selectedEquipmentSubTypeVariants.EquipmentSubType
                                  }
                                  <div className="equip-icon">
                                    <PiArrowElbowDownRightFill />
                                  </div>
                                </div>
                              )}
                              {selectedEquipmentSubTypeVariants.VariantName && (
                                <div className="child bold-text">
                                  {selectedEquipmentSubTypeVariants.VariantName}
                                  <div className="equip-icon">
                                    <PiArrowElbowDownRightFill />
                                  </div>
                                </div>
                              )}
                            </div>
                          </>

                          {selectedEquipmentSubTypeVariants && (
                            <div className="frequency-table-container">
                              <table className="frequency-table">
                                <thead>
                                  <tr>
                                    <th>OISD Point</th>
                                    <th>Frequency (In days)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedEquipmentSubTypeVariants.Frequencies && selectedEquipmentSubTypeVariants.Frequencies.length > 0 ? (
                                    selectedEquipmentSubTypeVariants.Frequencies.map((item, index) => (
                                      <tr key={index}>
                                        <td>{item.oisd_point}</td>
                                        <td>{item.frequency}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="2">No data available</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>

                <div className="form-seperator">
                  <div className="form-seperator-content">
                    Asset Information
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Make">Make</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Make"
                      onChange={handleInputChange}
                      placeholder="Enter make"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Model">Model</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Model"
                      onChange={handleInputChange}
                      placeholder="Enter model"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Installation">Year Of Installation</label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedInstallationDate}
                      onChange={handleInstallationDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="yyyy"
                      placeholderText="Select year"
                      className="datesss"
                      isClearable={false}
                      showYearPicker
                      // onKeyDown={(e) => {
                      //   e.preventDefault();
                      // }}
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          setSelectedInstallationDate(null); // Clear date on backspace
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="Warranty">Warranty Expiry</label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedWarrantyDate}
                      onChange={handleWarrantyDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a expiry date"
                      className="datesss"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      // onKeyDown={(e) => {
                      //   e.preventDefault();
                      // }}
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") {
                          setselectedWarrantyDate(null); // Clear date on backspace
                        }
                      }}
                    />
                  </div>
                </div>

                {/* <div className="form-group">
                  <label htmlFor="FrequencyOfMaintenance">
                    Frequency of Maintenance
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="FrequencyOfMaintenance"
                      onChange={handleInputChange}
                      placeholder="Enter frequency"
                    />
                  </div>
                </div> */}

                <div className="form-group">
                  <label htmlFor="Maintenance_Date">
                    Last Maintenance Date<span className="text-danger">*</span>
                  </label>
                  <div className="input-group-datepicker">
                    <DatePicker
                      selected={selectedMaintanceDate}
                      onChange={handleMaintanaceDateChange}
                      name="date_of_seizure"
                      id="date_of_seizure"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a maintenance date"
                      className="datesss"
                      isClearable
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      todayButton="Today"
                      maxDate={new Date()} // Set the max date to today
                    />
                  </div>
                  {maintenanceDateError && (
                    <p className="error-message">{maintenanceDateError}</p>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="Location">Location</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="Location"
                      onChange={handleInputChange}
                      placeholder="Enter location"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="Description">Description</label>
                  <div className="input-group">
                    <textarea
                      name="Description"
                      id="Description"
                      cols="35"
                      rows="3"
                      placeholder="Enter description"
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="attachement">
                    PR Copy{" "}
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={handleUploadButtonClick}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="attachments"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    // onChange={handleFileInputChange}
                    onChange={(e) =>
                      handleFileInputChange(
                        e.target.files,
                        setSelectedFiles
                      )
                    }
                    multiple
                  />
                  {selectedFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleIconClick}
                      ref={buttonRef}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedFiles.length}`}
                    </span>
                  )}
                  {isPopupOpen && (
                    <div className="attach-popup-amc4" ref={containerRef}>
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="attachement">
                  User Manual{" "}
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <button
                    type="button"
                    className="challan-btn"
                    onClick={handleUserUploadButtonClick}
                  >
                    <FiUpload /> Upload File
                  </button>
                  <input
                    id="attachments"
                    type="file"
                    ref={userFileInputRef}
                    style={{ display: "none" }}
                    // onChange={handleUserFileInputChange}
                    onChange={(e) =>
                      handleFileInputChange(
                        e.target.files,
                        setSelectedUserFiles
                      )
                    }
                    multiple
                  />
                  {selectedUserFiles.length > 0 && (
                    <span
                      className="inquiry-file-btn"
                      onClick={handleUserIconClick}
                      ref={buttonRef}
                    >
                      <MdAttachFile className="inquiry-file-icon" />{" "}
                      {`${selectedUserFiles.length}`}
                    </span>
                  )}
                  {isUserPopupOpen && (
                    <div className="attach-popup-amc4" ref={containerRef}>
                      <div className="attach-popup-contant">
                        <div className="attachment-header">
                          <div className="attachment-header-content">
                            Attachments
                          </div>
                          <button
                            className="fiile-close-btn"
                            onClick={handleUserCloseAttachment}
                          >
                            <IoCloseOutline className="file-close-icon" />
                          </button>
                        </div>
                        {selectedUserFiles.length > 0 && (
                          <div className="attach-scrroll-body">
                            {selectedUserFiles.map((file, index) => (
                              <div
                                className="attach-popup-contant-body"
                                key={index}
                              >
                                <div className="attach-file-container">
                                  <div className="attach-file-show">
                                    <div className="text-attachment">
                                      {file.name}
                                    </div>
                                    <div className="text-attachment-file">
                                      File Size:(
                                      {(file.size / 1024).toFixed(2)} KB)
                                    </div>
                                  </div>
                                  <div
                                    className="file-delete-btn"
                                    onClick={() => handleUserDeleteFile(index)}
                                  >
                                    <span>
                                      {" "}
                                      <MdDeleteOutline className="file-delete-icon" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>

            {showSubTypeVariantPopup && (
              <div className="inquiry-customerperson-popup">
                <AddSubTypeVariant
                  closePopup={closeEquipmentTypeVariantPopup}
                  setSelectEdequipmentTypeVariants={
                    selectedEquipmentSubTypeVariants
                  }
                  handleNewEquipmentSubTypeVariant={
                    handleNewEquipmentSubTypeVariant
                  }
                  parentSelectedEquipmentSubType={selectedEquipmentSubType}
                />
              </div>
            )}
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
          {frequencyPopup && (
            <div className="frequency-popup">
              <div className="frequency-popup-content">
                <div className="frequency-popup-content-header">
                  <div className="frequency-detail">Frequency Details:</div>
                  <div className="frequency-popup-contentss">
                    <button
                      onClick={handleFrequencyDetail}
                      className="cross-button"
                    >
                      <RxCross2 className="cross-icon" />
                    </button>
                  </div>
                </div>
                <div className="header-separator"></div>
                {selectedEquipmentSubTypeVariants && (
                  <div className="selected-category equip3">
                    <div className="equip3-boxs">
                      <>
                        <div className="equip-categorys">
                          <div className="equip-categorysss"> Category: </div>
                          <div className="equip-category">
                            {selectedEquipmentSubTypeVariants.DepartmentName && (
                              <div className="parent">
                                {
                                  selectedEquipmentSubTypeVariants.DepartmentName
                                }
                                <div className="equip-icon">
                                  <TbPointFilled />
                                </div>
                              </div>
                            )}
                            {selectedEquipmentSubTypeVariants.SubDepartmentName && (
                              <div
                                className={`child ${
                                  !selectedEquipmentSubTypeVariants.EquipmentType &&
                                  !selectedEquipmentSubTypeVariants.EquipmentSubType &&
                                  !selectedEquipmentSubTypeVariants.VariantName
                                    ? "bold-text"
                                    : ""
                                }`}
                              >
                                {
                                  selectedEquipmentSubTypeVariants.SubDepartmentName
                                }
                                <div className="equip-icon">
                                  <PiArrowElbowDownRightFill />
                                </div>
                              </div>
                            )}
                            {selectedEquipmentSubTypeVariants.EquipmentType && (
                              <div
                                className={`child ${
                                  !selectedEquipmentSubTypeVariants.EquipmentSubType &&
                                  !selectedEquipmentSubTypeVariants.VariantName
                                    ? "bold-text"
                                    : ""
                                }`}
                              >
                                {selectedEquipmentSubTypeVariants.EquipmentType}
                                <div className="equip-icon">
                                  <PiArrowElbowDownRightFill />
                                </div>
                              </div>
                            )}
                            {selectedEquipmentSubTypeVariants.EquipmentSubType && (
                              <div
                                className={`child ${
                                  !selectedEquipmentSubTypeVariants.VariantName
                                    ? "bold-text"
                                    : ""
                                }`}
                              >
                                {
                                  selectedEquipmentSubTypeVariants.EquipmentSubType
                                }
                                <div className="equip-icon">
                                  <PiArrowElbowDownRightFill />
                                </div>
                              </div>
                            )}
                            {selectedEquipmentSubTypeVariants.VariantName && (
                              <div className="child bold-text">
                                {selectedEquipmentSubTypeVariants.VariantName}
                                <div className="equip-icon">
                                  <PiArrowElbowDownRightFill />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>

                      {/* {selectedEquipmentSubTypeVariants && (
                        <div className="frequency-table-container">
                          <table className="frequency-table">
                            <thead>
                              <tr>
                                <th>OISD Point</th>
                                <th>Frequency (In days)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedEquipmentSubTypeVariants.Frequencies && selectedEquipmentSubTypeVariants.Frequencies.length > 0 ? (
                                selectedEquipmentSubTypeVariants.Frequencies.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.oisd_point}</td>
                                    <td>{item.frequency}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="2">No data available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      )} */}
                      <div className="frequency-tables">
                        {categorizedFrequencies.oem.length > 0 && (
                          <div className="frequency-table-container">
                            <h4 className="oem">OEM Frequencies</h4>
                            <table className="frequency-table">
                              <thead>
                                <tr>
                                  <th className="oem-th">
                                    Frequency (In days)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {categorizedFrequencies.oem.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.frequency}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {categorizedFrequencies.oisd.length > 0 && (
                          <div className="frequency-table-container">
                            <h4 className="oisd">OISD Frequencies</h4>
                            <table className="frequency-table">
                              <thead>
                                <tr className="oisd-th">
                                  <th>OISD No</th>
                                  <th>OISD Point</th>
                                  <th>Frequency (In days)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {categorizedFrequencies.oisd.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.oisd_no}</td>
                                    <td>{item.oisd_point}</td>
                                    <td>{item.frequency}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {categorizedFrequencies.factory_act.length > 0 && (
                          <div className="frequency-table-container">
                            <h4 className="factory-act">
                              Factory Act Frequencies
                            </h4>
                            <table className="frequency-table">
                              <thead>
                                <tr className="factory-act-th">
                                  <th>Factory Act Rule No</th>
                                  <th>Frequency (In days)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {categorizedFrequencies.factory_act.map(
                                  (item) => (
                                    <tr key={item.id}>
                                      <td>{item.factory_act_rule_no}</td>
                                      <td>{item.frequency}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}

                        {categorizedFrequencies.peso.length > 0 && (
                          <div className="frequency-table-container">
                            <h4 className="peso">PESO Frequencies</h4>
                            <table className="frequency-table">
                              <thead>
                                <tr className="peso-th">
                                  <th>PESO Rule No</th>
                                  <th>Frequency (In days)</th>
                                </tr>
                              </thead>
                              <tbody>
                                {categorizedFrequencies.peso.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.peso_rule_no}</td>
                                    <td>{item.frequency}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ transform: "translateY(40px)", marginLeft: "15px" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={severity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default NewEquipmentForm;
