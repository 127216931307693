import React, { useState, useEffect, useRef } from "react";
import "./AllInventory.css";
import Loader from "../../src/Loader";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoPrintOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar"; // Import Snackbar
import MuiAlert from "@mui/material/Alert";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import API_URL from "../../src/Config";
import Pagination from "../Component/Pagination";
import useClickOutside from "../useClickOutside";
import "../TableColor.css";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { LuDownload } from "react-icons/lu";
import { TfiImport } from "react-icons/tfi";
import { FiUpload } from "react-icons/fi";

function Inventory({ isSidebarExpanded, designationID }) {
  const [inventoryLists, setInventoryLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(""); // New state for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isSortPopup, setIsSortPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showActionHeader, setShowActionHeader] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const fetchTimeout = useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isMainContainerVisible, setIsMainContainerVisible] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [file, setFile] = useState(null);
  const modalRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileName, setFileName] = useState('');

  const handleUploadButtonClick = () => {
    // Ensure the ref is not null before triggering the click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is null");
    }
  };

  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     const fileType = selectedFile.name.split(".").pop();
  //     if (fileType === "xlsx" || fileType === "csv") {
  //       setFile(selectedFile);
  //       setErrorMessage("");
  //     } else {
  //       setErrorMessage("Only xlsx and csv files are allowed.");
  //       setFile(null);
  //     }
  //   }
  // };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.name.split(".").pop();
      if (fileType === "xlsx" || fileType === "csv") {
        setFile(selectedFile); // Set the file state
        setFileName(selectedFile.name); // Set the file name to display
        setErrorMessage(""); // Clear any previous error
      } else {
        setErrorMessage("Only xlsx and csv files are allowed.");
        setFile(null); // Clear file state if invalid
        setFileName(""); // Clear file name display
      }
    }
  };

  const handleImportClick = () => {
    setShowImportModal(true);
  };
  const handleCloseModal = () => {
    setShowImportModal(false); // Close the modal
    setFile(null); // Clear file state
    setFileName(''); // Clear file name display
    setErrorMessage(''); // Clear error message
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  // const handleImport = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("file", file);

  //     const authKey = localStorage.getItem("authKey");
  //     const apiUrl = `${API_URL}/api/import_inventory`;

  //     const response = await axios.post(apiUrl, formData, {
  //       headers: {
  //         Authorization: authKey,
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     if (response.status === 200) {
  //       showSnackbar("Products Inventory imported successfully");
  //       fetchData(); // Refresh the product list after import
  //       setShowImportModal(false); // Close the import modal
  //     } else {
  //       console.error("Failed to import products inventory");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while importing products:", error);
  //   }
  // };

  const handleImport = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/import_inventory`;

      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: authKey,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        showSnackbar("Import complete and Inventory updated successfully");
        fetchData(); // Refresh the product list after import
        setShowImportModal(false); // Close the import modal
      } else if (response.data.error) {
        setErrorMessage(response.data.error);
      } else {
        console.error("Failed to import products inventory");
      }
    } catch (error) {
      console.error("An error occurred while importing products:", error);
      setErrorMessage(
        "An error occurred while importing products. Please try again."
      );
    }
  };
  const handleDownload = async () => {
    const authKey = localStorage.getItem("authKey");
    try {
      // Use template literal correctly with backticks
      const response = await fetch(
        `${API_URL}/api/download_inventory_template`,
        {
          method: "GET",
          headers: {
            Authorization: authKey,
            "Content-Type": "application/octet-stream", // Add content type if needed
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      // Convert response to Blob for file handling
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger download
      const link = document.createElement("a");
      link.href = url;
      link.download = "inventory_template.xlsx"; // Suggested file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed:", error);
      alert("Failed to download the template. Please try again.");
    }
  };

  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (column) => {
    const order =
      sortColumn === column && sortOrder[column] === "asc" ? "desc" : "asc";

    setSortColumn(column);
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: order,
    }));

    setIsSortPopup(false);

    const sortedschedules = [...inventoryLists].sort((a, b) => {
      const aValue = a[column]?.toString().toLowerCase() || "";
      const bValue = b[column]?.toString().toLowerCase() || "";

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    setInventoryLists(sortedschedules);
  };

  const handleDeletePopup = () => {
    setIsDeletePopup(!isDeletePopup);
    setIsDeletePopupOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const handleCancel = () => {
    setIsDeletePopup(false);
  };

  const fetchData = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(`${API_URL}/api/product_inventory`, {
        headers: {
          Authorization: authKey,
        },
        params: {
          page: currentPage,
          per_page: itemsPerPage,
        },
      });
      if (response.status === 200) {
        setInventoryLists(response.data.Items || 0);
        setTotalItems(response.data.TotalItems);
      } else {
        console.error("Error fetching vendors:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching vendors:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (currentPage, itemsPerPage) => {
    if (!searchInput.trim()) {
      fetchData(currentPage, itemsPerPage);
      return;
    }
    try {
      const authKey = localStorage.getItem("authKey");

      const response = await axios.get(
        `${API_URL}/api/search_product_inventory`,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "application/json",
          },
          params: {
            query: searchInput,
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      if (response.status === 200) {
        setInventoryLists(response.data.inventory);
        setTotalItems(response.data.total);
      } else {
        console.error("Error fetching search results:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching search results:", error.message);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(fetchTimeout.current);

    // Debounce API calls by 600ms
    fetchTimeout.current = setTimeout(() => {
      if (searchInput.trim() === "") {
        fetchData(page, itemsPerPage);
      } else {
        setIsSearching(true);
        handleSearch(page, itemsPerPage);
      }
    }, 600);

    return () => clearTimeout(fetchTimeout.current);
  }, [searchInput, page, itemsPerPage]);

  useClickOutside([containerRef, buttonRef], () => setIsSortPopup(false));

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    setPage(currentPage);
    setItemsPerPage(itemsPerPage);
    localStorage.setItem("currentPage", currentPage);
    localStorage.setItem("itemsPerPage", itemsPerPage);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedRows(
      newSelectAll ? inventoryLists.map((inwardList) => inwardList.id) : []
    );
    setChecked(newSelectAll);
  };

  const handleSelectRow = (designationID) => {
    const updatedSelectedRows = selectedRows.includes(designationID)
      ? selectedRows.filter((id) => id !== designationID)
      : [...selectedRows, designationID];

    setSelectedRows(updatedSelectedRows);
    setSelectAll(updatedSelectedRows.length === inventoryLists.length);
    setChecked(updatedSelectedRows.length > 0);
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleArchivedinventory = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const apiUrl = `${API_URL}/api/archive_department`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: authKey,
        },
        body: JSON.stringify({ ids: selectedRows }),
      });

      if (response.ok) {
        showSnackbar("Data archived successfully");
        const updatedDesignations = inventoryLists.filter(
          (designation) => !selectedRows.includes(designation.ID)
        );
        setInventoryLists(updatedDesignations);
        setSelectedRows([]);
        setSelectAll(false);
        setChecked(false);
      } else {
        console.error("Failed to delete departments");
      }
    } catch (error) {
      console.error("An error occurred while deleting designations:", error);
    }
  };


  const handleArchiveInventory = ()=>{
    // alert('..testing')
    navigate('/archived-inventory')
  }

  const handleCloseButtonClick = () => {
    setChecked(false);
    setSelectedRows([]);
    setShowActionHeader(false);
  };

  const handleSortpopup = () => {
    setIsSortPopup(!isSortPopup);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRefresh = () => {
    fetchData(page,itemsPerPage)
    setSearchInput("");
  };
  const closePopups = () => {
    setIsSortPopup(false);
    setIsDeletePopup(false);
  };
  const handleViewClick = async (mat_code_id) => {
    navigate(`/product-inventory-view/${mat_code_id}`);
  };
  return (
    <>
      <div
        className={`data-list-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        {!isMainContainerVisible && (
          <div className="data-list-content" ref={containerRef}>
            {isChecked ? (
              <div className="action-header">
                <div className="action-header-content">
                  <div className="action-header-content-leftside">
                    <div className="action-button">
                    <button
                        className="delete"
                        onClick={handleDeletePopup}
                      >
                        Move to archive
                      </button>
                      {isDeletePopup && (
                        <div className="confirm-delete-popup">
                          <div className="confirm-delete-popup-content">
                            <div className="confirm-model">
                              <div className="confirm-model-content">
                                <div className="confirm-model-content-body">
                                  <p>Are you sure want to move archive?</p>
                                </div>
                                <div className="confirm-model-content-footer">
                                  <button
                                    className="confirm-delete-button"
                                    onClick={handleArchivedinventory}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="confirm-cancel-button"
                                    onClick={handleCancel}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="action-header-content-rightside">
                    <button
                      className="action-header-close-button"
                      onClick={handleCloseButtonClick}
                    >
                      <IoCloseOutline className="action-header-close-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="data-list-header">
                <div className="data-list-header-content">
                  <div className="data-list-heading-content-left-side">
                    All Product Inventory List
                  </div>
                  <div className="data-list-heading-content-right-side">
                    <div className="search-bar-container">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="search-bar"
                      />
                      <IoSearchOutline className="search-bar-icon" />
                    </div>
                    <div className="import-button-container">
                      <button
                        className="import-button"
                        onClick={handleImportClick}
                      >
                        <TfiImport className="import-icons-button" />
                        Import
                      </button>
                    </div>
                    <Tooltip title="Refresh List" arrow>
                      <button
                        className="refresh-button"
                        onClick={handleRefresh}
                      >
                        <TbRefresh className="refresh-icon" />
                      </button>
                    </Tooltip>
                    <button
                      className="sort-button"
                      onClick={handleSortpopup}
                      ref={buttonRef}
                    >
                      <PiDotsThreeOutlineVerticalFill className="sort-icon" />
                    </button>
                    {isSortPopup && (
                      <div className="sort-popup-container" ref={containerRef}>
                        <div className="sort-popup-content">
                          <div className="sort-by">
                            SORT BY{" "}
                            {isAscending ? "(Ascending)" : "(Descending)"}
                          </div>
                          <div className="sort-by-button">
                            <button onClick={() => handleSort("mat_code")}>
                              Mat Code{" "}
                              {sortOrder.mat_code === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                            <button onClick={() => handleSort("status")}>
                              Status{" "}
                              {sortOrder.status === "asc" ? (
                                <MdExpandMore />
                              ) : (
                                <MdExpandLess />
                              )}
                            </button>{" "}
                            <br />
                          </div>
                          <button
                            className="delete-item"
                            onClick={handleDownload}
                          >
                            Download ex. Formate
                            <LuDownload className="download-icon-dishpatchs" />
                          </button>
                          <br />
                          <button className='delete-item' onClick={handleArchiveInventory}>Archived Sub Department</button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="data-list-body" onClick={closePopups}>
              <div className="data-list-scroll-body">
                <div className="data-list-table">
                  {loading ? (
                    <Loader />
                  ) : inventoryLists.length > 0 ? (
                    <table className="table multilevel-data">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th>SR. NO.</th>
                          <th>MAT CODE</th>
                          <th>MAT CODE DES</th>
                      
                          <th className="total">TOTAL QUANTITY</th>

                          <th className="quantity">AVAILABLE QUANTITY</th>

                          <th className="pending">
                            TECHNICAL INSPECTION PENDING
                          </th>
                          <th>UPDATED AT</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody className="data-list-scroll-bodys">
                        {inventoryLists.map((inventoryList, index) => (
                          <tr key={inventoryList.id} className="hoverable-row">
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(
                                  inventoryList.id
                                )}
                                onChange={() =>
                                  handleSelectRow(inventoryList.id)
                                }
                              />
                            </td>

                            <td>{inventoryList.serial_no}</td>
                            <td>{inventoryList.mat_code || "NA"}</td>
                            <td>{inventoryList.description || "NA"}</td>
                           
                            <td className="total">
                              {inventoryList.total_qty || "0"}
                            </td>

                            <td className="quantity">
                              {inventoryList.available_qty || "0"}
                            </td>
                            <td className="pending">
                              {inventoryList.pending_qty || "0"}
                            </td>
                            <td>
                              {inventoryList.updated_at
                                ? new Date(inventoryList.updated_at)
                                    .toUTCString()
                                    .split("GMT")[0]
                                    .trim() + " IST"
                                : "NA"}
                            </td>

                            <td>
                              <button
                                onClick={() =>
                                  handleViewClick(inventoryList.mat_code_id)
                                }
                                className="view-button"
                              >
                                Inventory History
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-users-container">
                      <div className="no-users-message">
                        No Product Inventory List Found
                      </div>
                    </div>
                  )}
                </div>
                <Pagination
                  showPerPage={itemsPerPage}
                  total={totalItems}
                  onPaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
        )}
        {/* {isMainContainerVisible && (
          <div className="main-containers">
            <UserView userID={popupUserID} onClose={handleCloseUserView} />
          </div>
        )} */}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: "35px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      {/* {showImportModal && (
        <div className="import-modal-popup">
          <div
            className="import-modal-overlay"
            onClick={handleCloseModal}
          ></div>
          <div className="import-modal" ref={modalRef}>
            <h2 className="modal-title">Import Products Inventory</h2>
            <div className="import-modal-body">
              <input
                type="file"
                onChange={handleFileChange}
                className="file-input"
              />

              <button
                variant="contained"
                color="primary"
                onClick={handleImport}
                className="import-button"
                disabled={!file}
              >
                <TfiImport className="import-icons-button" />
                Import
              </button>
            </div>
            {errorMessage && (
              <div className="file-error-message">{errorMessage}</div>
            )}
          </div>
        </div>
      )} */}

{showImportModal && (
        <div className="import-modal-popup">
          <div
            className="import-modal-overlay"
            onClick={handleCloseModal}
          ></div>
          <div className="import-modal" ref={modalRef}>
            <h2 className="modal-title">Import Products Inventory</h2>
            <div className="import-modal-body">
              <button
                type="button"
                className="challan-btn"
                onClick={handleUploadButtonClick}
                ref={buttonRef}
              >
                <FiUpload /> Upload File
              </button>
              <input
                id="attachments"
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />

              {fileName && (
                <div>
                  <p>{fileName}</p>
                </div>
              )}

              <button
                onClick={handleImport}
                className="import-button1"
                disabled={!file}
              >
                <TfiImport className="import-icons-button" />
                Import
              </button>
            </div>
            {errorMessage && (
              <div className="file-error-message">{errorMessage}</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Inventory;
