import React, { useState, useEffect, useRef } from "react";
// import "./UserForm.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import API_URL from "../../src/Config";
import Select from "react-select";
import { FiUpload } from "react-icons/fi";
import { MdAttachFile } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md"; // Add this line at the top
import useClickOutside from "../useClickOutside";
import { useContext } from "react";
import { ThemeContext } from "../../src/ThemeContext";
function OutwardForm({ isSidebarExpanded }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showDescription, setShowDescription] = useState(false);
  const [matCodes, setMatCodes] = useState([]);

  const [outwardData, setOutwardData] = useState({
    min_no: "",
    invoice_attachment: null,
  });
  const [rows, setRows] = useState([
    { matCodes: "", available_qty: "", outwarded_qty: "", description: "" },
  ]);
  const handleAddRow = () => {
    setRows([
      ...rows,
      { matCodes: "", available_qty: "", outwarded_qty: "", description: "" },
    ]);
  };
  const { theme } = useContext(ThemeContext);

  const getAvailableMatCodes = (currentIndex) => {
    // Extract currently selected matCodes for rows before the new one
    const selectedMatCodes = rows
      .map((row, index) => (index < currentIndex ? row.matCodes : null))
      .filter(Boolean); // Filter out any null values

    // Return options that are not already selected
    return matCodes.filter(
      (option) => !selectedMatCodes.includes(option.value)
    );
  };

  const handleInputChanges = (index, field, value) => {
    const newRows = [...rows];

    // If the field is outwarded_qty, validate against available_qty
    if (field === "outwarded_qty") {
      const availableQty = newRows[index].available_qty || 0; // Default to 0 if undefined
      const quantityValue = Number(value); // Convert the input to a number

      // Check if the entered quantity exceeds available quantity
      if (quantityValue > availableQty) {
        // Get the material code from the current row
        const selectedMatCode = matCodes.find(
          (option) => option.value === newRows[index].matCodes
        );
        const matCodeLabel = selectedMatCode
          ? selectedMatCode.label
          : "Unknown"; // Default to 'Unknown' if not found

        setSeverity("error");
        showSnackbar(
          `Not enough stock for material code ${matCodeLabel}. Available quantity: ${availableQty}`
        );

        // Set outwarded_qty to available_qty if entered quantity exceeds it
        newRows[index][field] = availableQty;
      } else {
        newRows[index][field] = quantityValue; // Only update if validation passes
      }
    } else {
      newRows[index][field] = value; // Update for other fields
    }

    // If the field is matCodes, update the description as well
    if (field === "matCodes") {
      const selectedMatCode = matCodes.find((option) => option.value === value);

      // Update the description for the selected matCode
      newRows[index].description = selectedMatCode
        ? selectedMatCode.description
        : "";
      newRows[index].available_qty = selectedMatCode
        ? selectedMatCode.available_qty
        : "NA";
    }

    // Set the updated rows
    setRows(newRows);

    // Update showDescription based on any row having a description
    const hasDescriptions = newRows.some((row) => row.description);
    setShowDescription(hasDescriptions);
  };

  const fetchMattCodeData = async () => {
    try {
      const authKey = localStorage.getItem("authKey");
      const response = await axios.get(`${API_URL}/api/get_filtered_mat_codes`, {
        headers: {
          Authorization: authKey,
        },
      });

      // Transform data to match Select component's expected format
      const transformedData = response.data.filtered_mat_code_details.map((matCode) => ({
        value: matCode.mat_code_id,
        label: matCode.mat_code,
        description: matCode.description,
        available_qty: matCode.available_qty,
      }));

      setMatCodes(transformedData);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    fetchMattCodeData();
  }, []);

  const handleFileInputChange = (files, setFiles) => {
    const fileArray = Array.from(files);
    setFiles(fileArray);
  };
  const handleIconClick = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const handleCloseAttachment = () => {
    setIsPopupOpen(false);
  };

  useClickOutside([containerRef, buttonRef, fileInputRef], () => {
    // setIsSortPopupOpen(false);
    setIsPopupOpen(false);
  });
  const handleUploadButtonClick = (fileInputRef) => {
    fileInputRef.current.click();

    if (errors.attachments) {
      setErrors((prevErrors) => ({ ...prevErrors, attachments: "" }));
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOutwardData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear the error message for this field
    }));
  };

  const handleCancelClick = () => {
    navigate("/outward-list");
  };

  const handleDeleteFile = (indexToDelete) => {
    console.log("Before delete:", selectedFiles); // Debugging

    // Filter out the file at the given index and assign to updatedFiles
    const updatedFiles = selectedFiles.filter(
      (_, index) => index !== indexToDelete
    );

    // Update the state with filtered files
    setSelectedFiles(updatedFiles);

    console.log("After delete:", updatedFiles); // Debugging

    // Close the popup if no files are left
    if (updatedFiles.length === 0) {
      handleCloseAttachment(); // Close the popup if no files remain
    }
  };

  const handleSaveClick = async () => {
    // Initialize validation errors as an object
    const validationErrors = {
      min_no: "", // for MIN number validation
      attachments: "", // for attachments validation
      rowErrors: [], // to store errors for each row
    };
    // Validate attachments
    if (selectedFiles.length === 0) {
      validationErrors.attachments = "At least one attachment is required.";
    }
    // Validate MIN number
    if (!outwardData.min_no || outwardData.min_no.trim() === "") {
      validationErrors.min_no = "MIN number is required.";
    }

    // Validate mat code and outwarded quantity for each row
    rows.forEach((row, index) => {
      const rowErrors = {};
      if (!row.outwarded_qty || row.outwarded_qty <= 0) {
        const errorMsg = `Please enter a valid required quantity for row ${
          index + 1
        }.`;
        rowErrors.outwarded_qty = errorMsg; // Store error message for the row
        setSeverity("error");
        showSnackbar(errorMsg); // Show Snackbar for row quantity error
      }
      if (!row.matCodes) {
        const errorMsg = `Please select a mat code for row ${index + 1}.`;
        rowErrors.matCodes = errorMsg; // Store error message for the row
        setSeverity("error");
        showSnackbar(errorMsg); // Show Snackbar for mat code error
      }
      if (Object.keys(rowErrors).length > 0) {
        validationErrors.rowErrors[index] = rowErrors; // Collect row errors
      }
    });

    // If any validation errors occurred, stop execution
    const hasErrors =
      validationErrors.min_no ||
      validationErrors.attachments ||
      validationErrors.rowErrors.length > 0;

    if (hasErrors) {
      // Set overall severity to error
      setSeverity("error");

      // Display validation messages in Snackbar if any overall errors exist
      if (validationErrors.min_no) showSnackbar(validationErrors.min_no);
      if (validationErrors.attachments)
        showSnackbar(validationErrors.attachments);
      return; // Stop execution if there are validation errors
    }

    try {
      setIsLoading(true);
      const authKey = localStorage.getItem("authKey");

      // Prepare FormData for the API request
      const formData = new FormData();

      // Add MIN number to FormData
      formData.append("min_no", outwardData.min_no || "");

      // Prepare 'items' array from the rows data
      const items = rows.map((row) => ({
        mat_code_id: row.matCodes, // Mat code selected in the dropdown
        outwarded_qty: row.outwarded_qty, // User input for outwarded quantity
      }));

      // Add 'items' array as JSON string to FormData
      formData.append("items", JSON.stringify(items));

      // Add file attachments to FormData
      selectedFiles.forEach((file) => {
        formData.append("attachments", file);
      });

      console.log("FormData entries:");
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      // Send the POST request to the backend API
      const response = await axios.post(
        `${API_URL}/api/product_outward`,
        formData,
        {
          headers: {
            Authorization: authKey,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setSeverity("success");
        showSnackbar("Outward added successfully.");
        setTimeout(() => navigate("/outward-list"), 1000);
      }
    } catch (error) {
      console.error("Error saving outward:", error);
      setSeverity("error");
      showSnackbar("Failed to save outward.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleInputClick = (e) => {
    e.target.select(); //
  };
  return (
    <>
      <div
        className={`data-form-content-container page-content ${
          isSidebarExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className="data-form-content" ref={containerRef}>
          <div className="data-form-header">
            <div className="data-form-header-content">
              <div className="data-form-heading-content-left-side">
                New Material Issue
              </div>
              <div className="data-form-heading-content-right-side"></div>
            </div>
          </div>
          <div className="header-separator"></div>
          <div className="data-form-body">
            <form className="form-detail">
              <div className="new-data-form">
                <div className="form-group">
                  <label htmlFor="min_no">
                    MIN (Material Issue Note) No
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="min_no"
                      value={outwardData.min_no}
                      onChange={handleInputChange}
                      placeholder="Enter MIN no"
                    />
                  </div>
                  {errors.min_no && (
                    <span className="error-message">{errors.min_no}</span>
                  )}
                </div>
                <div className="form-group-attachments">
                  <div className="form-group">
                    <label htmlFor="gst_attachments">
                      Attachment<span className="text-danger">*</span>
                    </label>
                    <button
                      type="button"
                      className="challan-btn"
                      onClick={() => handleUploadButtonClick(fileInputRef)}
                    >
                      <FiUpload /> Upload File
                    </button>
                    <input
                      type="file"
                      id="gst_attachments"
                      ref={fileInputRef}
                      onChange={(e) =>
                        handleFileInputChange(e.target.files, setSelectedFiles)
                      }
                      style={{ display: "none" }}
                      multiple
                    />
                    {selectedFiles.length > 0 && (
                      <span
                        className="inquiry-file-btn"
                        onClick={handleIconClick}
                        ref={buttonRef}
                      >
                        <MdAttachFile className="inquiry-file-icon" />{" "}
                        {`${selectedFiles.length}`}
                      </span>
                    )}
                    {isPopupOpen && (
                      <div className="attach-popup-inward" ref={containerRef}>
                        <div className="attach-popup-contant">
                          <div className="attachment-header">
                            <div className="attachment-header-content">
                              Attachments
                            </div>
                            <button
                              className="fiile-close-btn"
                              onClick={handleCloseAttachment}
                            >
                              <IoCloseOutline className="file-close-icon" />
                            </button>
                          </div>
                          {selectedFiles.length > 0 && (
                            <div className="attach-scrroll-body">
                              {selectedFiles.map((file, index) => (
                                <div
                                  className="attach-popup-contant-body"
                                  key={index}
                                >
                                  <div className="attach-file-container">
                                    <div className="attach-file-show">
                                      <div className="text-attachment">
                                        {file.name}
                                      </div>
                                      <div className="text-attachment-file">
                                        File Size:(
                                        {(file.size / 1024).toFixed(2)} KB)
                                      </div>
                                    </div>
                                    <div
                                      className="file-delete-btn"
                                      onClick={() => handleDeleteFile(index)}
                                    >
                                      <span>
                                        <MdDeleteOutline className="file-delete-icon" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {errors.attachments && (
                      <span className="error-message">
                        {errors.attachments}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="action-purchase-sheet-header">
              <div className="actions-purchase-sheet-header-content">
                <div className="actions-purchase-sheet-heading-content-left-side">
                  Request Item Table
                </div>
                <div className="actions-purchase-heading-content-right-side"></div>
              </div>
            </div>

            <div className="action-purchase-body">
              <div className="action-purchase-scroll-body">
                <div className="action-purchase-tabless">
                  <>
                    <table className="action-table action-multilevel-data ">
                      <thead className="action-purchase-heads">
                        <tr>
                          <th className="th2">
                            MAT CODE <span className="text-danger">*</span>
                          </th>
                          {showDescription && (
                            <th className="th2">DESCRIPTION</th>
                          )}

                          <th className="th4 quantity">AVAILABLE QTY</th>
                          <th className="th5">
                            REQUIRED QTY <span className="text-danger">*</span>
                          </th>
                          <th className="th6">ACTION</th>
                        </tr>
                      </thead>

                      <tbody className="action-purchase-scroll-bodys">
                        {rows.map((row, index) => (
                          <tr key={index}>
                            <td className="th2">
                              <Select
                                // options={matCodes}
                                options={getAvailableMatCodes(index)}
                                value={matCodes.find(
                                  (option) => option.value === row.matCodes
                                )}
                                onChange={(selectedOption) =>
                                  handleInputChanges(
                                    index,
                                    "matCodes",
                                    selectedOption ? selectedOption.value : ""
                                  )
                                }
                                placeholder="Select Mat Code"
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    backgroundColor:
                                      theme === "dark" ? "black" : "white", // Dropdown background color
                                    color: theme === "dark" ? "#fff" : "#333", // Text color
                                    borderColor:
                                      theme === "dark" ? "#444" : "#ccc", // Border color
                                    cursor: "pointer",
                                    boxShadow: "none",
                                    "&:hover": {
                                      borderColor:
                                        theme === "dark" ? "#666" : "#888", // Border color on hover
                                    },
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    backgroundColor:
                                      theme === "dark" ? "#000" : "#fff", // Background color for dropdown menu based on theme
                                    zIndex: 1000, // Add high z-index for dropdown menu
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused
                                      ? theme === "dark"
                                        ? "#666" // Gray when hovered in dark theme
                                        : "lightblue" // Light blue when hovered in light theme
                                      : theme === "dark"
                                      ? "#000"
                                      : "#fff", // Background color for options based on theme
                                    color: state.isFocused
                                      ? "#fff"
                                      : theme === "dark"
                                      ? "#fff"
                                      : "#333", // Text color
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: theme === "dark" ? "#fff" : "#333", // Selected option text color
                                  }),
                                }}
                              />
                            </td>
                            {showDescription && (
                              <td className="th4">{row.description || "NA"}</td>
                            )}
                            <td className="th4 quantity">
                              {row.available_qty || "0"}
                            </td>

                            <td className="th4">
                              <input
                                type="number"
                                value={row.outwarded_qty || 0} // Default to 0 if undefined
                                onChange={(e) =>
                                  handleInputChanges(
                                    index,
                                    "outwarded_qty",
                                    e.target.value
                                  )
                                }
                                onFocus={handleInputClick}
                                placeholder="Enter required qty"
                              />
                            </td>
                            <td className="th6">
                              <button
                                className="delete-row"
                                onClick={() => {
                                  const newRows = rows.filter(
                                    (_, i) => i !== index
                                  );
                                  setRows(newRows);
                                }}
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="row">
                      <div className="action-caluculation">
                        <div className="new-rows">
                          <button onClick={handleAddRow} className="add-rows">
                            <FiPlus className="add-row-icon" /> Add New Row
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="button-group">
              <button
                className="data-form-save-button"
                type="save"
                onClick={handleSaveClick}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                className="data-form-cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Converted to milliseconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ top: "75px", marginLeft: "20px" }}
      >
        <MuiAlert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default OutwardForm;
